import { loadStripe } from '@stripe/stripe-js'
import { account } from './appwrite'
import toast from 'react-hot-toast'

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY)

export const handleUpgradeClick = async () => {
  try {
    const stripe = await stripePromise
    if (!stripe) {
      toast.error('Payment system unavailable. Please try again later.')
      return false
    }

    const user = await account.get()

    const response = await fetch(
      `${
        import.meta.env.VITE_API_URL || 'http://localhost:3001'
      }/create-checkout-session`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: user.$id,
          email: user.email,
        }),
      },
    )

    if (!response.ok) {
      const errorData = await response.json()
      throw new Error(errorData.error || 'Failed to create checkout session')
    }

    const { sessionId } = await response.json()

    if (!sessionId) {
      throw new Error('No session ID received')
    }

    // Redirect to Stripe Checkout
    const { error } = await stripe.redirectToCheckout({ sessionId })

    if (error) {
      console.error('Stripe checkout error:', error)
      if (error.message.includes('popup')) {
        toast.error(
          'Please enable popups for this site to complete your purchase',
        )
      }
      return false
    }

    return true
  } catch (error) {
    console.error('Payment error:', error)
    return false
  }
}

export const handleCancelSubscription = async () => {
  try {
    const user = await account.get()

    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/cancel-subscription`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: user.$id,
        }),
      },
    )

    if (!response.ok) {
      const errorData = await response.json()
      throw new Error(errorData.error || 'Failed to cancel subscription')
    }

    const data = await response.json()
    return data.success
  } catch (error) {
    console.error('Cancellation error:', error)
    throw error
  }
}
