const sendEmail = async ({ to, templateName, templateData, userId }) => {
  try {
    console.log('Sending email request:', {
      to,
      templateName,
      templateData,
      userId,
    })

    const response = await fetch(`${import.meta.env.VITE_API_URL}/send-email`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        to,
        templateName,
        templateData,
        userId,
      }),
    })

    const data = await response.json()

    if (!response.ok) {
      throw new Error(data.error || 'Failed to send email')
    }

    console.log('Email sent successfully:', data)
    return true
  } catch (error) {
    console.error('Error sending email:', error)
    throw error
  }
}

export { sendEmail }
