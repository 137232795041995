const Privacy = () => {
  return (
    <div className="py-12 px-4">
      <div className="max-w-3xl mx-auto">
        <div className="bg-white dark:bg-secondary-800 rounded-2xl p-8 shadow-sm">
          <h1 className="text-3xl sm:text-4xl font-bold text-secondary-900 dark:text-secondary-100 mb-8">
            Privacy Policy
          </h1>
          <div className="text-secondary-600 dark:text-secondary-300 space-y-8">
            <div className="flex items-center gap-2 text-sm">
              <span>Last updated:</span>
              <span className="px-2 py-1 bg-secondary-100 dark:bg-secondary-700 rounded">
                {new Date().toLocaleDateString()}
              </span>
            </div>

            <section className="space-y-4">
              <h2 className="text-2xl font-semibold text-secondary-900 dark:text-secondary-100">
                Introduction
              </h2>
              <p className="leading-relaxed">
                NextJob (&quot;we&quot;, &quot;our&quot;, or &quot;us&quot;) is
                committed to protecting your privacy. This policy explains how
                we collect, use, and safeguard your information when you use
                nextjob.work (&quot;the Service&quot;).
              </p>
            </section>

            <section className="space-y-4">
              <h2 className="text-2xl font-semibold text-secondary-900 dark:text-secondary-100">
                Information We Collect
              </h2>
              <ul className="space-y-3 list-none">
                <li className="flex gap-4">
                  <strong className="text-primary-600 dark:text-primary-400 shrink-0">
                    Account Information:
                  </strong>
                  <span>Email address and name when you create an account</span>
                </li>
                <li className="flex gap-4">
                  <strong className="text-primary-600 dark:text-primary-400 shrink-0">
                    Job Application Data:
                  </strong>
                  <span>
                    Information you input about job applications, including
                    company names, job titles, application status, and notes
                  </span>
                </li>
                <li className="flex gap-4">
                  <strong className="text-primary-600 dark:text-primary-400 shrink-0">
                    Usage Data:
                  </strong>
                  <span>
                    Preferences, view settings, and interaction with the
                    platform
                  </span>
                </li>
                <li className="flex gap-4">
                  <strong className="text-primary-600 dark:text-primary-400 shrink-0">
                    Automatic Data:
                  </strong>
                  <span>
                    Browser type, device information, and cookies for platform
                    functionality
                  </span>
                </li>
              </ul>
            </section>

            <section className="space-y-4">
              <h2 className="text-2xl font-semibold text-secondary-900 dark:text-secondary-100">
                How We Use Your Information
              </h2>
              <ul className="space-y-3 list-none">
                <li>To provide and maintain the Service</li>
                <li>To personalize your experience with AI-powered features</li>
                <li>To save your preferences and settings</li>
                <li>To improve our Service based on usage patterns</li>
                <li>To communicate important updates about the Service</li>
              </ul>
            </section>

            <section className="space-y-4">
              <h2 className="text-2xl font-semibold text-secondary-900 dark:text-secondary-100">
                Data Storage and Security
              </h2>
              <p className="leading-relaxed">
                We use Appwrite as our backend service provider, which ensures
                your data is encrypted and stored securely. All data is
                processed in compliance with industry standards for security.
              </p>
            </section>

            <section className="space-y-4">
              <h2 className="text-2xl font-semibold text-secondary-900 dark:text-secondary-100">
                AI Processing
              </h2>
              <p className="leading-relaxed">
                When you paste a job URL, we use AI to extract relevant
                information. This process is automated and the data is not
                stored or used for any purpose other than providing the service
                to you.
              </p>
            </section>

            <section className="space-y-4">
              <h2 className="text-2xl font-semibold text-secondary-900 dark:text-secondary-100">
                Your Rights
              </h2>
              <p className="leading-relaxed">You have the right to:</p>
              <ul className="space-y-3 list-none">
                <li>Access your personal data</li>
                <li>Correct inaccurate data</li>
                <li>Request deletion of your data</li>
                <li>Export your data</li>
                <li>Opt out of certain data processing activities</li>
              </ul>
            </section>

            <section className="space-y-4">
              <h2 className="text-2xl font-semibold text-secondary-900 dark:text-secondary-100">
                Contact Us
              </h2>
              <p className="leading-relaxed">
                If you have any questions about this Privacy Policy, please
                contact us at{' '}
                <a
                  href="mailto:support@nextjob.work"
                  className="text-primary-600 dark:text-primary-400 hover:underline"
                >
                  support@nextjob.work
                </a>
              </p>
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Privacy
