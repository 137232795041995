import { databases, DATABASE_ID } from './appwrite'
import { Query } from 'appwrite'
import { PLAN_TYPES } from './plans'

export const getUserPlan = async (userId) => {
  try {
    const plans = await databases.listDocuments(DATABASE_ID, 'user-plans', [
      Query.equal('userId', userId),
    ])

    if (plans.total === 0) {
      return PLAN_TYPES.FREE
    }

    const userPlan = plans.documents[0]

    // If there's an end date, check if it's still valid
    if (userPlan.endDate) {
      const endDate = new Date(userPlan.endDate)
      const now = new Date()

      // If end date hasn't passed, user still has pro access
      // regardless of plan status (handles canceled but not expired cases)
      if (endDate > now) {
        return PLAN_TYPES.PRO
      }
    }

    // If end date has passed or doesn't exist, return the current plan status
    return userPlan.plan || PLAN_TYPES.FREE
  } catch (error) {
    console.error('Error fetching user plan:', error)
    return PLAN_TYPES.FREE
  }
}
