import { createContext, useState, useContext } from 'react'
import PropTypes from 'prop-types'

const JobContext = createContext()

export const JobProvider = ({ children }) => {
  const [jobs, setJobs] = useState([])

  const addNewJob = (job) => {
    setJobs((prevJobs) => [job, ...prevJobs])
  }

  const updateJobList = (newJobs) => {
    setJobs(newJobs)
  }

  return (
    <JobContext.Provider value={{ jobs, addNewJob, updateJobList }}>
      {children}
    </JobContext.Provider>
  )
}

JobProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

// eslint-disable-next-line react-refresh/only-export-components
export const useJobs = () => useContext(JobContext)
