import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  FiCpu,
  FiRefreshCw,
  FiClock,
  FiTrendingUp,
  FiShield,
  FiSmile,
} from 'react-icons/fi'
import { PLAN_TYPES } from '../utils/plans'
import { handleUpgradeClick } from '../utils/payments'
import { toast } from 'react-hot-toast'
import PropTypes from 'prop-types'

const Upgrade = ({ user }) => {
  const [isProcessing, setIsProcessing] = useState(false)
  const navigate = useNavigate()

  const handleUpgrade = async () => {
    setIsProcessing(true)
    try {
      const success = await handleUpgradeClick()
      if (success) {
        toast.success('Successfully upgraded to Pro! Welcome aboard 🚀')
        navigate('/dashboard')
      } else {
        toast.error('Failed to upgrade. Please try again.')
      }
    } catch (error) {
      console.error('Upgrade error:', error)
      toast.error('Failed to upgrade. Please try again.')
    } finally {
      setIsProcessing(false)
    }
  }

  const features = [
    {
      icon: FiCpu,
      title: 'AI-Powered Features',
      free: '5 AI analyses/day',
      pro: 'Unlimited AI analyses',
      highlight: true,
    },
    {
      icon: FiRefreshCw,
      title: 'Cover Letters',
      free: '3 cover letters total',
      pro: 'Unlimited cover letters',
      highlight: true,
    },
    {
      icon: FiClock,
      title: 'Job Tracking',
      free: '10 jobs/month',
      pro: 'Unlimited job tracking',
      highlight: true,
    },
    {
      icon: FiTrendingUp,
      title: 'Interview Prep',
      free: 'No prep tasks',
      pro: 'AI interview strategies',
      highlight: true,
    },
    {
      icon: FiShield,
      title: 'Application Insights',
      free: 'Manual job details',
      pro: 'AI job details extraction',
    },
    {
      icon: FiSmile,
      title: 'Support',
      free: 'Basic support',
      pro: 'Priority email support',
    },
  ]

  return (
    <div className="relative max-w-5xl mx-auto px-4 py-12">
      {/* Background decoration */}
      <div className="absolute inset-0 overflow-hidden -z-10">
        <div className="absolute -top-1/2 -left-1/2 w-full h-full bg-gradient-to-r from-primary-500/20 to-purple-500/20 blur-3xl transform rotate-12 dark:from-primary-900/30 dark:to-purple-900/30" />
        <div className="absolute -bottom-1/2 -right-1/2 w-full h-full bg-gradient-to-l from-primary-500/20 to-purple-500/20 blur-3xl transform -rotate-12 dark:from-primary-900/30 dark:to-purple-900/30" />
      </div>

      {/* Hero Section */}
      <div className="text-center mb-12">
        <h1 className="text-4xl md:text-5xl font-bold text-secondary-900 dark:text-secondary-100 mb-6">
          Remove All Limits
        </h1>
      </div>

      {/* Main pricing grid - Reordered for mobile */}
      <div className="relative bg-white/80 dark:bg-secondary-800/80 backdrop-blur-xl rounded-2xl shadow-2xl overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-br from-transparent via-primary-500/5 to-primary-500/10 dark:from-transparent dark:via-primary-900/10 dark:to-primary-900/20" />

        <div className="relative grid md:grid-cols-[1fr,1.5fr]">
          {/* Pro Plan - Now first on mobile */}
          <div className="order-first md:order-last relative p-8 bg-gradient-to-br from-primary-50/50 to-purple-50/50 dark:from-primary-900/20 dark:to-purple-900/20">
            <div className="absolute top-4 right-4 sm:right-6">
              <span
                className="inline-flex items-center px-2.5 sm:px-3.5 py-1 sm:py-1.5 rounded-full text-[10px] sm:text-xs font-medium
                bg-gradient-to-r from-primary-500 to-purple-500
                text-white shadow-lg whitespace-nowrap"
              >
                Most Popular
              </span>
            </div>

            <div className="text-center mb-8">
              <h2 className="text-2xl font-semibold text-secondary-900 dark:text-secondary-100">
                Pro Access
              </h2>
              <div className="mt-4">
                <div className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-primary-600 to-purple-600">
                  $9.99
                  <span className="text-base font-normal text-secondary-500">
                    /month
                  </span>
                </div>
                <p className="mt-1 text-sm text-secondary-500">
                  Cancel anytime • No commitments
                </p>
              </div>
            </div>

            {/* Enhanced Pro Features */}
            <div className="space-y-4">
              {features.map((feature, index) => (
                <div key={index} className="flex items-start gap-3">
                  <feature.icon className="w-5 h-5 text-primary-500 dark:text-primary-400 mt-1" />
                  <div>
                    <h3 className="font-medium text-secondary-900 dark:text-secondary-100">
                      {feature.title}
                    </h3>
                    <p className="text-sm text-secondary-600 dark:text-secondary-400">
                      {feature.pro}
                    </p>
                  </div>
                </div>
              ))}
            </div>

            <div className="mt-8">
              <button
                onClick={handleUpgrade}
                disabled={isProcessing || user.plan === PLAN_TYPES.PRO}
                className={`w-full px-6 py-3 rounded-xl font-medium transition-all duration-300
                  ${
                    isProcessing || user.plan === PLAN_TYPES.PRO
                      ? 'bg-primary-400/50 cursor-not-allowed'
                      : 'bg-gradient-to-r from-primary-600 to-purple-600 hover:from-primary-700 hover:to-purple-700 text-white shadow-lg hover:shadow-xl hover:-translate-y-0.5'
                  }`}
              >
                {isProcessing
                  ? 'Processing...'
                  : user.plan === PLAN_TYPES.PRO
                  ? 'Current Plan'
                  : 'Upgrade Now'}
              </button>
              <p className="mt-2 text-xs text-center text-secondary-500">
                Secure payment • Instant access
              </p>
            </div>
          </div>

          {/* Basic Plan - Now second on mobile */}
          <div className="order-last md:order-first p-6 opacity-75 hover:opacity-100 transition-opacity border-t md:border-t-0 md:border-r border-secondary-200/50 dark:border-secondary-700/50">
            <div className="text-center mb-6">
              <h2 className="text-xl font-semibold text-secondary-900 dark:text-secondary-100">
                Basic
              </h2>
              <div className="mt-2 text-2xl font-bold text-secondary-900 dark:text-secondary-100">
                Free
              </div>
              <p className="mt-1 text-sm text-secondary-500">Current Plan</p>
            </div>

            {/* Simplified Basic Features */}
            <div className="space-y-3 text-sm text-secondary-600 dark:text-secondary-400">
              {features.map((feature, index) => (
                <div key={index} className="flex items-center gap-2">
                  <feature.icon className="w-4 h-4 text-secondary-400" />
                  <span>{feature.free}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Support Section */}
      <div className="mt-6 text-center">
        <p className="text-sm text-secondary-600 dark:text-secondary-400">
          Questions?{' '}
          <a
            href="mailto:support@nextjob.work"
            className="text-primary-600 hover:text-primary-700"
          >
            Contact support
          </a>
        </p>
      </div>
    </div>
  )
}

Upgrade.propTypes = {
  user: PropTypes.shape({
    plan: PropTypes.oneOf([PLAN_TYPES.FREE, PLAN_TYPES.PRO]).isRequired,
  }).isRequired,
}

export default Upgrade
