import { MDXProvider } from '@mdx-js/react'
import { Helmet } from 'react-helmet-async'
import { format } from 'date-fns'
import { useParams } from 'react-router-dom'
import { useBlog } from '../../contexts/BlogContext'

// Define custom components for MDX rendering
const components = {
  h1: (props) => (
    <h1
      className="text-4xl font-bold mb-8 text-secondary-900 dark:text-secondary-100"
      {...props}
    />
  ),
  h2: (props) => (
    <h2
      className="text-3xl font-bold mt-16 mb-6 text-secondary-900 dark:text-secondary-100"
      {...props}
    />
  ),
  h3: (props) => (
    <h3
      className="text-2xl font-bold mt-12 mb-4 text-secondary-900 dark:text-secondary-100"
      {...props}
    />
  ),
  p: (props) => (
    <p
      className="text-lg mb-6 leading-relaxed text-secondary-600 dark:text-secondary-300"
      {...props}
    />
  ),
  ul: (props) => <ul className="list-disc pl-6 mb-8 space-y-3" {...props} />,
  ol: (props) => <ol className="list-decimal pl-6 mb-8 space-y-3" {...props} />,
  li: (props) => (
    <li
      className="text-lg text-secondary-600 dark:text-secondary-300"
      {...props}
    />
  ),
  img: (props) => (
    <div className="my-12">
      <img
        className="rounded-xl w-full object-cover shadow-lg"
        loading="lazy"
        {...props}
        onError={(e) => {
          console.error('Image failed to load:', e.target.src)
        }}
      />
    </div>
  ),
}

const BlogPost = () => {
  const { slug } = useParams()
  const { posts } = useBlog()

  const post = posts.find((p) => p.slug === slug)

  if (!post) {
    return <div>Post not found</div>
  }

  const { Component, frontmatter } = post

  return (
    <article className="max-w-3xl mx-auto px-4 py-12">
      <Helmet>
        <title>{frontmatter.title} - NextJob Blog</title>
        <meta name="description" content={frontmatter.description} />
      </Helmet>

      <header className="mb-12">
        {frontmatter.image && (
          <div className="aspect-video mb-8 overflow-hidden rounded-xl">
            <img
              src={frontmatter.image}
              alt={frontmatter.title}
              className="w-full h-full object-cover"
            />
          </div>
        )}
        <h1 className="text-4xl font-bold mb-4 text-secondary-900 dark:text-secondary-100">
          {frontmatter.title}
        </h1>
        <div className="flex items-center gap-4 text-secondary-500">
          <time dateTime={frontmatter.date}>
            {format(new Date(frontmatter.date), 'MMMM d, yyyy')}
          </time>
          <span>•</span>
          <span>{frontmatter.author}</span>
        </div>
      </header>

      <MDXProvider components={components}>
        <div className="prose prose-lg dark:prose-invert max-w-none">
          <Component />
        </div>
      </MDXProvider>
    </article>
  )
}

export default BlogPost
