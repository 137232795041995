import { Client, Account, Databases, ID, Storage } from 'appwrite'

const client = new Client()

// Make sure the endpoint has a valid URL format with protocol
const endpoint = import.meta.env.VITE_APPWRITE_ENDPOINT
if (!endpoint || !endpoint.startsWith('http')) {
  throw new Error(
    'Invalid Appwrite endpoint URL. Must include protocol (http:// or https://)',
  )
}

client
  .setEndpoint(endpoint)
  .setProject(import.meta.env.VITE_APPWRITE_PROJECT_ID)

export const account = new Account(client)
export const id = ID
export const databases = new Databases(client)
export const storage = new Storage(client)

export const DATABASE_ID =
  import.meta.env.VITE_APPWRITE_DATABASE_ID || 'job-hunt-db'
export const JOBS_COLLECTION_ID =
  import.meta.env.VITE_APPWRITE_JOBS_COLLECTION_ID || 'jobs'
export const NOTES_COLLECTION_ID =
  import.meta.env.VITE_APPWRITE_NOTES_COLLECTION_ID || 'job-notes'
export const TASKS_COLLECTION_ID =
  import.meta.env.VITE_APPWRITE_TASKS_COLLECTION_ID || 'job-tasks'
export const RESUMES_BUCKET_ID =
  import.meta.env.VITE_APPWRITE_RESUMES_BUCKET_ID || 'resumes'
export const COVER_LETTERS_COLLECTION_ID =
  import.meta.env.VITE_APPWRITE_COVER_LETTERS_COLLECTION_ID || 'cover-letters'
export const USER_PROFILES_COLLECTION_ID =
  import.meta.env.VITE_APPWRITE_USER_PROFILES_COLLECTION_ID || 'user-profiles'
