import { useState, useEffect, useRef } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const StatCard = ({ icon: Icon, label, value }) => {
  const [isHovered, setIsHovered] = useState(false)
  const cardRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cardRef.current && !cardRef.current.contains(event.target)) {
        setIsHovered(false)
      }
    }

    if (isHovered) {
      document.addEventListener('mousedown', handleClickOutside)
      document.addEventListener('touchstart', handleClickOutside, {
        passive: true,
      })
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('touchstart', handleClickOutside)
    }
  }, [isHovered])

  const handleInteraction = () => {
    setIsHovered(!isHovered)
  }

  const getResponsiveLabel = (label) => {
    switch (label) {
      case 'Active Applications':
        return (
          <>
            <span className="sm:hidden">Active</span>
            <span className="hidden sm:inline">Active Applications</span>
          </>
        )
      case 'Total Applications':
        return (
          <>
            <span className="sm:hidden">Total</span>
            <span className="hidden sm:inline">Total Applications</span>
          </>
        )
      default:
        return label
    }
  }

  const getTip = (label, value) => {
    const numValue = Number(value?.toString().replace('%', ''))

    switch (label) {
      case 'Active Applications':
        return {
          text:
            numValue === 0
              ? 'Time to start your job search!'
              : 'We recommend keeping 5-10 active applications',
          metric: {
            label: 'Recommended',
            value: '5-10',
            status: numValue < 5 ? 'below' : numValue > 10 ? 'above' : 'good',
          },
          action: {
            text: 'View job resources',
            link: '/resources',
          },
        }

      case 'Interview Rate':
        return {
          text:
            numValue === 0
              ? 'No interviews yet'
              : 'We typically see successful candidates achieve 20-30% interview rates',
          metric: {
            label: 'Target',
            value: '20-30%',
            status: numValue < 20 ? 'below' : numValue > 30 ? 'above' : 'good',
          },
          action: {
            text: 'View career resources',
            link: '/resources#career-resources',
          },
        }

      case 'Offer Rate':
        return {
          text:
            numValue === 0
              ? 'Keep going! Offers will come'
              : "Let's aim for a 15% offer rate",
          metric: {
            label: 'Target',
            value: '15%',
            status: numValue < 15 ? 'below' : numValue > 15 ? 'above' : 'good',
          },
          action: {
            text: 'View success strategies',
            link: '/resources#success-strategies',
          },
        }

      case 'Total Applications':
        return {
          text: 'Most successful job searches take 30+ applications',
          metric: {
            label: 'Progress',
            value: `${Math.min(Math.round((numValue / 30) * 100), 100)}%`,
            status: 'progress',
          },
          action: {
            text: 'Add new application',
            link: '#add-job',
          },
        }

      default:
        return null
    }
  }

  const tip = getTip(label, value)

  return (
    <div
      ref={cardRef}
      className="relative bg-white dark:bg-secondary-800 rounded-xl p-4 shadow-sm"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleInteraction}
      role="button"
      tabIndex={0}
    >
      <div className="flex items-center gap-2 mb-2">
        <Icon className="w-5 h-5 text-primary-600 dark:text-primary-400" />
        <span className="text-sm text-secondary-600 dark:text-secondary-400">
          {getResponsiveLabel(label)}
        </span>
      </div>

      <AnimatePresence>
        {tip && isHovered ? (
          <motion.div
            initial={{ opacity: 0, y: -5 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -5 }}
            transition={{ duration: 0.2 }}
            className="absolute left-0 right-0 sm:left-auto sm:right-auto sm:w-64
              top-12 p-3 sm:p-4 bg-white dark:bg-secondary-800 rounded-lg shadow-lg z-10
              mx-0 sm:mx-2 space-y-2 sm:space-y-3
              border border-secondary-100 dark:border-secondary-700"
          >
            <p className="text-xs sm:text-sm font-medium text-secondary-700 dark:text-secondary-300">
              {tip.text}
            </p>

            <div className="flex items-center gap-2 sm:gap-3 text-xs">
              {tip.metric.status === 'progress' ? (
                <>
                  <span className="hidden sm:inline text-secondary-600 dark:text-secondary-400">
                    {tip.metric.label}
                  </span>
                  <div className="flex-1 h-1.5 bg-secondary-100 dark:bg-secondary-700 rounded-full overflow-hidden">
                    <motion.div
                      initial={{ width: 0 }}
                      animate={{ width: tip.metric.value }}
                      className="h-full bg-primary-500"
                    />
                  </div>
                  <span className="text-secondary-600 dark:text-secondary-400">
                    {tip.metric.value}
                  </span>
                </>
              ) : (
                <>
                  <span className="text-secondary-600 dark:text-secondary-400">
                    {tip.metric.label}
                  </span>
                  <div className="flex items-center gap-1 sm:gap-2">
                    <span
                      className={`font-medium ${
                        tip.metric.status === 'below'
                          ? 'text-yellow-500'
                          : tip.metric.status === 'above'
                          ? 'text-green-500'
                          : tip.metric.status === 'good'
                          ? 'text-green-500'
                          : 'text-primary-500'
                      }`}
                    >
                      {tip.metric.value}
                    </span>
                  </div>
                </>
              )}
            </div>

            <Link
              to={tip.action.link}
              className="text-xs sm:text-sm text-primary-600 dark:text-primary-400 hover:underline inline-flex items-center gap-1"
            >
              {tip.action.text} →
            </Link>
          </motion.div>
        ) : (
          <div className="text-2xl font-bold text-secondary-900 dark:text-secondary-100">
            {value}
          </div>
        )}
      </AnimatePresence>
    </div>
  )
}

StatCard.propTypes = {
  icon: PropTypes.elementType.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

export default StatCard
