import { Link } from 'react-router-dom'
import { format } from 'date-fns'
import { FiClock, FiArrowRight } from 'react-icons/fi'
import { useBlog } from '../../contexts/BlogContext'
import { estimateReadingTime } from '../../utils/blog'

const BlogList = () => {
  const { posts, loading } = useBlog()

  if (loading) {
    return <div>Loading...</div>
  }

  const [featuredPost, ...otherPosts] = posts

  return (
    <div className="max-w-5xl mx-auto px-4 py-16">
      <div className="text-center mb-16">
        <h1 className="text-4xl font-bold text-secondary-900 dark:text-secondary-100 mb-4">
          Blog
        </h1>
        <p className="text-xl text-secondary-600 dark:text-secondary-400">
          Insights and guides for your job search journey
        </p>
      </div>

      {/* Featured Post */}
      {featuredPost && (
        <Link
          to={`/blog/${featuredPost.slug}`}
          className="block group mb-16 bg-white/50 dark:bg-secondary-800/50 rounded-2xl overflow-hidden border border-secondary-200/50 dark:border-secondary-700/50"
        >
          <div className="grid md:grid-cols-2 gap-8 p-8">
            <div className="aspect-[4/3] overflow-hidden rounded-xl">
              {featuredPost.frontmatter.image && (
                <img
                  src={featuredPost.frontmatter.image}
                  alt={featuredPost.frontmatter.title}
                  className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                />
              )}
            </div>
            <div>
              <div className="flex items-center gap-4 text-sm text-secondary-500 dark:text-secondary-400 mb-4">
                <time dateTime={featuredPost.frontmatter.date}>
                  {format(
                    new Date(featuredPost.frontmatter.date),
                    'MMMM d, yyyy',
                  )}
                </time>
                <span>•</span>
                <span className="flex items-center gap-1">
                  <FiClock />
                  {estimateReadingTime(featuredPost.content)} min read
                </span>
              </div>
              <h2 className="text-3xl font-bold mb-4 text-secondary-900 dark:text-secondary-100 group-hover:text-primary-600 dark:group-hover:text-primary-400 transition-colors">
                {featuredPost.frontmatter.title}
              </h2>
              <p className="text-lg text-secondary-600 dark:text-secondary-400 mb-6">
                {featuredPost.frontmatter.description}
              </p>
              <div className="inline-flex items-center gap-2 text-primary-600 dark:text-primary-400 font-medium">
                Read article
                <FiArrowRight className="transition-transform group-hover:translate-x-1" />
              </div>
            </div>
          </div>
        </Link>
      )}

      {/* Other Posts Grid */}
      <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
        {otherPosts.map((post) => (
          <Link
            key={post.slug}
            to={`/blog/${post.slug}`}
            className="group bg-white/50 dark:bg-secondary-800/50 rounded-xl overflow-hidden border border-secondary-200/50 dark:border-secondary-700/50"
          >
            {post.frontmatter.image && (
              <div className="aspect-[3/2] overflow-hidden">
                <img
                  src={post.frontmatter.image}
                  alt={post.frontmatter.title}
                  className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                />
              </div>
            )}
            <div className="p-6">
              <div className="flex items-center gap-4 text-sm text-secondary-500 dark:text-secondary-400 mb-4">
                <time dateTime={post.frontmatter.date}>
                  {format(new Date(post.frontmatter.date), 'MMMM d, yyyy')}
                </time>
                <span>•</span>
                <span className="flex items-center gap-1">
                  <FiClock />
                  {estimateReadingTime(post.content)} min read
                </span>
              </div>
              <h2 className="text-xl font-bold mb-3 text-secondary-900 dark:text-secondary-100 group-hover:text-primary-600 dark:group-hover:text-primary-400 transition-colors">
                {post.frontmatter.title}
              </h2>
              <p className="text-secondary-600 dark:text-secondary-400 line-clamp-2">
                {post.frontmatter.description}
              </p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  )
}

export default BlogList
