import { motion, AnimatePresence } from 'framer-motion'
import PropTypes from 'prop-types'
import {
  FiX,
  FiLoader,
  FiRefreshCw,
  FiCopy,
  FiCoffee,
  FiBriefcase,
  FiMinimize2,
} from 'react-icons/fi'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { createPortal } from 'react-dom'

export const CoverLetterModal = ({
  isOpen,
  onClose,
  coverLetter,
  onRegenerate,
  isGenerating,
  job,
  remainingCount,
  user,
  onShowUpgradeModal,
}) => {
  const [showOptions, setShowOptions] = useState(false)

  // Add scroll lock effect
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    }
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [isOpen])

  // Add escape key handler
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape') {
        onClose()
      }
    }

    if (isOpen) {
      document.addEventListener('keydown', handleEscape)
    }

    return () => {
      document.removeEventListener('keydown', handleEscape)
    }
  }, [isOpen, onClose])

  const REGEN_OPTIONS = [
    { id: 'default', label: 'Default Style', icon: FiRefreshCw },
    { id: 'casual', label: 'Casual', icon: FiCoffee },
    { id: 'professional', label: 'Professional', icon: FiBriefcase },
    { id: 'concise', label: 'Concise', icon: FiMinimize2 },
  ]

  const handleRegenerateClick = () => {
    if (user?.plan !== 'pro' && remainingCount === 0) {
      onShowUpgradeModal() // Show the upgrade modal
    } else {
      setShowOptions(true)
    }
  }

  if (!isOpen) return null

  // Render modal in a portal
  return createPortal(
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 z-50 overflow-y-auto bg-black/60"
        onClick={onClose}
      >
        <div className="min-h-screen px-4 flex items-center justify-center">
          <motion.div
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            className="relative w-full max-w-4xl bg-white dark:bg-secondary-800 rounded-2xl shadow-2xl"
            onClick={(e) => e.stopPropagation()}
          >
            {/* Decorative gradient header */}
            <div className="absolute inset-x-0 top-0 h-24 rounded-t-2xl bg-gradient-to-br from-primary-500/10 via-primary-500/5 to-transparent dark:from-primary-500/20 dark:via-primary-500/10" />

            {/* Modal content */}
            <div className="relative">
              {/* Header */}
              <div className="p-4 sm:p-6">
                <div className="flex justify-between items-start">
                  <div className="space-y-1">
                    <h2 className="text-xl sm:text-2xl font-semibold text-secondary-900 dark:text-secondary-100">
                      Cover Letter
                    </h2>
                    <div className="space-y-0.5">
                      <p className="text-base font-medium text-secondary-800 dark:text-secondary-200">
                        {job.title}
                      </p>
                      <p className="text-sm text-secondary-600 dark:text-secondary-400">
                        {job.company}
                      </p>
                    </div>
                  </div>
                  <button
                    onClick={onClose}
                    className="p-2 text-secondary-400 hover:text-secondary-600 dark:text-secondary-400 dark:hover:text-secondary-300 rounded-full hover:bg-secondary-100 dark:hover:bg-secondary-700/50 transition-colors"
                  >
                    <FiX size={20} />
                  </button>
                </div>
              </div>

              {/* Scrollable content */}
              <div className="px-4 sm:px-6">
                <div className="rounded-xl bg-secondary-50 dark:bg-secondary-900/50 border border-secondary-200/50 dark:border-secondary-700/50">
                  <div className="max-h-[calc(100vh-20rem)] overflow-y-auto p-4 sm:p-6">
                    <pre className="whitespace-pre-wrap font-sans text-secondary-900 dark:text-secondary-100">
                      {coverLetter}
                    </pre>
                  </div>
                </div>
              </div>

              {/* Footer */}
              <div className="p-4 sm:p-6">
                {/* Stack on mobile, row on desktop */}
                <div
                  className={`flex flex-col sm:flex-row sm:items-center gap-4
                  ${
                    user?.plan === 'pro'
                      ? 'sm:justify-end'
                      : 'sm:justify-between'
                  }`}
                >
                  {/* Remaining count - Only show for free users */}
                  {typeof remainingCount === 'number' &&
                    remainingCount < 3 &&
                    user?.plan !== 'pro' && (
                      <p className="text-sm text-secondary-600 dark:text-secondary-400 ml-2 sm:ml-0">
                        {remainingCount === 0
                          ? "You've used all 3 free generations"
                          : `${remainingCount} generation${
                              remainingCount === 1 ? '' : 's'
                            } remaining`}
                      </p>
                    )}

                  {/* Actions - Full width on mobile */}
                  <div className="flex flex-wrap sm:flex-nowrap gap-2 sm:gap-4">
                    <button
                      onClick={() => {
                        navigator.clipboard.writeText(coverLetter)
                        toast.success('Cover letter copied to clipboard')
                      }}
                      className="flex-1 sm:flex-none inline-flex items-center justify-center gap-2 px-4 py-2 rounded-lg text-sm font-medium
                        bg-secondary-100 text-secondary-600 hover:bg-secondary-200
                        dark:bg-secondary-800 dark:text-secondary-400 dark:hover:bg-secondary-700
                        transition-colors"
                    >
                      <FiCopy />
                      <span>Copy</span>
                    </button>

                    <div className="relative">
                      <button
                        onClick={handleRegenerateClick}
                        disabled={isGenerating}
                        className="flex-1 sm:flex-none inline-flex items-center justify-center gap-2 px-4 py-2 rounded-lg text-sm font-medium
                          bg-primary-50 text-primary-600 hover:bg-primary-100
                          dark:bg-primary-900/20 dark:text-primary-300 dark:hover:bg-primary-900/30
                          disabled:opacity-60 disabled:cursor-not-allowed transition-colors"
                      >
                        {isGenerating ? (
                          <>
                            <FiLoader className="animate-spin" />
                            <span>Regenerating...</span>
                          </>
                        ) : (
                          <>
                            <FiRefreshCw />
                            <span>Regenerate</span>
                          </>
                        )}
                      </button>

                      {/* Only show options popover if user has generations remaining or is pro */}
                      <AnimatePresence>
                        {showOptions &&
                          (user?.plan === 'pro' || remainingCount > 0) && (
                            <>
                              <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                className="fixed inset-0"
                                onClick={() => setShowOptions(false)}
                              />
                              <motion.div
                                initial={{ opacity: 0, scale: 0.95 }}
                                animate={{ opacity: 1, scale: 1 }}
                                exit={{ opacity: 0, scale: 0.95 }}
                                className="absolute right-0 bottom-full mb-2 w-48 rounded-xl bg-white dark:bg-secondary-800 shadow-lg ring-1 ring-secondary-200 dark:ring-secondary-700 overflow-hidden"
                              >
                                {REGEN_OPTIONS.map((option) => (
                                  <button
                                    key={option.id}
                                    onClick={() => {
                                      onRegenerate(option.id)
                                      setShowOptions(false)
                                    }}
                                    className="w-full flex items-center gap-2 px-4 py-2.5 text-left text-sm hover:bg-secondary-50 dark:hover:bg-secondary-700/50 text-secondary-700 dark:text-secondary-300"
                                  >
                                    <option.icon className="w-4 h-4" />
                                    {option.label}
                                  </button>
                                ))}
                              </motion.div>
                            </>
                          )}
                      </AnimatePresence>
                    </div>

                    <button
                      onClick={onClose}
                      className="flex-1 sm:flex-none inline-flex items-center justify-center px-4 py-2 rounded-lg text-sm font-medium
                        text-secondary-600 hover:text-secondary-700
                        dark:text-secondary-400 dark:hover:text-secondary-300
                        transition-colors"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </motion.div>
    </AnimatePresence>,
    document.body,
  )
}

CoverLetterModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  coverLetter: PropTypes.string,
  onRegenerate: PropTypes.func.isRequired,
  isGenerating: PropTypes.bool.isRequired,
  job: PropTypes.shape({
    title: PropTypes.string.isRequired,
    company: PropTypes.string.isRequired,
  }).isRequired,
  remainingCount: PropTypes.number,
  user: PropTypes.shape({
    plan: PropTypes.string,
  }),
  onShowUpgradeModal: PropTypes.func.isRequired,
}
