import { useMemo, useState } from 'react'
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
import PropTypes from 'prop-types'
import { format, subDays, startOfDay, isWithinInterval } from 'date-fns'

const RANGE_OPTIONS = [
  { label: '7 Days', days: 7 },
  { label: '14 Days', days: 14 },
  { label: '30 Days', days: 30 },
]

const getJobsForDate = (jobs, date) => {
  const targetDate = format(date, 'yyyy-MM-dd')
  return jobs.filter(
    (job) => format(new Date(job.createdAt), 'yyyy-MM-dd') === targetDate,
  )
}

const JobActivityChart = ({ jobs }) => {
  const [selectedRange, setSelectedRange] = useState(14)

  const chartData = useMemo(() => {
    const endDate = startOfDay(new Date())
    const startDate = subDays(endDate, selectedRange - 1)

    const dates = Array.from({ length: selectedRange }, (_, i) => {
      const date = subDays(endDate, selectedRange - 1 - i)
      return {
        date,
        applications: 0,
      }
    })

    jobs.forEach((job) => {
      const jobDate = startOfDay(new Date(job.createdAt))
      if (
        isWithinInterval(jobDate, {
          start: startDate,
          end: endDate,
        })
      ) {
        const index = dates.findIndex(
          (d) => format(d.date, 'yyyy-MM-dd') === format(jobDate, 'yyyy-MM-dd'),
        )
        if (index !== -1) {
          dates[index].applications++
        }
      }
    })

    return dates.map((item) => ({
      date: format(item.date, 'MMM d'),
      applications: item.applications,
      fullDate: item.date, // Keep full date for tooltip
    }))
  }, [jobs, selectedRange])

  const totalApplications = chartData.reduce(
    (sum, day) => sum + day.applications,
    0,
  )
  const avgApplications = (totalApplications / selectedRange).toFixed(1)

  return (
    <div className="space-y-4">
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-3">
        <div>
          <h3 className="text-lg font-semibold text-secondary-900 dark:text-secondary-100">
            Daily application activity
          </h3>
          <p className="text-sm text-secondary-600 dark:text-secondary-400">
            Average: {avgApplications} applications per day
          </p>
        </div>

        <div className="flex gap-2 text-sm">
          {RANGE_OPTIONS.map((option) => (
            <button
              key={option.days}
              onClick={() => setSelectedRange(option.days)}
              className={`px-3 py-1.5 rounded-lg transition-colors
                ${
                  selectedRange === option.days
                    ? 'bg-primary-100 text-primary-700 dark:bg-primary-900/50 dark:text-primary-300'
                    : 'text-secondary-600 hover:bg-secondary-100 dark:text-secondary-400 dark:hover:bg-secondary-800'
                }
              `}
            >
              {option.label}
            </button>
          ))}
        </div>
      </div>

      <div className="h-64">
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            data={chartData}
            margin={{
              top: 10,
              right: 10,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" opacity={0.1} />
            <XAxis
              dataKey="date"
              tick={{ fill: '#9CA3AF' }}
              tickLine={{ stroke: '#9CA3AF' }}
            />
            <YAxis
              tick={{ fill: '#9CA3AF' }}
              tickLine={{ stroke: '#9CA3AF' }}
              allowDecimals={false}
            />
            <Tooltip
              content={({ active, payload }) => {
                if (active && payload && payload.length) {
                  const data = payload[0].payload
                  const dayJobs = getJobsForDate(jobs, data.fullDate)

                  return (
                    <div className="bg-secondary-800 p-3 rounded-lg shadow-lg max-w-xs">
                      <p className="text-secondary-200 text-sm mb-2">
                        {format(data.fullDate, 'MMMM d, yyyy')}
                      </p>
                      <p className="text-white font-medium mb-2">
                        {data.applications} application
                        {data.applications !== 1 ? 's' : ''}
                      </p>
                      {dayJobs.length > 0 && (
                        <div className="border-t border-secondary-700 mt-2 pt-2">
                          <div className="max-h-48 overflow-y-auto">
                            {dayJobs.map((job) => (
                              <div
                                key={job.$id}
                                className="text-sm py-1 text-secondary-300"
                              >
                                <span className="font-medium">{job.title}</span>
                                <span className="text-secondary-400">
                                  {' '}
                                  at {job.company}
                                </span>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  )
                }
                return null
              }}
            />
            <Area
              type="monotone"
              dataKey="applications"
              stroke="#6366F1"
              fill="#6366F1"
              fillOpacity={0.2}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

JobActivityChart.propTypes = {
  jobs: PropTypes.arrayOf(
    PropTypes.shape({
      $id: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      company: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default JobActivityChart
