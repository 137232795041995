import { useState } from 'react'
import { addJob, addNote, addTask } from '../utils/db'
import PropTypes from 'prop-types'
import { toast } from 'react-hot-toast'
import { useJobs } from '../contexts/JobContext'
import { showUpgradeModal } from '../utils/uiUtils.jsx'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { FiCompass } from 'react-icons/fi'

const JobForm = ({ user, onSuccess, onSubmit, onSubmitComplete }) => {
  const navigate = useNavigate()
  const currentLocation = useLocation()
  const { addNewJob } = useJobs()
  const [title, setTitle] = useState('')
  const [company, setCompany] = useState('')
  const [initialNote, setInitialNote] = useState('')
  const [jobUrl, setJobUrl] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [salary, setSalary] = useState('')
  const [jobLocation, setJobLocation] = useState('')
  const [aiGeneratedTasks, setAiGeneratedTasks] = useState([])
  const [limitReached, setLimitReached] = useState(false)
  const [showViewJobModal, setShowViewJobModal] = useState(false)

  const handleFetchJobDetails = async (urlParam) => {
    setIsLoading(true)
    setError(null)
    setLimitReached(false)
    try {
      const response = await fetch(
        `${
          import.meta.env.VITE_API_URL || 'http://localhost:3001'
        }/job-scraper`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            url: typeof urlParam === 'string' ? urlParam : jobUrl,
            userId: user.$id,
            userPlan: user.plan,
          }),
        },
      )

      if (!response.ok) {
        const errorData = await response.json()
        throw new Error(errorData.error || 'Failed to fetch job details')
      }

      const jobDetails = await response.json()

      if (jobDetails.limitReached) {
        setLimitReached(true)
        showUpgradeModal({
          title: 'AI Processing Limit Reached',
          message:
            "You've reached your daily limit for AI job processing. Upgrade to Pro for unlimited AI processing.",
        })
      }

      if (jobDetails) {
        setTitle(jobDetails.title || '')
        setCompany(jobDetails.company || '')
        setSalary(jobDetails.salary || '')
        setJobLocation(jobDetails.location || '')
        setAiGeneratedTasks(jobDetails.preparationTasks || [])

        if (!jobDetails.limitReached) {
          setInitialNote(
            `${jobDetails.description || 'N/A'}
Requirements: ${
              jobDetails.requirements
                ? Array.isArray(jobDetails.requirements)
                  ? jobDetails.requirements.join(', ')
                  : jobDetails.requirements
                : 'N/A'
            }`,
          )
        }
      } else {
        setError(
          "Couldn't extract job details. Please fill in the form manually.",
        )
      }
    } catch (error) {
      setError(`Failed to fetch job details: ${error.message}`)
    } finally {
      setIsLoading(false)
    }
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault()
    onSubmit?.()
    setError(null)

    try {
      const newJob = await addJob(
        {
          title,
          company,
          status,
          salary,
          location: jobLocation,
          url: jobUrl,
        },
        user.$id,
      )

      if (initialNote) {
        await addNote({ jobId: newJob.$id, content: initialNote }, user.$id)
      }

      // Add AI-generated tasks
      const taskPromises = aiGeneratedTasks.map((taskDescription) =>
        addTask(
          {
            jobId: newJob.$id,
            description: taskDescription,
            dueDate: null,
          },
          user.$id,
        ),
      )
      await Promise.all(taskPromises)

      // Add the new job to the context
      addNewJob(newJob)

      toast.success('Job application added successfully!')

      // Only show the modal if we're not on the homepage
      if (currentLocation.pathname !== '/') {
        setShowViewJobModal(true)
      } else {
        onSuccess?.()
      }
    } catch (error) {
      console.error('Error adding job:', error)
      setError('Failed to add job application. Please try again.')
      toast.error('Failed to add job application')
    } finally {
      onSubmitComplete?.()
    }
  }

  const handleUrlChange = (e) => {
    const url = e.target.value
    setJobUrl(url)

    // Only auto-fetch if it's a paste event and a valid URL
    if (e.nativeEvent.inputType === 'insertFromPaste' && isValidUrl(url)) {
      setTimeout(() => {
        handleFetchJobDetails(url)
      }, 100)
    }
  }

  const isValidUrl = (string) => {
    try {
      const url = new URL(string)
      return url.protocol === 'http:' || url.protocol === 'https:'
    } catch {
      return false
    }
  }

  // Update the inputClass constant with better visibility for both themes
  const inputClass = `w-full px-4 py-2.5 rounded-xl border
    border-secondary-300 dark:border-secondary-600
    bg-secondary-50 dark:bg-secondary-700
    text-secondary-900 dark:text-secondary-100
    placeholder-secondary-500 dark:placeholder-secondary-400
    focus:border-primary-500 dark:focus:border-primary-400
    focus:ring-2 focus:ring-primary-500/20 dark:focus:ring-primary-400/20
    hover:border-secondary-400 dark:hover:border-secondary-500`

  return (
    <div className="space-y-6">
      {limitReached && (
        <div className="bg-yellow-50 dark:bg-yellow-900/20 border border-yellow-200 dark:border-yellow-700 rounded-xl p-4 mb-6">
          <h3 className="text-yellow-800 dark:text-yellow-200 font-medium mb-2">
            Daily AI Limit Reached
          </h3>
          <p className="text-yellow-700 dark:text-yellow-300 text-sm mb-3">
            You&apos;re now getting basic job details. Upgrade to Pro to unlock:
          </p>
          <ul className="list-disc list-inside text-sm text-yellow-700 dark:text-yellow-300 space-y-1">
            <li>AI-powered job analysis</li>
            <li>Personalized interview prep tasks</li>
            <li>Detailed requirements analysis</li>
            <li>Salary insights and negotiation tips</li>
          </ul>
          <button
            onClick={() => {
              window.location.href = '/upgrade'
            }}
            className="mt-3 text-sm font-medium text-yellow-800 dark:text-yellow-200 hover:text-yellow-900 dark:hover:text-yellow-100"
          >
            Upgrade to Pro →
          </button>
        </div>
      )}

      <div className="space-y-2">
        <label
          htmlFor="jobUrl"
          className="block text-sm font-medium text-secondary-700 dark:text-secondary-300"
        >
          Job Posting URL
        </label>
        <div className="flex gap-2">
          <input
            type="url"
            id="jobUrl"
            name="url"
            value={jobUrl}
            onChange={handleUrlChange}
            className={inputClass}
            placeholder="https://example.com/job-posting"
          />
          <button
            type="button"
            onClick={() => handleFetchJobDetails()}
            disabled={isLoading || !jobUrl}
            className={`shrink-0 px-6 py-2.5 rounded-xl font-medium
              ${
                isLoading || !jobUrl
                  ? 'bg-secondary-100 dark:bg-secondary-800 text-secondary-400 dark:text-secondary-500 cursor-not-allowed'
                  : 'bg-primary-600 hover:bg-primary-700 dark:bg-primary-500 dark:hover:bg-primary-600 text-white'
              }`}
          >
            {isLoading ? (
              <span className="flex items-center gap-2">
                <svg className="animate-spin h-4 w-4" viewBox="0 0 24 24">
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                    fill="none"
                  />
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
                Extracting...
              </span>
            ) : (
              'Extract'
            )}
          </button>
        </div>
        <Link
          to="/resources"
          className="inline-flex items-center gap-1.5 text-sm text-primary-600 hover:text-primary-700 dark:text-primary-400 dark:hover:text-primary-300"
        >
          <FiCompass className="w-4 h-4" />
          Need job leads? Click here
        </Link>
        {error && (
          <p className="mt-2 text-sm text-red-600 dark:text-red-400">{error}</p>
        )}
      </div>

      <form id="jobForm" onSubmit={handleFormSubmit} className="space-y-6">
        <div className="grid gap-6 sm:grid-cols-2">
          <div className="space-y-2">
            <label
              htmlFor="title"
              className="block text-sm font-medium text-secondary-700 dark:text-secondary-300"
            >
              Job Title *
            </label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className={inputClass}
              required
              maxLength={100}
              placeholder="e.g. Frontend Developer"
            />
          </div>

          <div className="space-y-2">
            <label
              htmlFor="company"
              className="block text-sm font-medium text-secondary-700 dark:text-secondary-300"
            >
              Company *
            </label>
            <input
              type="text"
              id="company"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              className={inputClass}
              required
              maxLength={100}
              placeholder="e.g. Acme Inc."
            />
          </div>
        </div>

        <div className="grid gap-6 sm:grid-cols-2">
          <div className="space-y-2">
            <label
              htmlFor="salary"
              className="block text-sm font-medium text-secondary-700 dark:text-secondary-300"
            >
              Salary
            </label>
            <input
              type="text"
              id="salary"
              value={salary}
              onChange={(e) => setSalary(e.target.value)}
              className={inputClass}
              maxLength={100}
              placeholder="e.g. $50,000 - $70,000"
            />
          </div>

          <div className="space-y-2">
            <label
              htmlFor="location"
              className="block text-sm font-medium text-secondary-700 dark:text-secondary-300"
            >
              Location
            </label>
            <input
              type="text"
              id="location"
              value={jobLocation}
              onChange={(e) => setJobLocation(e.target.value)}
              className={inputClass}
              maxLength={100}
              placeholder="e.g. New York, NY"
            />
          </div>
        </div>

        <div className="space-y-2">
          <label
            htmlFor="initialNote"
            className="block text-sm font-medium text-secondary-700 dark:text-secondary-300"
          >
            Notes
          </label>
          <textarea
            id="initialNote"
            value={initialNote}
            onChange={(e) => setInitialNote(e.target.value)}
            className={inputClass}
            rows="4"
            maxLength={1000}
            placeholder="Add any initial notes about the position..."
          />
        </div>

        {aiGeneratedTasks.length > 0 && (
          <div className="md:col-span-2 bg-secondary-50 dark:bg-secondary-700/50 p-4 rounded-lg">
            <h3 className="text-lg font-semibold mb-3 text-secondary-900 dark:text-secondary-100">
              Recommended Preparation Tasks
            </h3>
            <ul className="list-disc pl-5 space-y-2 text-secondary-700 dark:text-secondary-300">
              {aiGeneratedTasks.map((task, index) => (
                <li key={index}>{task}</li>
              ))}
            </ul>
          </div>
        )}
      </form>

      {showViewJobModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/60">
          <div className="bg-white dark:bg-secondary-800 p-6 rounded-xl max-w-md w-full mx-4 space-y-4">
            <h3 className="text-xl font-semibold text-secondary-900 dark:text-secondary-100">
              Job Added Successfully
            </h3>
            <p className="text-secondary-600 dark:text-secondary-400">
              Would you like to view your jobs list?
            </p>
            <div className="flex gap-3">
              <button
                onClick={() => {
                  setShowViewJobModal(false)
                  onSuccess?.()
                }}
                className="flex-1 px-4 py-2 rounded-lg text-secondary-600 dark:text-secondary-400 hover:bg-secondary-100 dark:hover:bg-secondary-700"
              >
                Maybe Later
              </button>
              <button
                onClick={() => {
                  setShowViewJobModal(false)
                  navigate('/')
                  onSuccess?.()
                }}
                className="flex-1 px-4 py-2 rounded-lg bg-primary-600 hover:bg-primary-700 dark:bg-primary-500 dark:hover:bg-primary-600 text-white"
              >
                View Jobs
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

JobForm.propTypes = {
  user: PropTypes.shape({
    $id: PropTypes.string.isRequired,
    plan: PropTypes.oneOf(['free', 'pro']).isRequired,
  }).isRequired,
  onSuccess: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  onSubmitComplete: PropTypes.func,
}

export default JobForm
