import toast from 'react-hot-toast'

export const showUpgradeModal = ({ title, message }) => {
  toast(
    (t) => (
      <div className="flex flex-col gap-4 p-4 max-w-md">
        <h3 className="font-bold text-lg text-secondary-900 dark:text-secondary-100">
          {title}
        </h3>
        <p className="text-secondary-700 dark:text-secondary-300">
          {message ||
            "You've reached your daily limit for AI processing. Upgrade to Pro for unlimited AI features!"}
        </p>
        <div className="flex gap-2">
          <button
            onClick={() => {
              window.location.href = '/upgrade'
              toast.dismiss(t.id)
            }}
            className="btn btn-primary flex-1"
          >
            Upgrade
          </button>
          <button
            onClick={() => toast.dismiss(t.id)}
            className="btn btn-secondary"
          >
            Maybe Later
          </button>
        </div>
      </div>
    ),
    {
      duration: 10000,
      position: 'top-center',
    },
  )
}

export const showPremiumFeatureTeaser = (jobDetails) => {
  if (jobDetails.preparationTasks?.length > 0) {
    toast(
      (t) => (
        <div className="flex flex-col gap-4 p-4">
          <h3 className="font-bold text-lg">Unlock AI Interview Prep</h3>
          <p>
            Upgrade to Pro to get personalized interview preparation tasks and
            insights.
          </p>
          <button
            onClick={() => {
              window.location.href = '/upgrade'
              toast.dismiss(t.id)
            }}
            className="btn btn-primary"
          >
            Learn More
          </button>
        </div>
      ),
      {
        duration: 5000,
      },
    )
  }
}

export const showAIProcessingLimitInfo = (remainingProcesses) => {
  toast(
    <div className="text-sm text-secondary-700 dark:text-secondary-300">
      {remainingProcesses > 0
        ? `You have ${remainingProcesses} AI processing ${
            remainingProcesses === 1 ? 'credit' : 'credits'
          } remaining today`
        : 'You have used all your AI processing credits for today'}
    </div>,
    {
      duration: 4000,
      position: 'bottom-right',
    },
  )
}
