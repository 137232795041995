import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import {
  FiTarget,
  FiTrendingUp,
  FiZap,
  FiStar,
  FiArrowRight,
} from 'react-icons/fi'
import NextJobLogo from './icons/NextJobLogo'
import { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

const stats = [
  {
    icon: FiTarget,
    value: '3x',
    label: 'More Interviews',
  },
  {
    icon: FiZap,
    value: '80%',
    label: 'Faster Applications',
  },
  {
    icon: FiTrendingUp,
    value: '1min',
    label: 'Setup Time',
  },
  {
    icon: FiStar,
    value: '100+',
    label: 'Happy Users',
  },
]

const features = [
  {
    title: 'AI-Powered Job Tracking',
    description:
      'Add jobs in one click, let AI handle all the details automatically',
    icon: FiZap,
    color: 'from-primary-400/20 to-primary-600/20',
  },
  {
    title: 'Smart Cover Letters',
    description:
      'Generate personalized cover letters tailored to each role instantly',
    icon: FiTarget,
    color: 'from-primary-400/20 via-purple-500/20 to-primary-600/20',
  },
  {
    title: 'Interview Preparation',
    description:
      'Get custom preparation tasks and curated resources for each role.',
    icon: FiTrendingUp,
    color: 'from-primary-500/20 to-primary-600/20',
  },
]

const MagneticButton = ({ children }) => {
  const buttonRef = useRef(null)
  const [position, setPosition] = useState({ x: 0, y: 0 })

  const handleMouseMove = (e) => {
    const { clientX, clientY } = e
    const { left, top, width, height } =
      buttonRef.current.getBoundingClientRect()
    const x = (clientX - (left + width / 2)) * 0.2
    const y = (clientY - (top + height / 2)) * 0.2
    setPosition({ x, y })
  }

  const handleMouseLeave = () => setPosition({ x: 0, y: 0 })

  return (
    <motion.div
      ref={buttonRef}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      animate={{ x: position.x, y: position.y }}
      transition={{ type: 'spring', stiffness: 150, damping: 15 }}
    >
      {children}
    </motion.div>
  )
}

MagneticButton.propTypes = {
  children: PropTypes.node.isRequired,
}

const LandingPage = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 })

  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePosition({
        x: e.clientX / window.innerWidth,
        y: e.clientY / window.innerHeight,
      })
    }
    window.addEventListener('mousemove', handleMouseMove)
    return () => window.removeEventListener('mousemove', handleMouseMove)
  }, [])

  return (
    <div className="min-h-screen relative overflow-hidden">
      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-20 pb-16 relative z-10">
        <div className="text-center space-y-8 md:space-y-12">
          {/* Logo + Title */}
          <motion.div
            className="flex items-center justify-center gap-3 mb-8"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <NextJobLogo className="w-12 h-12" />
            <h1 className="text-4xl md:text-6xl font-bold text-primary-600 dark:text-primary-400">
              NextJob
            </h1>
          </motion.div>

          {/* Hero Content */}
          <motion.div
            className="space-y-6"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
          >
            <h2 className="text-3xl md:text-5xl font-bold text-secondary-900 dark:text-secondary-100 max-w-4xl mx-auto leading-tight">
              <span>Skip the spreadsheets.</span>{' '}
              <span className="text-primary-600 dark:text-primary-400">
                Focus on landing the job.
              </span>
            </h2>
            <p className="text-xl text-secondary-600 dark:text-secondary-300 max-w-2xl mx-auto">
              Let AI handle your job search details while you prepare for
              interviews. Join thousands of job seekers who land interviews 3x
              faster.
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center pt-4">
              <MagneticButton>
                <Link
                  to="/login"
                  className="group inline-flex items-center justify-center gap-2 bg-primary-600 hover:bg-primary-700 text-white px-8 py-3 rounded-xl font-semibold transition-all duration-200"
                >
                  Get Started Free
                  <FiArrowRight className="transition-transform group-hover:translate-x-1" />
                </Link>
              </MagneticButton>
            </div>
          </motion.div>

          {/* Stats Section */}
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-8 max-w-6xl mx-auto">
            {stats.map(({ icon: Icon, value, label, description }) => (
              <motion.div
                key={label}
                className="text-center p-4 md:p-6 rounded-xl bg-white/50 dark:bg-secondary-800/80 backdrop-blur-sm border border-secondary-200/50 dark:border-secondary-700/50"
                whileHover={{
                  y: -5,
                  scale: 1.02,
                  boxShadow: '0 10px 40px -10px rgba(0,0,0,0.1)',
                }}
                transition={{
                  duration: 0.2,
                  ease: 'easeOut',
                }}
              >
                <Icon className="w-8 h-8 mx-auto mb-4 text-primary-600 dark:text-primary-400" />
                <div className="text-3xl font-bold text-secondary-900 dark:text-secondary-100">
                  {value}
                </div>
                <div className="text-sm font-medium text-secondary-900 dark:text-secondary-100">
                  {label}
                </div>
                <div className="text-xs text-secondary-600 dark:text-secondary-400 mt-1">
                  {description}
                </div>
              </motion.div>
            ))}
          </div>

          {/* Features Section */}
          <div className="grid md:grid-cols-3 gap-8 max-w-6xl mx-auto">
            {features.map(({ title, description, icon: Icon, color }) => (
              <motion.div
                key={title}
                className="relative p-6 rounded-xl bg-white/50 dark:bg-secondary-800/50 backdrop-blur-sm border border-secondary-200/50 dark:border-secondary-700/50 group"
                whileHover={{
                  y: -5,
                  scale: 1.02,
                  boxShadow: '0 8px 30px -10px rgba(79, 70, 229, 0.2)',
                }}
                transition={{
                  duration: 0.2,
                  ease: 'easeOut',
                }}
              >
                <div
                  className={`absolute inset-0 rounded-xl bg-gradient-to-br ${color} opacity-0 dark:group-hover:opacity-100 transition-all duration-200`}
                />
                <div className="relative space-y-4">
                  <div className="flex items-center gap-3">
                    <motion.div
                      whileHover={{ rotate: 15 }}
                      transition={{ duration: 0.15, ease: 'easeOut' }}
                    >
                      <Icon className="w-6 h-6 text-primary-600 dark:text-primary-400" />
                    </motion.div>
                    <h3 className="text-xl font-semibold text-secondary-900 dark:text-secondary-100">
                      {title}
                    </h3>
                  </div>
                  <p className="text-secondary-600 dark:text-secondary-400">
                    {description}
                  </p>
                </div>
              </motion.div>
            ))}
          </div>

          {/* CTA Section */}
          <motion.div
            whileHover={{ scale: 1.02 }}
            transition={{ duration: 0.2, ease: 'easeOut' }}
            className="relative bg-gradient-to-r from-primary-600 to-primary-500 dark:from-primary-900 dark:to-primary-800 p-8 md:p-12 rounded-2xl max-w-4xl mx-auto mt-16 text-white overflow-hidden"
          >
            <div className="relative z-10">
              <h3 className="text-2xl md:text-3xl font-bold mb-4">
                Land Your Dream Job
              </h3>
              <p className="text-lg text-primary-50 mb-6">
                Stop getting lost in the details. Let&apos;s organize your job
                search and create a clear path from application to interview.
              </p>
              <Link
                to="/login"
                className="inline-block bg-white text-primary-600 hover:bg-primary-50 px-8 py-3 rounded-xl font-semibold transition-colors"
              >
                Get Started for Free
              </Link>
            </div>
            <div className="absolute inset-0 bg-white/10 backdrop-blur-3xl" />
          </motion.div>
        </div>
      </div>

      {/* Background gradient that follows mouse */}
      <motion.div
        className="fixed inset-0 pointer-events-none"
        style={{
          background: `radial-gradient(circle at ${mousePosition.x * 100}% ${
            mousePosition.y * 100
          }%, rgba(79, 70, 229, 0.1) 0%, transparent 50%)`,
        }}
      />
    </div>
  )
}

export default LandingPage
