import { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { Link, useNavigate } from 'react-router-dom'
import { FaGoogle, FaGithub } from 'react-icons/fa'
import { FiMail, FiLock, FiUser, FiArrowLeft } from 'react-icons/fi'
import PropTypes from 'prop-types'
import NextJobLogo from './icons/NextJobLogo'
import { account, id } from '../utils/appwrite'
import toast from 'react-hot-toast'

const Login = ({ setUser }) => {
  const navigate = useNavigate()
  const [isSignUp, setIsSignUp] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [name, setName] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)

    try {
      if (isSignUp) {
        // Create account using ID.unique()
        await account.create(id.unique(), email, password, name)
        // Login after successful signup
        await account.createEmailSession(email, password)
        const accountDetails = await account.get()
        setUser(accountDetails)
        toast.success('Account created successfully!')
        navigate('/')
      } else {
        // Login
        await account.createEmailSession(email, password)
        const accountDetails = await account.get()
        setUser(accountDetails)
        toast.success('Logged in successfully!')
        navigate('/')
      }
    } catch (error) {
      console.error('Auth error:', error)
      toast.error(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  const handleGoogleSignIn = async () => {
    try {
      setIsLoading(true)
      // Get the current URL for the redirect
      const currentUrl = window.location.href
      // Create OAuth2 session with Google
      account.createOAuth2Session(
        'google',
        currentUrl,
        `${currentUrl}?failure=true`,
      )
    } catch (error) {
      console.error('Google sign-in error:', error)
      toast.error('Failed to sign in with Google')
      setIsLoading(false)
    }
  }

  const handleGithubSignIn = async () => {
    try {
      setIsLoading(true)
      const currentUrl = window.location.href
      account.createOAuth2Session(
        'github',
        currentUrl,
        `${currentUrl}?failure=true`,
      )
    } catch (error) {
      console.error('GitHub sign-in error:', error)
      toast.error('Failed to sign in with GitHub')
      setIsLoading(false)
    }
  }

  const containerVariants = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 },
  }

  const formVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: { opacity: 1, x: 0 },
  }

  return (
    <div className="min-h-screen flex items-center justify-center px-4">
      <motion.div
        variants={containerVariants}
        initial="initial"
        animate="animate"
        exit="exit"
        className="w-full max-w-md mt-0"
      >
        {/* Logo Section */}
        <div className="text-center mb-6">
          <Link to="/" className="inline-block">
            <div className="flex items-center justify-center gap-2 group">
              <NextJobLogo className="w-10 h-10 transition-transform group-hover:-rotate-12" />
              <span className="text-2xl font-bold text-primary-600 dark:text-primary-400">
                NextJob
              </span>
            </div>
          </Link>
        </div>

        <motion.div
          className="bg-white dark:bg-secondary-800 rounded-2xl shadow-xl overflow-hidden"
          variants={formVariants}
          initial="hidden"
          animate="visible"
        >
          {/* Header */}
          <div className="relative px-6 pt-6 pb-4">
            <AnimatePresence mode="wait">
              <motion.h2
                key={isSignUp ? 'signup' : 'login'}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className="text-2xl font-bold text-secondary-900 dark:text-secondary-100 text-center"
              >
                {isSignUp ? 'Create your account' : 'Welcome back'}
              </motion.h2>
            </AnimatePresence>
          </div>

          {/* Google Sign-in Button */}
          <div className="px-6">
            <button
              onClick={handleGoogleSignIn}
              disabled={isLoading}
              className="w-full flex items-center justify-center gap-3 px-4 py-3 rounded-xl
                border-2 border-secondary-200 dark:border-secondary-700
                hover:bg-secondary-50 dark:hover:bg-secondary-700/50
                text-secondary-900 dark:text-secondary-100
                transition-colors duration-200 group"
            >
              <FaGoogle
                className="text-lg text-primary-600 dark:text-primary-400
                transition-transform group-hover:scale-110"
              />
              Continue with Google
            </button>
          </div>

          {/* GitHub Sign-in Button */}
          <div className="px-6">
            <button
              onClick={handleGithubSignIn}
              disabled={isLoading}
              className="w-full flex items-center justify-center gap-3 px-4 py-3 rounded-xl
                border-2 border-secondary-200 dark:border-secondary-700
                hover:bg-secondary-50 dark:hover:bg-secondary-700/50
                text-secondary-900 dark:text-secondary-100
                transition-colors duration-200 group mt-3"
            >
              <FaGithub
                className="text-lg text-secondary-900 dark:text-secondary-100
                transition-transform group-hover:scale-110"
              />
              Continue with GitHub
            </button>
          </div>

          {/* Divider */}
          <div className="relative my-6 px-6">
            <div className="absolute inset-0 flex items-center px-6">
              <div className="w-full border-t border-secondary-200 dark:border-secondary-700" />
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-white dark:bg-secondary-800 text-secondary-500">
                Or continue with email
              </span>
            </div>
          </div>

          {/* Form */}
          <form onSubmit={handleSubmit} className="px-6 pb-6 space-y-4">
            <AnimatePresence>
              {isSignUp && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: 'auto' }}
                  exit={{ opacity: 0, height: 0 }}
                >
                  <div className="relative">
                    <FiUser className="absolute left-3 top-1/2 -translate-y-1/2 text-secondary-400" />
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Full name"
                      className="w-full pl-10 pr-4 py-2.5 rounded-xl border border-secondary-200
                        dark:border-secondary-700 dark:bg-secondary-800/50
                        focus:ring-2 focus:ring-primary-500/20 dark:focus:ring-primary-400/20
                        text-secondary-900 dark:text-secondary-100"
                      required={isSignUp}
                    />
                  </div>
                </motion.div>
              )}
            </AnimatePresence>

            <div className="relative">
              <FiMail className="absolute left-3 top-1/2 -translate-y-1/2 text-secondary-400" />
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email address"
                className="w-full pl-10 pr-4 py-2.5 rounded-xl border border-secondary-200
                  dark:border-secondary-700 dark:bg-secondary-800/50
                  focus:ring-2 focus:ring-primary-500/20 dark:focus:ring-primary-400/20
                  text-secondary-900 dark:text-secondary-100"
                required
              />
            </div>

            <div className="relative">
              <FiLock className="absolute left-3 top-1/2 -translate-y-1/2 text-secondary-400" />
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                className="w-full pl-10 pr-4 py-2.5 rounded-xl border border-secondary-200
                  dark:border-secondary-700 dark:bg-secondary-800/50
                  focus:ring-2 focus:ring-primary-500/20 dark:focus:ring-primary-400/20
                  text-secondary-900 dark:text-secondary-100"
                required
              />
            </div>

            <button
              type="submit"
              disabled={isLoading}
              className="w-full py-2.5 px-4 rounded-xl bg-primary-600 hover:bg-primary-700
                dark:bg-primary-500 dark:hover:bg-primary-600
                text-white font-medium transition-colors duration-200
                disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isLoading ? (
                <span className="flex items-center justify-center gap-2">
                  <motion.span
                    animate={{ rotate: 360 }}
                    transition={{
                      duration: 1,
                      repeat: Infinity,
                      ease: 'linear',
                    }}
                    className="w-5 h-5 border-2 border-white/30 border-t-white rounded-full"
                  />
                  {isSignUp ? 'Creating account...' : 'Signing in...'}
                </span>
              ) : (
                <span>{isSignUp ? 'Create account' : 'Sign in'}</span>
              )}
            </button>
          </form>

          {/* Toggle Sign Up/Sign In */}
          <div className="px-6 pb-6 text-center">
            <button
              onClick={() => setIsSignUp(!isSignUp)}
              className="text-sm text-secondary-600 dark:text-secondary-400 hover:text-primary-600
                dark:hover:text-primary-400 transition-colors"
            >
              {isSignUp ? 'Already have an account?' : "Don't have an account?"}
              <span className="font-medium ml-1">
                {isSignUp ? 'Sign in' : 'Sign up'}
              </span>
            </button>
          </div>
        </motion.div>

        {/* Back to Home */}
        <div className="mt-6 text-center">
          <Link
            to="/"
            className="inline-flex items-center gap-2 text-sm text-secondary-600
              dark:text-secondary-400 hover:text-primary-600 dark:hover:text-primary-400
              transition-colors"
          >
            <FiArrowLeft className="text-lg" />
            Back to home
          </Link>
        </div>
      </motion.div>
    </div>
  )
}

Login.propTypes = {
  setUser: PropTypes.func.isRequired,
}

export default Login
