import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import { getJobs, updateJob, deleteJob } from '../utils/db'
import { account } from '../utils/appwrite'
import JobCard from './JobCard'
import {
  FiGrid,
  FiList,
  FiBarChart2,
  FiPlusCircle,
  FiBookOpen,
  FiCompass,
  FiPlus,
} from 'react-icons/fi'
import { twMerge } from 'tailwind-merge'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useTheme } from '../App'
import toast from 'react-hot-toast'
import { useJobs } from '../contexts/JobContext'
import { Link } from 'react-router-dom'
import DashboardSkeleton from './skeletonLoaders/DashboardSkeleton'
import DashboardStats from './DashboardStats'

const VIEW_TYPES = {
  GRID: 'grid',
  LIST: 'list',
  KANBAN: 'kanban',
}

const STATUSES = [
  'not-started',
  'applied',
  'interviewing',
  'got-offer',
  'declined',
]

const Dashboard = ({ user }) => {
  const { jobs, updateJobList } = useJobs()
  const { setDarkMode } = useTheme()
  const [viewType, setViewType] = useState(VIEW_TYPES.GRID)
  const [filter, setFilter] = useState('all')
  const [sortBy, setSortBy] = useState('updatedAt')
  const [sortOrder, setSortOrder] = useState('desc')
  const [stats, setStats] = useState({
    totalApplications: 0,
    interviewRate: 0,
    offerRate: 0,
    activeApplications: 0,
  })
  const [isInitialLoad, setIsInitialLoad] = useState(true)

  useEffect(() => {
    const loadJobsAndPreferences = async () => {
      if (jobs.length === 0) {
        const jobList = await getJobs(user.$id)
        updateJobList(jobList)
      }

      try {
        const prefs = await account.getPrefs()
        setViewType(prefs.viewType || VIEW_TYPES.GRID)
        setFilter(prefs.filter || 'all')
        setSortBy(prefs.sortBy || 'updatedAt')
        setSortOrder(prefs.sortOrder || 'desc')
        setDarkMode(prefs.darkMode || false)
      } catch (error) {
        console.error('Failed to load user preferences:', error)
      }
      setIsInitialLoad(false)
    }
    loadJobsAndPreferences()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // Calculate stats whenever jobs change
    const totalApplications = jobs.length
    const interviewingJobs = jobs.filter(
      (job) => job.status === 'interviewing',
    ).length
    const offerJobs = jobs.filter((job) => job.status === 'got-offer').length
    const activeJobs = jobs.filter(
      (job) =>
        ![
          'declined',
          'accepted',
          'withdrawn',
          'got-offer',
          'not-started',
        ].includes(job.status),
    ).length

    const interviewRate = totalApplications
      ? Math.round((interviewingJobs / totalApplications) * 100)
      : 0
    const offerRate = totalApplications
      ? Math.round((offerJobs / totalApplications) * 100)
      : 0

    setStats({
      totalApplications,
      interviewRate,
      offerRate,
      activeApplications: activeJobs,
    })
  }, [jobs])

  const savePreferences = async (newPrefs) => {
    try {
      const currentPrefs = await account.getPrefs()
      await account.updatePrefs({
        ...currentPrefs,
        ...newPrefs,
      })
    } catch (error) {
      console.error('Failed to save user preferences:', error)
    }
  }

  const handleViewTypeChange = (newViewType) => {
    savePreferences({ viewType: newViewType })
  }

  const handleFilterChange = (newFilter) => {
    console.log('Filter changed to:', newFilter)
    setFilter(newFilter)
    savePreferences({ filter: newFilter })
  }

  const handleSortByChange = (event) => {
    const newSortBy = event.target.value
    console.log('Sort by changed to:', newSortBy)
    setSortBy(newSortBy)
    savePreferences({ sortBy: newSortBy })
  }

  const handleSortOrderChange = () => {
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc'
    setSortOrder(newSortOrder)
    savePreferences({ sortOrder: newSortOrder })
  }

  const filteredJobs = jobs
    .filter((job) => filter === 'all' || job.status === filter)
    .sort((a, b) => {
      const order = sortOrder === 'asc' ? 1 : -1
      if (sortBy === 'updatedAt' || sortBy === 'createdAt') {
        return (new Date(a[sortBy]) - new Date(b[sortBy])) * order
      }
      return a[sortBy].localeCompare(b[sortBy]) * order
    })

  const handleJobUpdate = (updatedJob) => {
    updateJobList(
      jobs.map((job) => (job.$id === updatedJob.$id ? updatedJob : job)),
    )
  }

  const getStatusCounts = () => {
    return STATUSES.reduce((counts, status) => {
      counts[status] = jobs.filter((job) => job.status === status).length
      return counts
    }, {})
  }

  const statusCounts = getStatusCounts()

  const onDragEnd = async (result) => {
    const { destination, source, draggableId } = result

    if (!destination) return

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return
    }

    const updatedJob = jobs.find((job) => job.$id === draggableId)
    updatedJob.status = destination.droppableId

    // Optimistic update
    updateJobList(
      jobs.map((job) => (job.$id === updatedJob.$id ? updatedJob : job)),
    )

    try {
      // Update in the database
      await updateJob(updatedJob.$id, { status: updatedJob.status }, user.$id)
    } catch (error) {
      console.error('Failed to update job status:', error)
      // Revert the optimistic update if the API call fails
      updateJobList(
        jobs.map((job) =>
          job.$id === updatedJob.$id
            ? { ...job, status: source.droppableId }
            : job,
        ),
      )
    }
  }

  const handleJobDelete = async (jobId) => {
    const jobToDelete = jobs.find((job) => job.$id === jobId)

    try {
      // Optimistic update
      updateJobList(jobs.filter((job) => job.$id !== jobId))
      toast.success('Job application deleted')

      await deleteJob(jobId, user.$id)
    } catch (error) {
      // Restore the job if deletion fails
      updateJobList((prevJobs) => [...prevJobs, jobToDelete])
      console.error('Failed to delete job:', error)
      toast.error('Failed to delete job application')
    }
  }

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 640 && viewType === VIEW_TYPES.LIST) {
        handleViewTypeChange(VIEW_TYPES.GRID)
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize() // Check on initial load

    return () => window.removeEventListener('resize', handleResize)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewType])

  const totalApplications = stats.totalApplications

  const formatStatus = (status) => {
    return status
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }

  if (isInitialLoad && jobs.length === 0) {
    return <DashboardSkeleton />
  }

  return (
    <div className="py-4">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        className="space-y-3 sm:space-y-4 px-2 sm:px-0 relative"
      >
        <div className="absolute inset-0 bg-white/40 dark:bg-secondary-900/50 backdrop-blur-sm rounded-xl -z-10" />

        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-4 bg-white/80 dark:bg-secondary-800/70 backdrop-blur-md p-4 rounded-xl border border-slate-200/50 dark:border-secondary-700/20">
          <div className="flex items-center gap-3">
            <h2 className="text-2xl sm:text-2xl md:text-3xl font-bold tracking-tight text-secondary-900 dark:text-secondary-100">
              My Jobs
            </h2>
            <Link
              to="#add-job"
              className="inline-flex items-center px-3.5 py-1.5 border border-emerald-200
              bg-emerald-50 text-emerald-700 hover:bg-emerald-100
              dark:border-emerald-900/30 dark:bg-emerald-900/30
              dark:text-emerald-300 dark:hover:bg-emerald-900/50
              text-sm font-medium rounded-full transition-colors duration-200"
            >
              <FiPlusCircle className="mr-1.5" />
              Add new
            </Link>
          </div>

          <div className="flex items-center gap-2 overflow-x-auto pb-2 sm:pb-0 bg-slate-50/80 dark:bg-secondary-800/70 backdrop-blur-md p-4 rounded-xl border border-slate-200/50 dark:border-secondary-700/20">
            <select
              value={filter}
              onChange={(e) => handleFilterChange(e.target.value)}
              className="input py-1 px-2 sm:px-3 text-sm sm:text-base dark:bg-secondary-800 dark:text-secondary-100"
            >
              <option value="all">All Status</option>
              <option value="not-started">Not Started</option>
              <option value="applied">Applied</option>
              <option value="interviewing">Interviewing</option>
              <option value="got-offer">Got Offer</option>
              <option value="declined">Declined</option>
            </select>

            <div className="flex items-center rounded-lg border border-secondary-200 dark:border-secondary-700 p-1 bg-white dark:bg-secondary-800">
              <button
                onClick={() => {
                  setViewType(VIEW_TYPES.GRID)
                  handleViewTypeChange(VIEW_TYPES.GRID)
                }}
                className={twMerge(
                  'p-1.5 rounded flex items-center gap-1.5',
                  viewType === VIEW_TYPES.GRID
                    ? 'bg-gradient-to-r from-primary-50/50 to-primary-100/50 dark:from-primary-900/30 dark:to-primary-800/30 text-primary-600 dark:text-primary-400'
                    : 'text-secondary-600 dark:text-secondary-400 hover:text-secondary-900 dark:hover:text-secondary-100',
                )}
                title="Grid view"
              >
                <FiGrid size={18} />
              </button>

              <button
                onClick={() => {
                  setViewType(VIEW_TYPES.LIST)
                  handleViewTypeChange(VIEW_TYPES.LIST)
                }}
                className={twMerge(
                  'p-1.5 rounded flex items-center gap-1.5 hidden sm:flex',
                  viewType === VIEW_TYPES.LIST
                    ? 'bg-gradient-to-r from-primary-50/50 to-primary-100/50 dark:from-primary-900/30 dark:to-primary-800/30 text-primary-600 dark:text-primary-400'
                    : 'text-secondary-600 dark:text-secondary-400 hover:text-secondary-900 dark:hover:text-secondary-100',
                )}
                title="List view"
              >
                <FiList size={18} />
              </button>

              <button
                onClick={() => {
                  setViewType(VIEW_TYPES.KANBAN)
                  handleViewTypeChange(VIEW_TYPES.KANBAN)
                }}
                className={twMerge(
                  'p-1.5 rounded flex items-center gap-1.5',
                  viewType === VIEW_TYPES.KANBAN
                    ? 'bg-gradient-to-r from-primary-50/50 to-primary-100/50 dark:from-primary-900/30 dark:to-primary-800/30 text-primary-600 dark:text-primary-400'
                    : 'text-secondary-600 dark:text-secondary-400 hover:text-secondary-900 dark:hover:text-secondary-100',
                )}
                title="Kanban view"
              >
                <FiBarChart2 size={18} />
              </button>
            </div>

            <select
              value={sortBy}
              onChange={handleSortByChange}
              className="input py-1 px-2 sm:px-3 text-sm sm:text-base dark:bg-secondary-800 dark:text-secondary-100"
            >
              <option value="updatedAt">Last Updated</option>
              <option value="createdAt">Date Added</option>
              <option value="company">Company</option>
              <option value="title">Job Title</option>
            </select>

            <button
              onClick={handleSortOrderChange}
              className="btn btn-secondary dark:bg-secondary-700 dark:text-secondary-100 p-1 sm:p-2"
            >
              {sortOrder === 'asc' ? '↑' : '↓'}
            </button>
          </div>
        </div>

        <div className="bg-white/90 dark:bg-secondary-800/70 backdrop-blur-md p-4 rounded-xl border border-slate-200/50 dark:border-secondary-700/20">
          <DashboardStats
            stats={stats}
            statusCounts={statusCounts}
            totalApplications={totalApplications}
          />
        </div>

        <DragDropContext onDragEnd={onDragEnd}>
          <div
            className={twMerge(
              'bg-slate-50/80 dark:bg-secondary-800/70 backdrop-blur-md p-4 rounded-xl border border-slate-200/50 dark:border-secondary-700/20',
              viewType === VIEW_TYPES.KANBAN
                ? 'flex space-x-4 overflow-x-auto pb-4'
                : viewType === VIEW_TYPES.GRID
                ? 'grid gap-6 md:grid-cols-2 lg:grid-cols-3 items-start'
                : 'space-y-4',
            )}
          >
            {viewType === VIEW_TYPES.KANBAN
              ? STATUSES.map((status) => (
                  <Droppable key={status} droppableId={status}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className="flex-shrink-0 w-80"
                      >
                        <h3 className="text-lg font-semibold mb-2 dark:text-secondary-100">
                          {formatStatus(status)}
                        </h3>
                        <div className="space-y-4">
                          {filteredJobs
                            .filter((job) => job.status === status)
                            .map((job, index) => (
                              <Draggable
                                key={job.$id}
                                draggableId={job.$id}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      ...provided.draggableProps.style,
                                      transform: snapshot.isDragging
                                        ? provided.draggableProps.style
                                            .transform
                                        : 'translate(0, 0)',
                                      left: 'auto',
                                      top: 'auto',
                                      // Ensure dragged item appears above other content
                                      zIndex: snapshot.isDragging
                                        ? 9999
                                        : 'auto',
                                    }}
                                  >
                                    <JobCard
                                      job={job}
                                      onUpdate={handleJobUpdate}
                                      onDelete={handleJobDelete}
                                      user={user}
                                      viewType={viewType}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          {provided.placeholder}
                        </div>
                      </div>
                    )}
                  </Droppable>
                ))
              : filteredJobs.map((job) => (
                  <JobCard
                    key={job.$id}
                    job={job}
                    onUpdate={handleJobUpdate}
                    onDelete={handleJobDelete}
                    user={user}
                    viewType={viewType}
                  />
                ))}
            {filteredJobs.length === 0 && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="col-span-full"
              >
                <div className="max-w-3xl mx-auto text-center space-y-8 py-12">
                  <div className="relative">
                    <div className="absolute inset-0 flex items-center justify-center opacity-10">
                      <div className="w-48 h-48 bg-primary-500 rounded-full blur-3xl"></div>
                    </div>
                    <div className="relative space-y-4">
                      <h3 className="text-3xl font-bold text-secondary-900 dark:text-secondary-100">
                        Let&apos;s Land Your Dream Job
                      </h3>
                      <p className="text-lg text-secondary-600 dark:text-secondary-400 max-w-xl mx-auto">
                        Add your first job application. We&apos;ll help you
                        prepare and stay organized throughout your search.
                      </p>
                    </div>
                  </div>

                  <div className="grid gap-6 sm:grid-cols-2 max-w-2xl mx-auto">
                    <Link
                      to="#add-job"
                      className="group relative p-6 bg-white dark:bg-secondary-800 rounded-xl shadow-sm border border-secondary-200 dark:border-secondary-700 hover:border-primary-500 dark:hover:border-primary-500 transition-all duration-300 hover:shadow-md"
                    >
                      <div className="absolute inset-0 bg-gradient-to-br from-primary-500/5 to-transparent rounded-xl opacity-0 group-hover:opacity-100 transition-opacity"></div>
                      <div className="relative">
                        <div className="flex items-center gap-4 mb-4">
                          <div className="p-3 rounded-lg bg-primary-50 dark:bg-primary-900/30 text-primary-600 dark:text-primary-400 group-hover:scale-110 transition-transform">
                            <FiPlus size={24} />
                          </div>
                          <h4 className="text-lg font-semibold text-secondary-900 dark:text-secondary-100">
                            Add Your First Job
                          </h4>
                        </div>
                        <p className="text-secondary-600 dark:text-secondary-400 text-sm">
                          Start by adding a job posting URL. We&apos;ll
                          automatically extract the details and help you track
                          your application.
                        </p>
                      </div>
                    </Link>

                    <Link
                      to="/resources"
                      className="group relative p-6 bg-white dark:bg-secondary-800 rounded-xl shadow-sm border border-secondary-200 dark:border-secondary-700 hover:border-primary-500 dark:hover:border-primary-500 transition-all duration-300 hover:shadow-md"
                    >
                      <div className="absolute inset-0 bg-gradient-to-br from-primary-500/5 to-transparent rounded-xl opacity-0 group-hover:opacity-100 transition-opacity"></div>
                      <div className="relative">
                        <div className="flex items-center gap-4 mb-4">
                          <div className="p-3 rounded-lg bg-primary-50 dark:bg-primary-900/30 text-primary-600 dark:text-primary-400 group-hover:scale-110 transition-transform">
                            <FiCompass size={24} />
                          </div>
                          <h4 className="text-lg font-semibold text-secondary-900 dark:text-secondary-100">
                            Explore Job Boards
                          </h4>
                        </div>
                        <p className="text-secondary-600 dark:text-secondary-400 text-sm">
                          Find the perfect job opportunity from our curated list
                          of job boards and career resources.
                        </p>
                      </div>
                    </Link>
                  </div>

                  <div className="pt-8">
                    <Link
                      to="/resources#career-resources"
                      className="inline-flex items-center gap-2 px-4 py-2 rounded-lg text-primary-600 dark:text-primary-400 hover:bg-primary-50 dark:hover:bg-primary-900/30 transition-colors"
                    >
                      <FiBookOpen className="animate-pulse" />
                      <span>View Career Resources</span>
                    </Link>
                  </div>
                </div>
              </motion.div>
            )}
          </div>
        </DragDropContext>

        <div className="absolute inset-0 -z-20 overflow-hidden">
          <div className="absolute -top-1/2 -left-1/2 w-full h-full bg-gradient-to-r from-primary-500/10 to-purple-500/10 blur-3xl transform rotate-12 dark:from-primary-900/20 dark:to-purple-900/20" />
          <div className="absolute -bottom-1/2 -right-1/2 w-full h-full bg-gradient-to-l from-primary-500/10 to-purple-500/10 blur-3xl transform -rotate-12 dark:from-primary-900/20 dark:to-purple-900/20" />
        </div>
      </motion.div>
    </div>
  )
}

Dashboard.propTypes = {
  user: PropTypes.shape({
    $id: PropTypes.string.isRequired,
    // Add other user properties that are used
  }).isRequired,
}

const ViewTypeButton = ({ icon: Icon, viewType, currentViewType, onClick }) => (
  <button
    onClick={onClick}
    className={twMerge(
      `p-2 rounded transition-all duration-200 gradient-border-active`,
      currentViewType === viewType
        ? 'active bg-primary-50/50 text-primary-600 dark:bg-primary-900/20 dark:text-primary-300'
        : 'text-secondary-400 hover:bg-secondary-50 hover:text-secondary-600 dark:text-secondary-500 dark:hover:bg-secondary-700/50 dark:hover:text-secondary-400',
    )}
  >
    <Icon size={20} />
  </button>
)

ViewTypeButton.propTypes = {
  icon: PropTypes.elementType.isRequired,
  viewType: PropTypes.string.isRequired,
  currentViewType: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default Dashboard
