import PropTypes from 'prop-types'
import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import JobForm from './JobForm'

const JobFormModal = ({ onClose, user }) => {
  const navigate = useNavigate()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleClose = () => {
    navigate(window.location.pathname)
    onClose?.()
  }

  const handleSubmit = () => {
    setIsSubmitting(true)
  }

  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        handleClose()
      }
    }

    document.addEventListener('keydown', handleEscKey)
    return () => document.removeEventListener('keydown', handleEscKey)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key="modal-backdrop"
        className="fixed inset-0 z-50 overflow-hidden"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        onClick={handleClose}
      >
        {/* Backdrop */}
        <div className="absolute inset-0 bg-black/60" />

        {/* Modal Container */}
        <div className="relative flex min-h-screen items-center justify-center p-4">
          <motion.div
            initial={{ scale: 0.95, opacity: 0, y: 20 }}
            animate={{ scale: 1, opacity: 1, y: 0 }}
            exit={{ scale: 0.95, opacity: 0, y: 20 }}
            transition={{ type: 'spring', stiffness: 300, damping: 30 }}
            onClick={(e) => e.stopPropagation()}
            className="relative w-full max-w-3xl rounded-2xl overflow-hidden max-h-[85vh] bg-white dark:bg-secondary-800 flex flex-col"
          >
            {/* Header */}
            <div className="flex items-center justify-between border-b border-secondary-200 dark:border-secondary-700 p-6">
              <div className="space-y-1">
                <h2 className="text-2xl font-bold text-secondary-900 dark:text-secondary-100">
                  Add New Application
                </h2>
              </div>
              <button
                onClick={handleClose}
                className="rounded-lg p-2 text-secondary-500 hover:bg-secondary-100 dark:text-secondary-400 dark:hover:bg-secondary-700"
                aria-label="Close modal"
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            {/* Scrollable Form Content */}
            <div className="overflow-y-auto flex-1">
              <div className="p-6">
                <JobForm
                  user={user}
                  onSuccess={handleClose}
                  onSubmit={handleSubmit}
                  onSubmitComplete={() => setIsSubmitting(false)}
                />
              </div>
            </div>

            {/* Fixed Footer */}
            <div className="border-t border-secondary-200 dark:border-secondary-700 p-6">
              <button
                type="submit"
                form="jobForm"
                disabled={isSubmitting}
                className={`w-full px-6 py-3 rounded-xl font-medium flex items-center justify-center
                  ${
                    isSubmitting
                      ? 'bg-primary-400 cursor-not-allowed'
                      : 'bg-primary-600 hover:bg-primary-700 dark:bg-primary-500 dark:hover:bg-primary-600'
                  } text-white shadow-sm hover:shadow`}
              >
                {isSubmitting ? (
                  <>
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                        fill="none"
                      />
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                    Adding Job...
                  </>
                ) : (
                  'Add Job'
                )}
              </button>
            </div>
          </motion.div>
        </div>
      </motion.div>
    </AnimatePresence>
  )
}

JobFormModal.propTypes = {
  user: PropTypes.shape({
    $id: PropTypes.string.isRequired,
    plan: PropTypes.oneOf(['free', 'pro']).isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
}

export default JobFormModal
