const Terms = () => {
  return (
    <div className="py-12 px-4">
      <div className="max-w-3xl mx-auto">
        <div className="bg-white dark:bg-secondary-800 rounded-2xl p-8 shadow-sm">
          <h1 className="text-3xl sm:text-4xl font-bold text-secondary-900 dark:text-secondary-100 mb-8">
            Terms of Service
          </h1>
          <div className="text-secondary-600 dark:text-secondary-300 space-y-8">
            <div className="flex items-center gap-2 text-sm">
              <span>Last updated:</span>
              <span className="px-2 py-1 bg-secondary-100 dark:bg-secondary-700 rounded">
                {new Date().toLocaleDateString()}
              </span>
            </div>

            <section className="space-y-4">
              <h2 className="text-2xl font-semibold text-secondary-900 dark:text-secondary-100">
                1. Agreement to Terms
              </h2>
              <p className="leading-relaxed">
                By accessing and using nextjob.work (&quot;the Service&quot;),
                you agree to be bound by these Terms of Service and our Privacy
                Policy.
              </p>
            </section>

            <section className="space-y-4">
              <h2 className="text-2xl font-semibold text-secondary-900 dark:text-secondary-100">
                2. Description of Service
              </h2>
              <p className="leading-relaxed">
                NextJob is a job application tracking platform that helps users
                manage their job search process. Features include:
              </p>
              <ul className="space-y-2 list-none pl-4">
                <li className="flex items-center gap-2 before:content-['•'] before:text-primary-600 dark:before:text-primary-400">
                  Job application tracking and organization
                </li>
                <li className="flex items-center gap-2 before:content-['•'] before:text-primary-600 dark:before:text-primary-400">
                  AI-powered job details extraction
                </li>
                <li className="flex items-center gap-2 before:content-['•'] before:text-primary-600 dark:before:text-primary-400">
                  Application status management
                </li>
                <li className="flex items-center gap-2 before:content-['•'] before:text-primary-600 dark:before:text-primary-400">
                  Notes and tasks management
                </li>
                <li className="flex items-center gap-2 before:content-['•'] before:text-primary-600 dark:before:text-primary-400">
                  Dark Theme support
                </li>
              </ul>
            </section>

            <section className="space-y-4">
              <h2 className="text-2xl font-semibold text-secondary-900 dark:text-secondary-100">
                3. User Accounts
              </h2>
              <p className="leading-relaxed">To use NextJob, you must:</p>
              <ul className="space-y-2 list-none pl-4">
                <li className="flex items-center gap-2 before:content-['•'] before:text-primary-600 dark:before:text-primary-400">
                  Create an account with valid information
                </li>
                <li className="flex items-center gap-2 before:content-['•'] before:text-primary-600 dark:before:text-primary-400">
                  Maintain the security of your account
                </li>
                <li className="flex items-center gap-2 before:content-['•'] before:text-primary-600 dark:before:text-primary-400">
                  Notify us of any unauthorized access
                </li>
                <li className="flex items-center gap-2 before:content-['•'] before:text-primary-600 dark:before:text-primary-400">
                  Be at least 16 years old
                </li>
              </ul>
            </section>

            <section className="space-y-4">
              <h2 className="text-2xl font-semibold text-secondary-900 dark:text-secondary-100">
                4. Acceptable Use
              </h2>
              <p className="leading-relaxed">You agree not to:</p>
              <ul className="space-y-2 list-none pl-4">
                <li className="flex items-center gap-2 before:content-['•'] before:text-primary-600 dark:before:text-primary-400">
                  Use the Service for any illegal purpose
                </li>
                <li className="flex items-center gap-2 before:content-['•'] before:text-primary-600 dark:before:text-primary-400">
                  Share account credentials
                </li>
                <li className="flex items-center gap-2 before:content-['•'] before:text-primary-600 dark:before:text-primary-400">
                  Attempt to access other users&apos; accounts
                </li>
                <li className="flex items-center gap-2 before:content-['•'] before:text-primary-600 dark:before:text-primary-400">
                  Interfere with the Service&apos;s operation
                </li>
                <li className="flex items-center gap-2 before:content-['•'] before:text-primary-600 dark:before:text-primary-400">
                  Automate access to the Service without permission
                </li>
              </ul>
            </section>

            <section className="space-y-4">
              <h2 className="text-2xl font-semibold text-secondary-900 dark:text-secondary-100">
                5. Data Usage
              </h2>
              <p className="leading-relaxed">
                We use AI to process job listings and provide suggestions. While
                we strive for accuracy, we don&apos;t guarantee the completeness
                or accuracy of AI-generated content.
              </p>
            </section>

            <section className="space-y-4">
              <h2 className="text-2xl font-semibold text-secondary-900 dark:text-secondary-100">
                6. Service Availability
              </h2>
              <p className="leading-relaxed">
                We strive for 24/7 availability but don&apos;t guarantee
                uninterrupted access. We reserve the right to modify or
                discontinue features with notice.
              </p>
            </section>

            <section className="space-y-4">
              <h2 className="text-2xl font-semibold text-secondary-900 dark:text-secondary-100">
                7. Termination
              </h2>
              <p className="leading-relaxed">
                We may suspend or terminate accounts that violate these terms.
                You may delete your account at any time.
              </p>
            </section>

            <section className="space-y-4">
              <h2 className="text-2xl font-semibold text-secondary-900 dark:text-secondary-100">
                8. Changes to Terms
              </h2>
              <p className="leading-relaxed">
                We may update these terms with notice. Continued use of the
                Service constitutes acceptance of new terms.
              </p>
            </section>

            <section className="space-y-4">
              <h2 className="text-2xl font-semibold text-secondary-900 dark:text-secondary-100">
                Contact
              </h2>
              <p className="leading-relaxed">
                Questions about these terms? Contact us at{' '}
                <a
                  href="mailto:support@nextjob.work"
                  className="text-primary-600 dark:text-primary-400 hover:underline"
                >
                  support@nextjob.work
                </a>
              </p>
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Terms
