import { useState } from 'react'
import PropTypes from 'prop-types'
import ResumeUpload from './ResumeUpload'
import { FiUpload, FiMoon, FiSun } from 'react-icons/fi'
import { account } from '../utils/appwrite'
import toast from 'react-hot-toast'

const Settings = ({ user, darkMode, setDarkMode }) => {
  const [isSaving, setIsSaving] = useState(false)

  const handleThemeToggle = async () => {
    try {
      setIsSaving(true)
      const newDarkMode = !darkMode

      // Update user preferences
      const prefs = await account.getPrefs()
      await account.updatePrefs({
        ...prefs,
        darkMode: newDarkMode,
      })

      setDarkMode(newDarkMode)
      toast.success('Theme updated successfully')
    } catch (error) {
      console.error('Error updating theme:', error)
      toast.error('Failed to update theme')
    } finally {
      setIsSaving(false)
    }
  }

  return (
    <div className="max-w-4xl mx-auto p-6">
      <h1 className="text-2xl font-bold text-secondary-900 dark:text-secondary-100 mb-8">
        Settings
      </h1>

      <div className="space-y-8">
        {/* Resume Section */}
        <section className="bg-white dark:bg-secondary-800 rounded-lg p-6 shadow-sm">
          <h2 className="text-xl font-semibold text-secondary-900 dark:text-secondary-100 mb-4 flex items-center gap-2">
            <FiUpload className="text-primary-500" />
            Resume
          </h2>
          <p className="text-secondary-600 dark:text-secondary-400 mb-4">
            Upload your resume to enable AI-powered cover letter generation and
            job matching.
          </p>
          <ResumeUpload user={user} />
        </section>

        {/* Theme Section */}
        <section className="bg-white dark:bg-secondary-800 rounded-lg p-6 shadow-sm">
          <h2 className="text-xl font-semibold text-secondary-900 dark:text-secondary-100 mb-4 flex items-center gap-2">
            {darkMode ? (
              <FiMoon className="text-primary-500" />
            ) : (
              <FiSun className="text-primary-500" />
            )}
            Theme
          </h2>
          <button
            onClick={handleThemeToggle}
            disabled={isSaving}
            className="flex items-center gap-2 px-4 py-2 rounded-lg bg-secondary-100 dark:bg-secondary-700 text-secondary-900 dark:text-secondary-100 hover:bg-secondary-200 dark:hover:bg-secondary-600 transition-colors"
          >
            {darkMode ? 'Switch to Light Theme' : 'Switch to Dark Theme'}
          </button>
        </section>
      </div>
    </div>
  )
}

Settings.propTypes = {
  user: PropTypes.shape({
    $id: PropTypes.string.isRequired,
    plan: PropTypes.string.isRequired,
  }).isRequired,
  darkMode: PropTypes.bool.isRequired,
  setDarkMode: PropTypes.func.isRequired,
}

export default Settings
