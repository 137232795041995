import { motion, AnimatePresence } from 'framer-motion'
import PropTypes from 'prop-types'
import { FiUpload } from 'react-icons/fi'
import { createPortal } from 'react-dom'
import ResumeUpload from './ResumeUpload'
import { useEffect } from 'react'

export const ResumePromptModal = ({ isOpen, onClose, user }) => {
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape') {
        onClose()
      }
    }

    if (isOpen) {
      document.addEventListener('keydown', handleEscape)
    }

    return () => {
      document.removeEventListener('keydown', handleEscape)
    }
  }, [isOpen, onClose])

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    }
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [isOpen])

  if (!isOpen) return null

  return createPortal(
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 z-50 bg-black/60"
        onClick={onClose}
      >
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <motion.div
              initial={{ scale: 0.95, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.95, opacity: 0 }}
              className="relative w-full max-w-md bg-white dark:bg-secondary-800 rounded-2xl shadow-2xl p-6"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="text-center">
                <FiUpload className="mx-auto h-12 w-12 text-primary-500 mb-4" />
                <h3 className="text-lg font-semibold text-secondary-900 dark:text-secondary-100 mb-2">
                  Resume Required
                </h3>
                <p className="text-secondary-600 dark:text-secondary-400 mb-6">
                  Please upload your resume to generate a customized cover
                  letter.
                </p>

                <div className="mb-6">
                  <ResumeUpload user={user} />
                </div>

                <button
                  onClick={onClose}
                  className="px-4 py-2 text-sm font-medium text-secondary-600 dark:text-secondary-400 hover:bg-secondary-100 dark:hover:bg-secondary-700 rounded-lg transition-colors"
                >
                  Close
                </button>
              </div>
            </motion.div>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>,
    document.body,
  )
}

ResumePromptModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
}

export default ResumePromptModal
