import { Link, useLocation } from 'react-router-dom'
import { account } from '../utils/appwrite'
import {
  FiMoon,
  FiSun,
  FiPlusCircle,
  FiUser,
  FiCompass,
  FiChevronDown,
  FiBookOpen,
} from 'react-icons/fi'
import { useTheme } from '../App'
import NextJobLogo from './icons/NextJobLogo'
import { twMerge } from 'tailwind-merge'
import PropTypes from 'prop-types'
import { useState, useRef, useEffect } from 'react'

const NavLink = ({ to, icon: Icon, label, className = '' }) => {
  const location = useLocation()
  const isActive = location.pathname === to

  return (
    <Link
      to={to}
      className={twMerge(`
        flex items-center justify-center sm:justify-start px-4 py-2 rounded-md transition-all duration-200
        ${
          isActive
            ? 'text-primary-600 dark:text-primary-400 border-b-2 border-primary-600 dark:border-primary-400'
            : 'text-secondary-600 hover:text-primary-600 dark:text-secondary-400 dark:hover:text-primary-400 hover:border-b-2 hover:border-primary-600/50 dark:hover:border-primary-400/50'
        }
        ${className}
      `)}
    >
      <Icon className="text-2xl sm:text-xl md:text-base sm:mr-2" />
      <span className="hidden md:inline">{label}</span>
    </Link>
  )
}

NavLink.propTypes = {
  to: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
}

const UserMenu = ({ user, setUser }) => {
  const [isOpen, setIsOpen] = useState(false)
  const menuRef = useRef()
  const { darkMode, setDarkMode } = useTheme()

  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape' && isOpen) {
        setIsOpen(false)
      }
    }

    const handleClickOutside = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        setIsOpen(false)
      }
    }

    if (isOpen) {
      document.addEventListener('keydown', handleEscape)
      document.addEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('keydown', handleEscape)
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen])

  const handleLogout = async () => {
    try {
      await account.deleteSession('current')
      setUser(null)
    } catch (error) {
      console.error('Logout failed', error)
    }
  }

  const handleThemeToggle = async () => {
    const newDarkMode = !darkMode
    setDarkMode(newDarkMode)
    try {
      const currentPrefs = await account.getPrefs()
      await account.updatePrefs({ ...currentPrefs, darkMode: newDarkMode })
    } catch (error) {
      console.error('Failed to save theme preference:', error)
    }
  }

  // Helper function to close dropdown
  const closeDropdown = () => setIsOpen(false)

  return (
    <div className="relative z-50" ref={menuRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center gap-2 text-secondary-600 hover:text-secondary-900 dark:text-secondary-400 dark:hover:text-secondary-100"
      >
        <FiUser className="text-xl" />
        <span className="hidden md:inline text-sm font-medium">
          {user.name || 'Account'}
        </span>
        <FiChevronDown
          className={`transition-transform ${isOpen ? 'rotate-180' : ''}`}
        />
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 py-2 bg-white dark:bg-secondary-800 rounded-lg shadow-xl border border-secondary-100 dark:border-secondary-700">
          <Link
            to="/profile"
            onClick={closeDropdown}
            className="block px-4 py-2 border-b border-secondary-100 dark:border-secondary-700 hover:bg-secondary-50 dark:text-secondary-300 dark:hover:bg-secondary-700"
          >
            View Profile
          </Link>
          <Link
            to="/upgrade"
            onClick={closeDropdown}
            className="block px-4 py-2 text-sm text-secondary-700 hover:bg-secondary-50 dark:text-secondary-300 dark:hover:bg-secondary-700"
          >
            Upgrade to Pro
          </Link>

          <button
            onClick={handleThemeToggle}
            className="w-full text-left px-4 py-2 text-sm text-secondary-700 hover:bg-secondary-50 dark:text-secondary-300 dark:hover:bg-secondary-700 flex items-center gap-2"
          >
            {darkMode ? (
              <>
                <FiSun className="text-base" />
                <span>Light Theme</span>
              </>
            ) : (
              <>
                <FiMoon className="text-base" />
                <span>Dark Theme</span>
              </>
            )}
          </button>
          <button
            onClick={handleLogout}
            className="w-full text-left px-4 py-2 text-sm text-secondary-700 hover:bg-secondary-50 dark:text-secondary-300 dark:hover:bg-secondary-700"
          >
            Logout
          </button>
        </div>
      )}
    </div>
  )
}

UserMenu.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
    name: PropTypes.string,
  }).isRequired,
  setUser: PropTypes.func.isRequired,
}

const Header = ({ user, setUser }) => {
  const [isVisible, setIsVisible] = useState(true)
  const [prevScrollPos, setPrevScrollPos] = useState(0)

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY
      const isScrollingUp = prevScrollPos > currentScrollPos

      // Only update visibility if scrolled more than 10px to prevent tiny movements
      if (Math.abs(prevScrollPos - currentScrollPos) > 10) {
        setIsVisible(isScrollingUp || currentScrollPos < 10)
      }

      setPrevScrollPos(currentScrollPos)
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [prevScrollPos])

  return (
    <header
      className={`fixed top-0 left-0 right-0 bg-white/80 dark:bg-secondary-800/80 backdrop-blur-md shadow-sm z-50 transition-transform duration-300 ${
        isVisible ? 'translate-y-0' : '-translate-y-full'
      }`}
    >
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          <Link to="/" className="flex items-center gap-2">
            <NextJobLogo className="w-7 h-7 sm:w-8 sm:h-8" />
            <h1 className="text-lg sm:text-xl lg:text-2xl font-bold text-primary-600 dark:text-primary-400">
              NextJob
            </h1>
          </Link>
          <nav className="flex items-center gap-3 sm:gap-2">
            {user ? (
              <>
                <NavLink
                  to="#add-job"
                  icon={FiPlusCircle}
                  label="Add Job"
                  className="p-2 sm:px-4 sm:py-2"
                />
                <NavLink
                  to="/resources"
                  icon={FiCompass}
                  label="Job Resources"
                  className="p-2 sm:px-4 sm:py-2"
                />
                <NavLink
                  to="/blog"
                  icon={FiBookOpen}
                  label="Blog"
                  className="hidden sm:flex"
                />
                <UserMenu user={user} setUser={setUser} />
              </>
            ) : (
              <>
                <Link
                  to="/login"
                  className="px-4 py-2 rounded-md text-secondary-600 hover:bg-secondary-100 dark:text-secondary-400 dark:hover:bg-secondary-800 transition-colors duration-200"
                >
                  Login / Sign Up
                </Link>
                <ThemeToggle />
              </>
            )}
          </nav>
        </div>
      </div>
    </header>
  )
}

const ThemeToggle = () => {
  const { darkMode, setDarkMode } = useTheme()

  const handleThemeToggle = async () => {
    const newDarkMode = !darkMode
    setDarkMode(newDarkMode)
  }

  return (
    <button
      onClick={handleThemeToggle}
      className="p-2 sm:px-4 sm:py-2 rounded-md text-secondary-600 hover:bg-secondary-100 dark:text-secondary-400 dark:hover:bg-secondary-800 transition-colors duration-200"
      aria-label={darkMode ? 'Switch to Light Theme' : 'Switch to Dark Theme'}
    >
      {darkMode ? (
        <FiSun className="text-[18px] xs:text-[22px] md:text-base" />
      ) : (
        <FiMoon className="text-[18px] xs:text-[22px] md:text-base" />
      )}
    </button>
  )
}

Header.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
    name: PropTypes.string,
  }),
  setUser: PropTypes.func.isRequired,
}

export default Header
