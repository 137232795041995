// Function to estimate reading time based on content length
export const estimateReadingTime = (content) => {
  if (!content) return 0

  // Average reading speed (words per minute)
  const wordsPerMinute = 200

  // Convert content to plain text if it's not already
  const text = typeof content === 'string' ? content : ''

  // Count words (split by whitespace)
  const wordCount = text.trim().split(/\s+/).length

  // Calculate reading time in minutes
  const readingTime = Math.ceil(wordCount / wordsPerMinute)

  // Return at least 1 minute
  return Math.max(1, readingTime)
}

// Function to get blog content as text for reading time calculation
export const getBlogContent = (post) => {
  if (!post) return ''

  // Combine title, description and content
  return `
    ${post.frontmatter?.title || ''}
    ${post.frontmatter?.description || ''}
    ${post.content || ''}
  `
}

// Function to format blog post data
export const formatBlogPost = (post) => {
  if (!post) return null

  return {
    ...post,
    content: getBlogContent(post),
    readingTime: estimateReadingTime(getBlogContent(post)),
  }
}

// Function to sort blog posts by date
export const sortBlogPosts = (posts) => {
  return [...posts].sort((a, b) => {
    const dateA = new Date(a.frontmatter?.date || 0)
    const dateB = new Date(b.frontmatter?.date || 0)
    return dateB - dateA
  })
}

// Meta tag generation
export const generateMetaTags = (frontmatter) => `
  <meta name="description" content="${frontmatter.description}">
  <meta property="og:title" content="${frontmatter.title}">
  <meta property="og:description" content="${frontmatter.description}">
  ${
    frontmatter.image
      ? `<meta property="og:image" content="${frontmatter.image}">`
      : ''
  }
  <meta name="twitter:card" content="summary_large_image">
  <meta name="twitter:title" content="${frontmatter.title}">
  <meta name="twitter:description" content="${frontmatter.description}">
  ${
    frontmatter.image
      ? `<meta name="twitter:image" content="${frontmatter.image}">`
      : ''
  }
`
