import { motion, AnimatePresence } from 'framer-motion'
import PropTypes from 'prop-types'
import { FiX, FiStar, FiCheck } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { handleUpgradeClick } from '../utils/payments'
import toast from 'react-hot-toast'
import { createPortal } from 'react-dom'

export const UpgradeModal = ({
  isOpen = false,
  onClose = () => {},
  position = { x: 0, y: 0 },
}) => {
  const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 })
  const [isProcessing, setIsProcessing] = useState(false)

  useEffect(() => {
    if (isOpen && position) {
      const viewportHeight = window.innerHeight
      const modalHeight = 420 // Approximate modal height
      const modalWidth = 384 // max-w-md = 384px
      const isMobile = window.innerWidth < 640 // SM breakpoint

      if (isMobile) {
        // Center modal on mobile
        setModalPosition({
          x: Math.max(16, (window.innerWidth - modalWidth) / 2),
          y: Math.max(16, (viewportHeight - modalHeight) / 2),
        })
      } else {
        // Original positioning logic for desktop
        const yPosition =
          position.y + modalHeight > viewportHeight
            ? Math.max(150, position.y - modalHeight - 150)
            : Math.min(position.y + 150, viewportHeight - modalHeight - 150)

        const xPosition = Math.min(
          position.x,
          window.innerWidth - modalWidth - 16, // 16px padding
        )

        setModalPosition({
          x: Math.max(16, xPosition), // Ensure at least 16px from left edge
          y: yPosition,
        })
      }
    }
  }, [isOpen, position])

  // Add scroll lock effect
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    }
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [isOpen])

  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape') {
        onClose()
      }
    }

    if (isOpen) {
      document.addEventListener('keydown', handleEscape)
    }

    return () => {
      document.removeEventListener('keydown', handleEscape)
    }
  }, [isOpen, onClose])

  const handleUpgrade = async () => {
    setIsProcessing(true)
    try {
      const success = await handleUpgradeClick()
      if (success) {
        onClose() // Close the modal when checkout starts
      } else {
        toast.error('Failed to start checkout process. Please try again.')
      }
    } catch (error) {
      console.error('Upgrade error:', error)
      toast.error('Failed to start checkout process. Please try again.')
    } finally {
      setIsProcessing(false)
    }
  }

  if (!isOpen) return null

  // Render modal in a portal
  return createPortal(
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 z-50 overflow-y-auto bg-black/60"
        onClick={onClose}
      >
        <motion.div
          initial={{ scale: 0.95, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.95, opacity: 0 }}
          style={{
            position: 'fixed',
            left: `${modalPosition.x}px`,
            top: `${modalPosition.y}px`,
            width: '384px', // max-w-md
            maxWidth: 'calc(100vw - 2rem)',
          }}
          className="relative bg-white dark:bg-secondary-800 rounded-2xl shadow-2xl overflow-hidden"
          onClick={(e) => e.stopPropagation()}
        >
          {/* Keep exact same content */}
          {/* Decorative background */}
          <div className="absolute inset-0 bg-gradient-to-br from-primary-500/10 via-transparent to-purple-500/10 dark:from-primary-500/20 dark:to-purple-500/20" />

          {/* Content */}
          <div className="relative p-6 sm:p-8">
            <div className="flex justify-between items-start mb-6">
              <div className="space-y-2">
                <span className="inline-flex items-center gap-1.5 px-3 py-1 rounded-full text-xs font-medium bg-primary-100 text-primary-700 dark:bg-primary-900/50 dark:text-primary-300">
                  <FiStar className="text-yellow-500" />
                  Pro Feature
                </span>
                <h2 className="text-2xl font-semibold text-secondary-900 dark:text-secondary-100">
                  Upgrade to Pro
                </h2>
              </div>
              <button
                onClick={onClose}
                className="p-2 text-secondary-400 hover:text-secondary-600 dark:hover:text-secondary-300 rounded-full hover:bg-secondary-100 dark:hover:bg-secondary-700/50 transition-colors"
              >
                <FiX size={20} />
              </button>
            </div>

            <div className="space-y-4">
              <p className="text-secondary-600 dark:text-secondary-400">
                Unlock unlimited cover letters and other Pro features for just
                $9.99/month:
              </p>

              <ul className="space-y-3">
                {[
                  'Personalized cover letters',
                  'Custom interview prep tasks',
                  'Unlimited job tracking',
                  'AI job details extraction',
                ].map((feature) => (
                  <li
                    key={feature}
                    className="flex items-center gap-3 text-secondary-600 dark:text-secondary-400"
                  >
                    <span className="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full bg-primary-100 dark:bg-primary-900/50">
                      <FiCheck className="w-4 h-4 text-primary-600 dark:text-primary-400" />
                    </span>
                    {feature}
                  </li>
                ))}
              </ul>

              <div className="flex flex-col gap-2">
                <button
                  onClick={handleUpgrade}
                  disabled={isProcessing}
                  className="w-full inline-flex justify-center items-center px-4 py-2.5 rounded-xl
                    bg-gradient-to-r from-primary-500 to-primary-600 hover:from-primary-600 hover:to-primary-700
                    text-white font-medium shadow-sm hover:shadow-md
                    transition-all duration-200 disabled:opacity-70"
                >
                  {isProcessing ? (
                    <>
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      Processing...
                    </>
                  ) : (
                    <>Upgrade Now - $9.99/mo</>
                  )}
                </button>

                <Link
                  to="/upgrade"
                  className="text-sm text-center text-secondary-500 hover:text-secondary-600 dark:text-secondary-400 dark:hover:text-secondary-300"
                >
                  View all Pro features
                </Link>
              </div>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>,
    document.body,
  )
}

UpgradeModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  position: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
}

export default UpgradeModal
