import { useState, useEffect, useContext } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { format } from 'date-fns'
import {
  FiChevronDown,
  FiPlus,
  FiCheck,
  FiCalendar,
  FiDollarSign,
  FiMapPin,
  FiX,
  FiExternalLink,
  FiLoader,
  FiFileText,
} from 'react-icons/fi'
import {
  getNotes,
  getTasks,
  addNote,
  addTask,
  updateTask,
  updateJob,
  deleteNote,
  deleteTask,
} from '../utils/db'
import toast from 'react-hot-toast'
import PropTypes from 'prop-types'
import { CoverLetterModal } from './CoverLetterModal.jsx'
import { UpgradeModal } from './UpgradeModal.jsx'
import { UserContext } from '../App'
import DeleteConfirmDialog from './DeleteConfirmDialog'
import ResumePromptModal from './ResumePromptModal'
import { sendEmail } from '../utils/emailUtils'

const JobCard = ({ job, onUpdate, onDelete, user, viewType = 'grid' }) => {
  const [notes, setNotes] = useState([])
  const [tasks, setTasks] = useState([])
  const [newNote, setNewNote] = useState('')
  const [newTask, setNewTask] = useState('')
  const [newTaskDueDate, setNewTaskDueDate] = useState('')
  const [isExpanded, setIsExpanded] = useState(false)
  const [firstUncompletedTask, setFirstUncompletedTask] = useState(null)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [suggestedTask, setSuggestedTask] = useState(null)
  const [deletePosition, setDeletePosition] = useState({ x: 0, y: 0 })
  const [showDeleteNoteDialog, setShowDeleteNoteDialog] = useState(false)
  const [showDeleteTaskDialog, setShowDeleteTaskDialog] = useState(false)
  const [itemToDelete, setItemToDelete] = useState(null)
  const [deleteDialogPosition, setDeleteDialogPosition] = useState({
    x: 0,
    y: 0,
  })
  const [isGeneratingCoverLetter, setIsGeneratingCoverLetter] = useState(false)
  const [showCoverLetterModal, setShowCoverLetterModal] = useState(false)
  const [showUpgradeModal, setShowUpgradeModal] = useState(false)
  const [upgradeModalPosition, setUpgradeModalPosition] = useState({
    x: 0,
    y: 0,
  })
  const { hasResume, userProfile, setUserProfile } = useContext(UserContext)
  const [showResumePromptModal, setShowResumePromptModal] = useState(false)

  useEffect(() => {
    const loadNotesAndTasks = async () => {
      const jobNotes = await getNotes(job.$id, user.$id)
      const jobTasks = await getTasks(job.$id, user.$id)
      setNotes(jobNotes)
      setTasks(jobTasks)

      // Instead of creating tasks, just set the suggestion
      if (job.status === 'declined') {
        setSuggestedTask('Ask for feedback')
      } else if (job.status === 'got-offer') {
        setSuggestedTask('Prepare counter offer')
      } else {
        setSuggestedTask(null)
        // Find first uncompleted task for normal states
        const uncompleted = jobTasks.find((task) => !task.completed)
        setFirstUncompletedTask(uncompleted || null)
      }
    }
    loadNotesAndTasks()
  }, [job.$id, user.$id, job.status])

  const handleAddNote = async (e) => {
    e.preventDefault()
    try {
      const note = await addNote({ jobId: job.$id, content: newNote }, user.$id)
      setNotes([...notes, note])
      setNewNote('')
      toast.success('Note added successfully')
      // eslint-disable-next-line no-unused-vars
    } catch (error) {
      toast.error('Failed to add note')
    }
  }

  const handleAddTask = async (e) => {
    e.preventDefault()
    try {
      const task = await addTask(
        {
          jobId: job.$id,
          description: newTask,
          dueDate: newTaskDueDate || null,
        },
        user.$id,
      )
      setTasks([...tasks, task])
      setNewTask('')
      setNewTaskDueDate('')
      toast.success('Task added successfully')
      // eslint-disable-next-line no-unused-vars
    } catch (error) {
      toast.error('Failed to add task')
    }
  }

  const handleToggleTask = async (taskId) => {
    try {
      const task = tasks.find((t) => t.$id === taskId)
      const newCompletedState = !task.completed

      // Optimistic update
      setTasks(
        tasks.map((t) =>
          t.$id === taskId ? { ...t, completed: newCompletedState } : t,
        ),
      )

      // Update firstUncompletedTask optimistically
      if (newCompletedState && task.$id === firstUncompletedTask?.$id) {
        const nextUncompleted = tasks.find(
          (t) => !t.completed && t.$id !== taskId,
        )
        setFirstUncompletedTask(nextUncompleted || null)
      } else if (!newCompletedState && !firstUncompletedTask) {
        setFirstUncompletedTask({ ...task, completed: newCompletedState })
      }

      // Server update
      const updatedTask = await updateTask(
        taskId,
        { completed: newCompletedState },
        user.$id,
      )

      // Only update if server response is different (rare case)
      if (updatedTask.completed !== newCompletedState) {
        setTasks(tasks.map((t) => (t.$id === taskId ? updatedTask : t)))
        toast.error('Failed to update task status')
      } else {
        toast.success(
          newCompletedState ? 'Task completed!' : 'Task uncompleted',
        )
      }
      // eslint-disable-next-line no-unused-vars
    } catch (error) {
      // Revert optimistic update on error
      setTasks(
        tasks.map((t) =>
          t.$id === taskId ? { ...t, completed: !t.completed } : t,
        ),
      )
      toast.error('Failed to update task')
    }
  }

  const getStatusColor = (status) => {
    const colors = {
      'not-started':
        'bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-200',
      applied:
        'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-200',
      interviewing:
        'bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200',
      'got-offer':
        'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200',
      declined: 'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-200',
    }
    return colors[status] || colors['not-started']
  }

  const handleStatusChange = async (e) => {
    const newStatus = e.target.value

    try {
      const updatedJob = await updateJob(
        job.$id,
        { status: newStatus },
        user.$id,
      )
      onUpdate(updatedJob)

      // Do actions based on new status
      if (newStatus === 'got-offer') {
        setSuggestedTask('Prepare counter offer')
      } else if (newStatus === 'declined') {
        setSuggestedTask('Ask for feedback')
      } else if (newStatus === 'interviewing') {
        try {
          await sendEmail({
            to: user.email,
            templateName: 'interviewStatus',
            templateData: {
              title: job.title,
              company: job.company,
              origin: window.location.origin,
            },
            userId: user.$id,
          })
        } catch (error) {
          console.error('Failed to send interview status email:', error)
          // Optionally show a toast to the user
          toast.error('Failed to send email notification')
        }
      } else {
        setSuggestedTask(null)
        const uncompleted = tasks.find((task) => !task.completed)
        setFirstUncompletedTask(uncompleted || null)
      }

      toast.success('Status updated successfully')
    } catch (error) {
      console.error('Failed to update status:', error)
      toast.error('Failed to update status')
    }
  }

  const calculateModalPosition = (rect, modalHeight = 200) => {
    const viewportHeight = window.innerHeight
    const viewportWidth = window.innerWidth
    const modalWidth = 320
    const buffer = 16 // Minimum space from edges
    const isMobile = window.innerWidth < 640 // SM breakpoint

    if (isMobile) {
      // Center modal on mobile
      return {
        x: Math.max(buffer, (viewportWidth - modalWidth) / 2),
        y: Math.max(buffer, (viewportHeight - modalHeight) / 2),
      }
    }

    let yPosition
    // If showing below would go off screen, show above
    if (rect.bottom + modalHeight + buffer > viewportHeight) {
      yPosition = Math.max(buffer, rect.top - modalHeight - buffer)
    } else {
      yPosition = rect.bottom + buffer
    }

    // Horizontal positioning
    let xPosition
    if (rect.right + modalWidth + buffer > viewportWidth) {
      // Position to the left of the trigger if not enough space on the right
      xPosition = Math.max(buffer, rect.left - modalWidth)

      // If positioning to the left would push it off-screen,
      // center it or find best possible position
      if (xPosition < buffer) {
        xPosition = Math.max(
          buffer,
          Math.min(rect.left, viewportWidth - modalWidth - buffer),
        )
      }
    } else {
      // Default positioning to the right
      xPosition = rect.left
    }

    return { x: xPosition, y: yPosition }
  }

  const handleDeleteClick = (e) => {
    const rect = e.currentTarget.getBoundingClientRect()
    setDeletePosition(calculateModalPosition(rect))
    setShowDeleteDialog(true)
  }

  const handleDeleteNoteClick = (e, note) => {
    const rect = e.currentTarget.getBoundingClientRect()
    setDeleteDialogPosition(calculateModalPosition(rect))
    setItemToDelete(note)
    setShowDeleteNoteDialog(true)
  }

  const handleDeleteTaskClick = (e, task) => {
    const rect = e.currentTarget.getBoundingClientRect()
    setDeleteDialogPosition(calculateModalPosition(rect))
    setItemToDelete(task)
    setShowDeleteTaskDialog(true)
  }

  useEffect(() => {
    if (hasResume) {
      setShowResumePromptModal(false)
    }
  }, [hasResume])

  const handleGenerateCoverLetter = async (style = 'default') => {
    if (!hasResume) {
      setShowResumePromptModal(true)
      return
    }

    try {
      setIsGeneratingCoverLetter(true)

      const apiUrl = import.meta.env.VITE_API_URL

      const response = await fetch(`${apiUrl}/generate-cover-letter`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          jobId: job.$id,
          userId: user.$id,
          style,
        }),
      })

      const data = await response.json()
      if (data.error) throw new Error(data.error)

      // Update job with new cover letter
      await onUpdate({
        ...job,
        coverLetter: data.coverLetter,
      })

      // Update userProfile in context with new cover letter count
      setUserProfile((prev) => ({
        ...prev,
        coverLetterCount: (prev?.coverLetterCount || 0) + 1,
        updatedAt: new Date().toISOString(),
      }))

      setShowCoverLetterModal(true)
    } catch (error) {
      console.error('Failed to generate cover letter:', error)
      toast.error(error.message || 'Failed to generate cover letter')
    } finally {
      setIsGeneratingCoverLetter(false)
    }
  }

  const handleCoverLetterClick = async (e) => {
    if (job.coverLetter) {
      setShowCoverLetterModal(true)
    } else {
      if (user.plan === 'pro' || (userProfile?.coverLetterCount || 0) < 3) {
        handleGenerateCoverLetter()
      } else {
        const rect = e.currentTarget.getBoundingClientRect()
        const viewportHeight = window.innerHeight
        const modalHeight = 420
        const buffer = 20

        const isInTopHalf = rect.top < viewportHeight / 2
        const yPosition = isInTopHalf
          ? rect.top - 120
          : rect.top - modalHeight - buffer

        setUpgradeModalPosition({
          x: rect.x,
          y: Math.max(buffer, yPosition),
        })
        setShowUpgradeModal(true)
      }
    }
  }

  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape') {
        // ... handle escape key
      }
    }

    window.addEventListener('keydown', handleEscape)

    return () => {
      window.removeEventListener('keydown', handleEscape)
    }
  }, [])

  const handleDeleteConfirm = () => {
    onDelete(job.$id)
    setShowDeleteDialog(false)
  }

  const handleDeleteNoteConfirm = async () => {
    try {
      setNotes(notes.filter((note) => note.$id !== itemToDelete.$id))
      await deleteNote(itemToDelete.$id)
      toast.success('Note deleted successfully')
    } catch (error) {
      setNotes((prevNotes) => [...prevNotes])
      console.error('Failed to delete note:', error)
      toast.error('Failed to delete note')
    }
    setShowDeleteNoteDialog(false)
  }

  const handleDeleteTaskConfirm = async () => {
    try {
      setTasks(tasks.filter((task) => task.$id !== itemToDelete.$id))
      await deleteTask(itemToDelete.$id)
      toast.success('Task deleted successfully')
    } catch (error) {
      setTasks((prevTasks) => [...prevTasks])
      console.error('Failed to delete task:', error)
      toast.error('Failed to delete task')
    }
    setShowDeleteTaskDialog(false)
  }

  const remainingCount = Math.max(0, 3 - (userProfile?.coverLetterCount || 0))

  return (
    <motion.div
      layout
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className={`group relative bg-white dark:bg-secondary-800 rounded-xl shadow-sm hover:shadow-md transition-all duration-200
        ${
          viewType === 'grid'
            ? 'p-5 sm:p-6'
            : 'p-5 sm:p-6 flex items-center justify-between gap-4'
        }`}
    >
      <div className="flex-1 min-w-0">
        <div
          className={
            viewType === 'list'
              ? 'flex flex-col sm:flex-row sm:justify-between sm:items-start'
              : ''
          }
        >
          <div className="space-y-2.5">
            <h3 className="text-[1.175rem] sm:text-xl font-semibold bg-gradient-to-r from-secondary-900 to-secondary-700 dark:from-secondary-100 dark:to-secondary-300 bg-clip-text text-transparent">
              {job.title}
            </h3>
            <p className="text-secondary-600 dark:text-secondary-400 font-medium">
              {job.company}
            </p>

            {/* Job URL with hover effect */}
            {job.url && (
              <a
                href={job.url}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center text-sm text-primary-600 hover:text-primary-700 dark:text-primary-400 dark:hover:text-primary-300 transition-all duration-300 hover:translate-x-0.5 group/link"
              >
                View Job Posting
                <FiExternalLink className="ml-1 transition-transform group-hover/link:rotate-45" />
              </a>
            )}

            {/* metadata section */}
            <div className="flex flex-wrap gap-3 text-sm mt-1">
              {job.location && (
                <span className="inline-flex items-center gap-1.5 px-2.5 py-1 rounded-full bg-secondary-100/80 dark:bg-secondary-700/80 text-secondary-600 dark:text-secondary-300">
                  <FiMapPin className="w-3.5 h-3.5" />
                  {job.location}
                </span>
              )}
              {job.salary && (
                <span className="inline-flex items-center gap-1.5 px-2.5 py-1 rounded-full bg-green-100/80 dark:bg-green-900/30 text-green-700 dark:text-green-300">
                  <FiDollarSign className="w-3.5 h-3.5" />
                  {job.salary}
                </span>
              )}
              <span className="inline-flex items-center gap-1.5 px-2.5 py-1 rounded-full bg-secondary-100/80 dark:bg-secondary-700/80 text-secondary-600 dark:text-secondary-300">
                <FiCalendar className="w-3.5 h-3.5" />
                {format(new Date(job.createdAt), 'MMM d, yyyy')}
              </span>
            </div>
          </div>

          {/* Status and actions section with enhanced visual hierarchy */}
          <div
            className={`${
              viewType === 'list' ? 'mt-4 sm:mt-0 sm:ml-4' : 'mt-4'
            } flex flex-wrap items-center gap-3`}
          >
            {/* Cover Letter Button - Order changes on mobile */}
            <div
              className={`w-full sm:w-auto ${
                viewType === 'list'
                  ? 'order-first sm:order-none'
                  : 'order-first sm:order-last'
              }`}
            >
              <button
                onClick={handleCoverLetterClick}
                disabled={isGeneratingCoverLetter}
                className={`
                  inline-flex items-center justify-center gap-2 px-4 py-2 rounded-lg text-sm font-medium
                  w-full sm:w-auto min-w-[180px]
                  ${
                    job.coverLetter
                      ? 'bg-gradient-to-l sm:bg-gradient-to-r from-secondary-200/80 via-secondary-200/80 to-secondary-300/80 hover:from-secondary-200/90 hover:via-secondary-200/90 hover:to-secondary-300/90 text-secondary-600 hover:text-secondary-700 dark:from-secondary-700/30 dark:via-secondary-700/50 dark:to-secondary-600/30 dark:hover:from-secondary-600/40 dark:hover:via-secondary-600/60 dark:hover:to-secondary-500/40 dark:text-secondary-200 dark:hover:text-secondary-100'
                      : 'bg-gradient-to-l sm:bg-gradient-to-r from-primary-50/30 via-primary-100/50 to-primary-100/30 hover:from-primary-100/40 hover:via-primary-100/60 hover:to-primary-200/40 text-primary-600 hover:text-primary-700 dark:from-primary-800/20 dark:via-primary-800/40 dark:to-primary-700/20 dark:hover:from-primary-700/30 dark:hover:via-primary-700/50 dark:hover:to-primary-600/30 dark:text-primary-300 dark:hover:text-primary-200'
                  }
                  disabled:opacity-60 disabled:cursor-not-allowed
                  border border-transparent hover:border-secondary-200/50 dark:hover:border-secondary-600/50
                `}
              >
                {isGeneratingCoverLetter ? (
                  <>
                    <FiLoader className="animate-spin" />
                    <span className="whitespace-nowrap">Generating...</span>
                  </>
                ) : (
                  <>
                    <FiFileText className="text-current flex-shrink-0" />
                    <span className="whitespace-nowrap">
                      {job.coverLetter
                        ? 'View Cover Letter'
                        : 'Generate Cover Letter'}
                    </span>
                  </>
                )}
              </button>
            </div>

            {/* Status Select */}
            <select
              value={job.status}
              onChange={handleStatusChange}
              className={`flex-1 sm:flex-none px-3 py-1.5 rounded-lg text-sm font-medium transition-all duration-300 hover:ring-2 hover:ring-offset-2 hover:ring-primary-500/20 focus:ring-2 focus:ring-offset-2 focus:ring-primary-500/40 ${getStatusColor(
                job.status,
              )}`}
            >
              <option value="not-started">Not Started</option>
              <option value="applied">Applied</option>
              <option value="interviewing">Interviewing</option>
              <option value="got-offer">Got an Offer</option>
              <option value="declined">Declined</option>
            </select>

            {/* Delete Button */}
            <button
              onClick={handleDeleteClick}
              className={`p-2 text-secondary-400 hover:text-red-600 dark:text-secondary-500 dark:hover:text-red-400
              transition-all duration-200 rounded-full hover:bg-secondary-50 dark:hover:bg-secondary-700
              ${
                viewType === 'list'
                  ? 'opacity-0 group-hover:opacity-100 scale-90 group-hover:scale-100 sm:order-last'
                  : 'absolute top-4 right-4 opacity-0 group-hover:opacity-100 scale-90 group-hover:scale-100'
              }`}
              title="Delete job"
            >
              <FiX size={18} />
            </button>
          </div>
        </div>

        {(firstUncompletedTask || suggestedTask) && (
          <div className="mt-2 text-sm text-secondary-600 dark:text-secondary-400">
            <span className="font-semibold">
              {suggestedTask ? 'Suggested action:' : 'Next task:'}
            </span>{' '}
            {suggestedTask || firstUncompletedTask.description}
          </div>
        )}

        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="mt-4 text-primary-600 hover:text-primary-700 dark:text-primary-300 dark:hover:text-primary-200 flex items-center text-sm"
        >
          <FiChevronDown
            className={`transform transition-transform ${
              isExpanded ? 'rotate-180' : ''
            }`}
          />
          <span className="ml-1">{isExpanded ? 'Show less' : 'Show more'}</span>
        </button>

        <AnimatePresence>
          {isExpanded && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
              className="mt-4 space-y-4"
            >
              {/* Notes Section */}
              <div className="space-y-2">
                <h4 className="font-semibold text-secondary-900 dark:text-secondary-100">
                  Notes
                </h4>
                <div className="space-y-2">
                  {notes.map((note) => (
                    <div
                      key={note.$id}
                      className="bg-secondary-50 dark:bg-secondary-700 p-3 rounded-lg text-sm relative hover-parent"
                    >
                      <button
                        onClick={(e) => handleDeleteNoteClick(e, note)}
                        className="absolute top-2 right-2 text-secondary-400 hover:text-red-600 dark:text-secondary-500 dark:hover:text-red-400 opacity-0 hover-child transition-opacity"
                        title="Delete note"
                      >
                        <FiX size={16} />
                      </button>
                      <p className="text-secondary-700 dark:text-secondary-300 pr-8 whitespace-pre-wrap">
                        {note.content}
                      </p>
                      <p className="text-xs text-secondary-400 dark:text-secondary-500 mt-1">
                        {format(new Date(note.createdAt), 'MMM d, yyyy')}
                      </p>
                    </div>
                  ))}
                </div>
                <form onSubmit={handleAddNote}>
                  <input
                    type="text"
                    value={newNote}
                    onChange={(e) => setNewNote(e.target.value)}
                    placeholder="Add a note"
                    className="input text-sm dark:bg-secondary-700 dark:text-secondary-100"
                  />
                </form>
              </div>

              {/* Tasks Section */}
              <div className="space-y-2">
                <h4 className="font-semibold text-secondary-900 dark:text-secondary-100">
                  Tasks
                </h4>
                <ul className="space-y-2">
                  {tasks.map((task) => (
                    <motion.li
                      key={task.$id}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      className="flex items-center space-x-2 relative hover-parent"
                    >
                      <div
                        onClick={() => handleToggleTask(task.$id)}
                        className="flex-grow flex items-center space-x-2 cursor-pointer transition-transform hover:scale-[1.01]"
                      >
                        <div
                          className={`w-5 h-5 rounded-full border-2 flex items-center justify-center ${
                            task.completed
                              ? 'bg-primary-600 border-primary-600 dark:bg-primary-500 dark:border-primary-500'
                              : 'border-secondary-300 dark:border-secondary-600'
                          }`}
                          style={{ minWidth: '20px', minHeight: '20px' }}
                        >
                          {task.completed && (
                            <FiCheck className="text-white" size={12} />
                          )}
                        </div>
                        <span
                          className={`${
                            task.completed
                              ? 'line-through text-secondary-400 dark:text-secondary-500'
                              : 'text-secondary-700 dark:text-secondary-300'
                          }`}
                        >
                          {task.description}
                        </span>
                      </div>
                      <button
                        onClick={(e) => handleDeleteTaskClick(e, task)}
                        className="text-secondary-400 hover:text-red-600 dark:text-secondary-500 dark:hover:text-red-400 opacity-0 hover-child transition-opacity"
                        title="Delete task"
                      >
                        <FiX size={16} />
                      </button>
                    </motion.li>
                  ))}
                </ul>
                <form
                  onSubmit={handleAddTask}
                  className="flex items-center space-x-2"
                >
                  <input
                    type="text"
                    value={newTask}
                    onChange={(e) => setNewTask(e.target.value)}
                    placeholder="Add a task"
                    className="input flex-grow text-sm dark:bg-secondary-700 dark:text-secondary-100"
                  />
                  <button
                    type="submit"
                    className="btn btn-primary p-2 dark:bg-primary-600 dark:text-white"
                    disabled={!newTask.trim()}
                  >
                    <FiPlus />
                  </button>
                </form>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <AnimatePresence key="delete-note-dialog">
        <DeleteConfirmDialog
          isOpen={showDeleteNoteDialog}
          onClose={() => setShowDeleteNoteDialog(false)}
          onConfirm={handleDeleteNoteConfirm}
          title="Delete Note"
          message="Are you sure you want to delete this note? This action cannot be undone."
          position={deleteDialogPosition}
        />
      </AnimatePresence>

      <AnimatePresence key="delete-task-dialog">
        <DeleteConfirmDialog
          isOpen={showDeleteTaskDialog}
          onClose={() => setShowDeleteTaskDialog(false)}
          onConfirm={handleDeleteTaskConfirm}
          title="Delete Task"
          message="Are you sure you want to delete this task? This action cannot be undone."
          position={deleteDialogPosition}
        />
      </AnimatePresence>

      <AnimatePresence key="delete-job-dialog">
        <DeleteConfirmDialog
          isOpen={showDeleteDialog}
          onClose={() => setShowDeleteDialog(false)}
          onConfirm={handleDeleteConfirm}
          title="Delete Job Application"
          message={`Are you sure you want to delete the job application for ${job.title}? This action cannot be undone.`}
          position={deletePosition}
        />
      </AnimatePresence>

      <CoverLetterModal
        isOpen={showCoverLetterModal}
        onClose={() => setShowCoverLetterModal(false)}
        coverLetter={job.coverLetter}
        onRegenerate={(style) => handleGenerateCoverLetter(style)}
        isGenerating={isGeneratingCoverLetter}
        job={job}
        remainingCount={remainingCount}
        user={user}
        onShowUpgradeModal={() => {
          const rect = document.body.getBoundingClientRect()
          setUpgradeModalPosition({
            x: rect.width / 2,
            y: rect.height / 3,
          })
          setShowUpgradeModal(true)
        }}
      />

      <UpgradeModal
        isOpen={showUpgradeModal}
        onClose={() => setShowUpgradeModal(false)}
        position={upgradeModalPosition}
      />

      <ResumePromptModal
        isOpen={showResumePromptModal}
        onClose={() => setShowResumePromptModal(false)}
        user={user}
      />
    </motion.div>
  )
}

JobCard.propTypes = {
  job: PropTypes.shape({
    $id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    company: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    url: PropTypes.string,
    location: PropTypes.string,
    salary: PropTypes.string,
    createdAt: PropTypes.string.isRequired,
    coverLetter: PropTypes.string,
  }).isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  user: PropTypes.shape({
    $id: PropTypes.string.isRequired,
    plan: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
  viewType: PropTypes.string,
}

export default JobCard
