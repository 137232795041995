const DashboardSkeleton = () => {
  return (
    <div className="p-4 sm:p-6 space-y-6">
      {/* Header Skeleton */}
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
        <div className="space-y-3">
          <div className="h-8 w-48 bg-secondary-200 dark:bg-secondary-700 rounded-lg animate-pulse" />
          <div className="h-5 w-72 bg-secondary-200 dark:bg-secondary-700 rounded animate-pulse" />
        </div>
        <div className="flex items-center gap-2">
          <div className="h-10 w-32 bg-secondary-200 dark:bg-secondary-700 rounded-lg animate-pulse" />
          <div className="h-10 w-10 bg-secondary-200 dark:bg-secondary-700 rounded-lg animate-pulse" />
        </div>
      </div>

      {/* View Toggle Skeleton */}
      <div className="flex items-center justify-between">
        <div className="flex gap-2">
          {[...Array(3)].map((_, i) => (
            <div
              key={i}
              className="h-9 w-24 bg-secondary-200 dark:bg-secondary-700 rounded animate-pulse"
            />
          ))}
        </div>
      </div>

      {/* Grid View Skeleton */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {[...Array(6)].map((_, i) => (
          <div
            key={i}
            className="bg-white dark:bg-secondary-800 rounded-xl p-4 space-y-4"
          >
            <div className="flex items-start justify-between">
              <div className="space-y-3 flex-1">
                <div className="h-6 w-3/4 bg-secondary-200 dark:bg-secondary-700 rounded animate-pulse" />
                <div className="h-5 w-1/2 bg-secondary-200 dark:bg-secondary-700 rounded animate-pulse" />
              </div>
              <div className="h-8 w-8 bg-secondary-200 dark:bg-secondary-700 rounded animate-pulse" />
            </div>
            <div className="space-y-2">
              <div className="h-4 w-full bg-secondary-200 dark:bg-secondary-700 rounded animate-pulse" />
              <div className="h-4 w-3/4 bg-secondary-200 dark:bg-secondary-700 rounded animate-pulse" />
            </div>
            <div className="flex justify-between items-center pt-2">
              <div className="h-8 w-24 bg-secondary-200 dark:bg-secondary-700 rounded animate-pulse" />
              <div className="h-8 w-8 bg-secondary-200 dark:bg-secondary-700 rounded-full animate-pulse" />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default DashboardSkeleton
