import { FiExternalLink, FiFilter, FiBriefcase, FiBook } from 'react-icons/fi'
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLocation, Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { motion } from 'framer-motion'

const JobResources = () => {
  const location = useLocation()
  const [selectedCategory, setSelectedCategory] = useState('all')
  const [activeTab, setActiveTab] = useState('job-boards')

  useEffect(() => {
    const hash = location.hash.replace('#', '')
    if (hash === 'career-resources' || hash === 'job-boards') {
      setActiveTab(hash)
    }
  }, [location.hash])

  const jobBoards = {
    generalPurpose: [
      {
        name: 'LinkedIn Jobs',
        url: 'https://www.linkedin.com/jobs',
        description: 'Professional networking and job opportunities',
        categories: ['All Industries', 'Local', 'Remote'],
        icon: '💼',
      },
      {
        name: 'Wellfound (AngelList)',
        url: 'https://wellfound.com/jobs',
        description: 'Top tech and startup jobs with salary transparency',
        categories: ['All Industries', 'Tech', 'Startup'],
        icon: '🚀',
      },
      {
        name: 'ZipRecruiter',
        url: 'https://www.ziprecruiter.com',
        description: 'Smart job matching with one-click apply',
        categories: ['All Industries', 'Local', 'Remote'],
        icon: '⚡',
      },
    ],
    remote: [
      {
        name: 'WeWorkRemotely',
        url: 'https://weworkremotely.com',
        description:
          'Popular remote job board focusing on tech and digital roles',
        categories: ['Remote', 'Tech', 'Design', 'Management'],
        icon: '🌎',
      },
      {
        name: 'Remotive',
        url: 'https://remotive.com',
        description:
          'Curated remote jobs in tech, marketing, and customer support',
        categories: ['Remote', 'Tech', 'Customer Support', 'Sales'],
        icon: '💻',
      },
      {
        name: 'Remote OK',
        url: 'https://remoteok.com',
        description: 'Remote jobs from startups and tech companies',
        categories: ['Remote', 'Tech', 'Startup'],
        icon: '🚀',
      },
    ],
    tech: [
      {
        name: 'Stack Overflow Jobs',
        url: 'https://stackoverflow.com/jobs',
        description: 'Developer-focused job board with technical roles',
        categories: ['Tech', 'Programming', 'Remote'],
        icon: '👩‍💻',
      },
      {
        name: 'GitHub Jobs',
        url: 'https://jobs.github.com',
        description: 'Software development and engineering positions',
        categories: ['Tech', 'Programming', 'Remote'],
        icon: '🐱',
      },
      {
        name: 'Dice',
        url: 'https://dice.com',
        description:
          'Specialized job board for IT and technology professionals',
        categories: ['Tech', 'IT', 'Programming'],
        icon: '🎲',
      },
    ],
    startup: [
      {
        name: 'Y Combinator Jobs',
        url: 'https://www.ycombinator.com/jobs',
        description: 'Direct job listings from YC-backed companies',
        categories: ['Tech', 'Startup'],
        icon: '✨',
      },
      {
        name: 'Work at a Startup',
        url: 'https://www.workatastartup.com',
        description: 'Job platform exclusively for Y Combinator startups',
        categories: ['Tech', 'Startup'],
        icon: '🚀',
      },
      {
        name: 'Startup.jobs',
        url: 'https://startup.jobs',
        description: 'Aggregated startup jobs from funded companies',
        categories: ['Tech', 'Startup', 'Remote'],
        icon: '💫',
      },
      {
        name: 'Otta',
        url: 'https://otta.com',
        description: 'Personalized job matching for tech and startup roles',
        categories: ['Tech', 'Startup', 'Remote'],
        icon: '🎯',
      },
    ],
  }

  const careerResources = [
    {
      name: 'Resume.io',
      url: 'https://resume.io',
      description: 'Online resume builder with ATS-friendly templates',
      categories: ['Resume', 'Portfolio', 'Free'],
      icon: '📝',
    },
    {
      name: 'Canva Resume Builder',
      url: 'https://www.canva.com/resumes',
      description: 'Visual resume builder with customizable templates',
      categories: ['Resume', 'Portfolio', 'Free'],
      icon: '🎨',
    },
    {
      name: 'PayScale',
      url: 'https://www.payscale.com/salary-calculator',
      description: 'Real-time salary data and compensation research tools',
      categories: ['Salary', 'Research', 'Free'],
      icon: '💰',
    },
    {
      name: 'Levels.fyi',
      url: 'https://www.levels.fyi',
      description: 'Crowdsourced tech compensation data and career levels',
      categories: ['Salary', 'Tech', 'Career Path'],
      icon: '📊',
    },
    {
      name: 'Pramp',
      url: 'https://www.pramp.com',
      description: 'Peer-to-peer technical interview preparation platform',
      categories: ['Interview Prep', 'Tech', 'Free'],
      icon: '🎯',
    },
    {
      name: 'interviewing.io',
      url: 'https://interviewing.io',
      description: 'Practice technical interviews with experienced engineers',
      categories: ['Interview Prep', 'Tech', 'Premium'],
      icon: '👥',
    },
    {
      name: 'Coursera',
      url: 'https://www.coursera.org',
      description: 'Online courses to boost your skills from top universities',
      categories: ['Learning', 'Certification', 'Premium'],
      icon: '📚',
    },
    {
      name: 'LinkedIn Learning',
      url: 'https://www.linkedin.com/learning',
      description:
        'Professional courses with certificates for your LinkedIn profile',
      categories: ['Learning', 'Certification', 'Premium'],
      icon: '🎓',
    },
    {
      name: 'Grammarly',
      url: 'https://www.grammarly.com',
      description: 'AI writing assistant for applications and emails',
      categories: ['Writing', 'Communication', 'Free'],
      icon: '✍️',
    },
    {
      name: 'HackerRank',
      url: 'https://www.hackerrank.com',
      description: 'Coding practice and technical assessments',
      categories: ['Interview Prep', 'Tech', 'Free'],
      icon: '⌨️',
    },
    {
      name: 'Blind',
      url: 'https://www.teamblind.com',
      description: 'Anonymous professional network with career insights',
      categories: ['Networking', 'Career Advice', 'Free'],
      icon: '👁️',
    },
    {
      name: 'Fishbowl',
      url: 'https://www.fishbowlapp.com',
      description: 'Industry-specific career discussions and networking',
      categories: ['Networking', 'Career Advice', 'Free'],
      icon: '🐠',
    },
    {
      name: 'Carrus.io',
      url: 'https://www.carrus.io',
      description: 'Career coaching and professional development',
      categories: ['Coaching', 'Career Path', 'Premium'],
      icon: '🚀',
    },
  ]

  // Get unique categories from all job boards
  const allCategories = [
    'all',
    ...new Set(
      Object.values(jobBoards)
        .flat()
        .flatMap((board) => board.categories),
    ),
  ]

  const filterBoards = (boards) =>
    selectedCategory === 'all'
      ? boards
      : boards.filter((board) => board.categories.includes(selectedCategory))

  return (
    <div className="py-4">
      <Helmet>
        <title>Job Search Resources - NextJob</title>
        <meta
          name="description"
          content="Curated collection of job boards, career resources, and professional development tools to help you in your job search."
        />
      </Helmet>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        className="space-y-3 sm:space-y-4 px-2 sm:px-0 relative max-w-6xl mx-auto"
      >
        {/* Background gradient effect */}
        <div className="absolute inset-0 bg-white/40 dark:bg-secondary-900/50 backdrop-blur-sm rounded-xl -z-10" />

        {/* Tabs Section */}
        <div className="flex border-b border-secondary-200 dark:border-secondary-700 bg-white/80 dark:bg-secondary-800/70 backdrop-blur-md p-4 rounded-xl border border-slate-200/50 dark:border-secondary-700/20">
          <Link
            to="#job-boards"
            className={`flex items-center gap-2 px-4 py-3 font-medium transition-colors border-b-2 -mb-px
              ${
                activeTab === 'job-boards'
                  ? 'border-primary-600 text-primary-600 dark:border-primary-400 dark:text-primary-400'
                  : 'border-transparent text-secondary-600 hover:text-secondary-900 dark:text-secondary-400 dark:hover:text-secondary-100'
              }`}
          >
            <FiBriefcase className="text-xl sm:text-base md:hidden" />
            <span className="hidden md:inline">Job Boards</span>
            <span className="md:hidden">Boards</span>
          </Link>
          <Link
            to="#career-resources"
            className={`flex items-center gap-2 px-4 py-3 font-medium transition-colors border-b-2 -mb-px
              ${
                activeTab === 'career-resources'
                  ? 'border-primary-600 text-primary-600 dark:border-primary-400 dark:text-primary-400'
                  : 'border-transparent text-secondary-600 hover:text-secondary-900 dark:text-secondary-400 dark:hover:text-secondary-100'
              }`}
          >
            <FiBook className="text-xl sm:text-base md:hidden" />
            <span className="hidden md:inline">Career Resources</span>
            <span className="md:hidden">Resources</span>
          </Link>
        </div>

        {/* Filter Section */}
        <div className="bg-white/80 dark:bg-secondary-800/70 backdrop-blur-md p-4 rounded-xl border border-slate-200/50 dark:border-secondary-700/20">
          <div className="flex items-center gap-3 overflow-x-auto pb-2">
            <FiFilter className="text-secondary-400" />
            {(activeTab === 'job-boards'
              ? allCategories
              : [
                  'all',
                  ...new Set(
                    careerResources.flatMap((resource) => resource.categories),
                  ),
                ]
            ).map((category) => (
              <button
                key={category}
                onClick={() => setSelectedCategory(category)}
                className={`px-4 py-2 rounded-full text-sm whitespace-nowrap transition-colors
                  ${
                    selectedCategory === category
                      ? 'bg-primary-100 text-primary-700 dark:bg-primary-900/30 dark:text-primary-300'
                      : 'bg-secondary-100 text-secondary-600 hover:bg-secondary-200 dark:bg-secondary-800 dark:text-secondary-400 dark:hover:bg-secondary-700'
                  }`}
              >
                {category.charAt(0).toUpperCase() + category.slice(1)}
              </button>
            ))}
          </div>
        </div>

        {/* Content Section */}
        <div className="bg-white/90 dark:bg-secondary-800/70 backdrop-blur-md p-4 rounded-xl border border-slate-200/50 dark:border-secondary-700/20">
          {activeTab === 'job-boards' ? (
            <div className="space-y-12">
              {/* Remote Job Boards */}
              {filterBoards(jobBoards.remote).length > 0 && (
                <section>
                  <h2 className="text-2xl font-semibold text-secondary-900 dark:text-secondary-100 mb-4">
                    Remote Job Boards
                  </h2>
                  <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                    {filterBoards(jobBoards.remote).map((board) => (
                      <JobBoardCard key={board.name} board={board} />
                    ))}
                  </div>
                </section>
              )}

              {/* Tech Job Boards */}
              {filterBoards(jobBoards.tech).length > 0 && (
                <section>
                  <h2 className="text-2xl font-semibold text-secondary-900 dark:text-secondary-100 mb-4">
                    Tech Job Boards
                  </h2>
                  <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                    {filterBoards(jobBoards.tech).map((board) => (
                      <JobBoardCard key={board.name} board={board} />
                    ))}
                  </div>
                </section>
              )}

              {/* Startup Job Boards */}
              {filterBoards(jobBoards.startup).length > 0 && (
                <section>
                  <h2 className="text-2xl font-semibold text-secondary-900 dark:text-secondary-100 mb-4">
                    Startup Job Boards
                  </h2>
                  <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                    {filterBoards(jobBoards.startup).map((board) => (
                      <JobBoardCard key={board.name} board={board} />
                    ))}
                  </div>
                </section>
              )}

              {/* General Job Boards */}
              {filterBoards(jobBoards.generalPurpose).length > 0 && (
                <section>
                  <h2 className="text-2xl font-semibold text-secondary-900 dark:text-secondary-100 mb-4">
                    General Job Boards
                  </h2>
                  <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                    {filterBoards(jobBoards.generalPurpose).map((board) => (
                      <JobBoardCard key={board.name} board={board} />
                    ))}
                  </div>
                </section>
              )}
            </div>
          ) : (
            <div className="space-y-6">
              <p className="text-secondary-600 dark:text-secondary-400 mb-6">
                Essential tools and resources to help you prepare for your job
                search
              </p>
              <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                {(selectedCategory === 'all'
                  ? careerResources
                  : careerResources.filter((resource) =>
                      resource.categories.includes(selectedCategory),
                    )
                ).map((resource) => (
                  <JobBoardCard key={resource.name} board={resource} />
                ))}
              </div>
            </div>
          )}
        </div>

        {/* Background gradients */}
        <div className="absolute inset-0 -z-20 overflow-hidden">
          <div className="absolute -top-1/2 -left-1/2 w-full h-full bg-gradient-to-r from-primary-500/10 to-purple-500/10 blur-3xl transform rotate-12 dark:from-primary-900/20 dark:to-purple-900/20" />
          <div className="absolute -bottom-1/2 -right-1/2 w-full h-full bg-gradient-to-l from-primary-500/10 to-purple-500/10 blur-3xl transform -rotate-12 dark:from-primary-900/20 dark:to-purple-900/20" />
        </div>
      </motion.div>
    </div>
  )
}

const JobBoardCard = ({ board }) => (
  <a
    href={board.url}
    target="_blank"
    rel="noopener noreferrer"
    className="group p-6 rounded-xl border border-secondary-200 dark:border-secondary-700 hover:border-primary-500 dark:hover:border-primary-500 transition-colors bg-white/50 dark:bg-secondary-800/50 backdrop-blur-sm hover:shadow-lg"
  >
    <div className="flex items-start justify-between mb-4">
      <div className="flex items-center gap-3">
        <span className="text-2xl">{board.icon}</span>
        <h3 className="text-xl font-semibold text-secondary-900 dark:text-secondary-100 group-hover:text-primary-600 dark:group-hover:text-primary-400">
          {board.name}
        </h3>
      </div>
      <FiExternalLink className="text-secondary-400 group-hover:text-primary-500" />
    </div>
    <p className="text-secondary-600 dark:text-secondary-400 mb-4">
      {board.description}
    </p>
    {board.categories && (
      <div className="flex flex-wrap gap-2">
        {board.categories.map((category) => (
          <span
            key={category}
            className="px-3 py-1 text-sm rounded-full bg-secondary-100 dark:bg-secondary-800 text-secondary-600 dark:text-secondary-400"
          >
            {category}
          </span>
        ))}
      </div>
    )}
  </a>
)

JobBoardCard.propTypes = {
  board: PropTypes.shape({
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    categories: PropTypes.arrayOf(PropTypes.string),
    type: PropTypes.string,
  }).isRequired,
}

export default JobResources
