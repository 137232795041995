import { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import PropTypes from 'prop-types'
import {
  FiChevronUp,
  FiChevronDown,
  FiClock,
  FiFileText,
  FiUsers,
  FiAward,
} from 'react-icons/fi'
import StatCard from './StatCard'

const DashboardStats = ({ stats, statusCounts, totalApplications }) => {
  const [showAllStats, setShowAllStats] = useState(false)
  const [activeStatus, setActiveStatus] = useState(null)

  return (
    <div className="grid gap-4">
      {/* Primary Stats - Always Visible */}
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
        <StatCard
          icon={FiClock}
          label="Active Applications"
          value={stats.activeApplications}
        />
        <StatCard
          icon={FiFileText}
          label="Total Applications"
          value={stats.totalApplications}
        />
        <StatCard
          icon={FiUsers}
          label="Interview Rate"
          value={`${stats.interviewRate}%`}
        />
        <StatCard
          icon={FiAward}
          label="Offer Rate"
          value={`${stats.offerRate}%`}
        />
      </div>

      {/* Toggle Button */}
      <button
        onClick={() => setShowAllStats(!showAllStats)}
        className="text-sm text-primary-600 dark:text-primary-400 hover:text-primary-700 dark:hover:text-primary-300 flex items-center gap-2 justify-center"
      >
        {showAllStats ? (
          <>
            Show Less <FiChevronUp />
          </>
        ) : (
          <>
            Show More Stats <FiChevronDown />
          </>
        )}
      </button>

      {/* Expandable Stats Section */}
      <AnimatePresence>
        {showAllStats && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="overflow-hidden"
          >
            {/* Status breakdown */}
            <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-4">
              {Object.entries(statusCounts).map(([status, count]) => (
                <StatusBreakdown
                  key={status}
                  status={status}
                  count={count}
                  total={totalApplications}
                  isActive={activeStatus === status}
                  onHover={setActiveStatus}
                />
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

const StatusBreakdown = ({ status, count, total, isActive, onHover }) => {
  const formatStatus = (status) => {
    return status
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }

  const percentage = total > 0 ? Math.round((count / total) * 100) : 0
  const getStatusColor = (status) => {
    switch (status) {
      case 'not-started':
        return 'bg-secondary-400'
      case 'applied':
        return 'bg-blue-500'
      case 'interviewing':
        return 'bg-yellow-500'
      case 'got-offer':
        return 'bg-green-500'
      case 'declined':
        return 'bg-red-500'
      default:
        return 'bg-secondary-400'
    }
  }

  return (
    <div
      className={`relative p-3 sm:p-4 rounded-xl transition-all duration-200 ${
        isActive ? 'bg-secondary-50 dark:bg-secondary-700/50 scale-105' : ''
      }`}
      onMouseEnter={() => onHover(status)}
      onMouseLeave={() => onHover(null)}
    >
      <div className="text-sm text-secondary-600 dark:text-secondary-400 mb-1">
        {formatStatus(status)}
      </div>
      <div className="flex items-baseline gap-2">
        <div className="text-xl sm:text-2xl font-bold text-primary-600 dark:text-primary-400">
          {count}
        </div>
        <div className="text-xs text-secondary-500 dark:text-secondary-500">
          {percentage}%
        </div>
      </div>
      <div className="mt-2 relative h-2 bg-secondary-100 dark:bg-secondary-700 rounded-full overflow-hidden">
        <motion.div
          initial={{ width: 0 }}
          animate={{ width: `${percentage}%` }}
          transition={{ duration: 0.5, ease: 'easeOut' }}
          className={`absolute left-0 top-0 h-full ${getStatusColor(status)}`}
        />
      </div>
    </div>
  )
}

DashboardStats.propTypes = {
  stats: PropTypes.shape({
    totalApplications: PropTypes.number.isRequired,
    interviewRate: PropTypes.number.isRequired,
    offerRate: PropTypes.number.isRequired,
    activeApplications: PropTypes.number.isRequired,
  }).isRequired,
  statusCounts: PropTypes.object.isRequired,
  totalApplications: PropTypes.number.isRequired,
}

StatusBreakdown.propTypes = {
  status: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  isActive: PropTypes.bool.isRequired,
  onHover: PropTypes.func.isRequired,
}

export default DashboardStats
