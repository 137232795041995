import { useDropzone } from 'react-dropzone'
import { useContext, useState } from 'react'
import { storage } from '../utils/appwrite'
import { FiUpload } from 'react-icons/fi'
import PropTypes from 'prop-types'
import toast from 'react-hot-toast'
import { RESUMES_BUCKET_ID } from '../utils/appwrite'
import { getUserProfile } from '../utils/db'
import { UserContext } from '../App'

const ResumeUpload = ({ user, onUploadComplete }) => {
  const [isUploading, setIsUploading] = useState(false)
  const { setHasResume } = useContext(UserContext)

  const apiUrl = import.meta.env.VITE_API_URL

  const onDrop = async (acceptedFiles) => {
    try {
      setIsUploading(true)
      const file = acceptedFiles[0]

      // Get user profile to check for existing resume
      const userProfile = await getUserProfile(user.$id)
      const oldResumeFileId = userProfile?.resumeFileId

      // Upload new file to Appwrite storage first
      const uploadResponse = await storage.createFile(
        RESUMES_BUCKET_ID,
        'unique()',
        file,
      )

      // Process the new resume
      const processResponse = await fetch(`${apiUrl}/process-resume`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          fileId: uploadResponse.$id,
          bucketId: RESUMES_BUCKET_ID,
          userId: user.$id,
          fileName: file.name,
        }),
      })

      if (!processResponse.ok) {
        // If processing fails, delete the uploaded file
        await storage.deleteFile(RESUMES_BUCKET_ID, uploadResponse.$id)
        throw new Error('Failed to process resume')
      }

      // If there's an existing resume, delete it
      if (oldResumeFileId) {
        await storage.deleteFile(RESUMES_BUCKET_ID, oldResumeFileId)
      }

      // update state
      setHasResume(true)

      // Call onUploadComplete with the new resume info
      onUploadComplete?.({
        fileId: uploadResponse.$id,
        fileName: file.name,
        uploadDate: new Date().toISOString(),
      })

      toast.success('Resume uploaded successfully')
    } catch (error) {
      console.error('Failed to upload resume:', error)
      toast.error('Failed to upload resume')
    } finally {
      setIsUploading(false)
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        ['.docx'],
    },
    maxFiles: 1,
  })

  return (
    <div className="w-full max-w-md mx-auto">
      <div
        {...getRootProps()}
        className={`border-2 border-dashed rounded-lg p-6 text-center cursor-pointer transition-all
          ${
            isDragActive
              ? 'border-primary-500 bg-primary-50 dark:bg-primary-900/10'
              : 'border-secondary-200 dark:border-secondary-700'
          }
          ${
            isUploading
              ? 'opacity-50 cursor-not-allowed'
              : 'hover:border-primary-500'
          }`}
      >
        <input {...getInputProps()} disabled={isUploading} />
        <div className="flex flex-col items-center gap-2">
          <FiUpload className="w-8 h-8 text-secondary-400" />
          <p className="text-secondary-600 dark:text-secondary-400">
            {isUploading
              ? 'Uploading...'
              : isDragActive
              ? 'Drop your resume here'
              : 'Drag & drop your resume or click to select'}
          </p>
          <p className="text-xs text-secondary-500">
            Supported formats: PDF, DOC, DOCX
          </p>
        </div>
      </div>
    </div>
  )
}

ResumeUpload.propTypes = {
  user: PropTypes.shape({
    $id: PropTypes.string.isRequired,
  }).isRequired,
  onUploadComplete: PropTypes.func,
}

ResumeUpload.defaultProps = {
  onUploadComplete: () => {},
}

export default ResumeUpload
