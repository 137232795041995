export const PLAN_TYPES = {
  FREE: 'free',
  PRO: 'pro',
}

export const PLAN_LIMITS = {
  [PLAN_TYPES.FREE]: {
    monthlyJobs: 10,
    aiFeatures: {
      jobExtraction: true, // Basic job detail extraction
      prepTasks: false, // No AI interview prep
      insights: false, // No AI application insights
      customPrompts: false, // No custom AI interactions
    },
    features: [
      'Track up to 10 jobs/month',
      'Basic job detail extraction',
      'Simple application tracking',
      'Access to job resources',
    ],
  },
  [PLAN_TYPES.PRO]: {
    monthlyJobs: 'unlimited',
    aiFeatures: {
      jobExtraction: true, // Enhanced job detail extraction
      prepTasks: true, // AI interview prep
      insights: true, // AI application insights
      customPrompts: true, // Custom AI interactions
    },
    price: 9.99,
    features: [
      'Unlimited job tracking',
      'AI-powered interview prep',
      'Application insights & analytics',
      'Custom AI job analysis',
      'Priority support',
    ],
  },
}
