import { Link } from 'react-router-dom'

const Footer = () => {
  const year = new Date().getFullYear()

  return (
    <footer className="py-6 px-4 border-t border-secondary-200 dark:border-secondary-800">
      <div className="container mx-auto flex flex-col sm:flex-row justify-between items-center gap-4">
        <div className="text-sm text-secondary-600 dark:text-secondary-400">
          © {year} NextJob. All rights reserved.
        </div>
        <div className="flex gap-6 text-sm text-secondary-600 dark:text-secondary-400">
          <a
            href="mailto:support@nextjob.work"
            className="hover:text-primary-600 dark:hover:text-primary-400 transition-colors"
          >
            Contact
          </a>
          <Link
            to="/privacy"
            className="hover:text-primary-600 dark:hover:text-primary-400 transition-colors"
          >
            Privacy
          </Link>
          <Link
            to="/terms"
            className="hover:text-primary-600 dark:hover:text-primary-400 transition-colors"
          >
            Terms
          </Link>
        </div>
      </div>
    </footer>
  )
}

export default Footer
