import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from 'react-router-dom'
import { useState, useEffect, createContext, useContext } from 'react'
import { account } from './utils/appwrite'
import { Toaster, toast } from 'react-hot-toast'
import Header from './components/Header'
import Dashboard from './components/Dashboard'
import JobFormModal from './components/JobFormModal'
import Login from './components/Login'
import { JobProvider } from './contexts/JobContext'
import LandingPage from './components/LandingPage'
import Footer from './components/Footer'
import Privacy from './components/Privacy'
import Terms from './components/Terms'
import JobResources from './components/JobResources'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import Upgrade from './components/Upgrade'
import ProtectedRoute from './components/ProtectedRoute'
import Settings from './components/Settings'
import { getUserPlan } from './utils/planUtils'
import Profile from './components/Profile'
import { getUserProfile } from './utils/db'
import BlogList from './components/blog/BlogList'
import BlogPost from './components/blog/BlogPost'
import { BlogProvider } from './contexts/BlogContext'
import ErrorBoundary from './components/ErrorBoundary'
import { sendEmail } from './utils/emailUtils'

export const ThemeContext = createContext()

// eslint-disable-next-line react-refresh/only-export-components
export const useTheme = () => useContext(ThemeContext)

const getMetaData = (pathname) => {
  switch (pathname) {
    case '/':
      return {
        title: 'NextJob - Land your next dream job',
        description:
          'Skip the spreadsheets. Let AI handle your job search details while you focus on interviews. Join thousands of job seekers who land interviews 3x faster with NextJob.',
      }
    case '/resources':
      return {
        title: 'Job Search Resources - NextJob',
        description:
          'Hand-picked job boards and career resources that actually work. Find the right opportunities and prep tools, filtered by industry and role type.',
      }
    case '/login':
      return {
        title: 'Sign In - NextJob',
        description:
          'Access your job search dashboard. Pick up where you left off with your applications, interview prep, and career planning.',
      }
    case '/privacy':
      return {
        title: 'Privacy Policy - NextJob',
        description:
          'Your data stays yours. Read how NextJob keeps your job search private and secure.',
      }
    case '/terms':
      return {
        title: 'Terms of Service - NextJob',
        description:
          "Clear, straightforward terms for using NextJob's job search tools.",
      }
    default:
      return {
        title: 'NextJob - Job Application Tracking Made Simple',
        description:
          'Keep your job search organized without the busywork. Track applications, prep for interviews, and land your next role faster.',
      }
  }
}

const AppContent = () => {
  const location = useLocation()
  const [showJobModal, setShowJobModal] = useState(false)
  const { darkMode, setDarkMode } = useTheme()
  const { user, setUser } = useContext(UserContext)

  // Save scroll position before navigation
  useEffect(() => {
    // Save the current scroll position before navigating away
    const saveScrollPosition = () => {
      history.replaceState(
        { ...history.state, scrollY: window.scrollY },
        document.title,
      )
    }

    window.addEventListener('beforeunload', saveScrollPosition)
    return () => window.removeEventListener('beforeunload', saveScrollPosition)
  }, [])

  // Restore scroll position on navigation
  useEffect(() => {
    if (history.state?.scrollY !== undefined) {
      // Restore previous scroll position
      window.scrollTo(0, history.state.scrollY)
    } else {
      // New navigation - scroll to top
      window.scrollTo(0, 0)
    }
  }, [location.pathname])

  useEffect(() => {
    setShowJobModal(location.hash === '#add-job')
  }, [location.hash])

  const metaData = getMetaData(location.pathname)

  return (
    <div
      className={`min-h-screen flex flex-col pt-16 ${
        darkMode
          ? 'bg-secondary-900 text-secondary-100'
          : 'bg-gradient-to-b from-white to-primary-50 text-secondary-900'
      } transition-colors duration-200`}
    >
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
        <meta property="og:title" content={metaData.title} />
        <meta property="og:description" content={metaData.description} />
        <meta property="twitter:title" content={metaData.title} />
        <meta property="twitter:description" content={metaData.description} />
        <link
          rel="canonical"
          href={`https://nextjob.work${location.pathname}`}
        />
      </Helmet>
      <Header user={user} setUser={setUser} />
      <main className="flex-grow container mx-auto px-0 sm:px-6 lg:px-8">
        <Routes>
          <Route
            path="/"
            element={user ? <Dashboard user={user} /> : <LandingPage />}
          />
          <Route
            path="/login"
            element={user ? <Navigate to="/" /> : <Login setUser={setUser} />}
          />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/resources" element={<JobResources />} />
          <Route
            path="/upgrade"
            element={
              <ProtectedRoute>
                <Upgrade user={user} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <ProtectedRoute>
                <Settings
                  user={user}
                  darkMode={darkMode}
                  setDarkMode={setDarkMode}
                />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <Profile user={user} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/blog"
            element={
              <BlogProvider>
                <ErrorBoundary>
                  <BlogList />
                </ErrorBoundary>
              </BlogProvider>
            }
          />
          <Route
            path="/blog/:slug"
            element={
              <BlogProvider>
                <ErrorBoundary>
                  <BlogPost />
                </ErrorBoundary>
              </BlogProvider>
            }
          />
        </Routes>
      </main>
      <Footer />
      {user && showJobModal && (
        <JobFormModal
          user={user}
          onClose={() => {
            setShowJobModal(false)
          }}
        />
      )}
    </div>
  )
}

const App = () => {
  const [user, setUser] = useState(null)
  const [hasResume, setHasResume] = useState(false)
  const [loading, setLoading] = useState(true)
  const [darkMode, setDarkMode] = useState(() => {
    const stored = localStorage.getItem('theme')
    if (stored !== null) return stored === 'dark'
    return window.matchMedia('(prefers-color-scheme: dark)').matches
  })
  const [userProfile, setUserProfile] = useState(null)

  useEffect(() => {
    const checkUser = async () => {
      try {
        const session = await account.get()
        if (session) {
          const prefs = await account.getPrefs()

          // Load theme preferences
          setDarkMode(prefs.darkMode || false)

          // Set default plan if not exists
          const plan = await getUserPlan(session.$id)

          const userWithPlan = {
            ...session,
            plan,
          }
          setUser(userWithPlan)

          // Check if this is a new user (registered within the last minute)
          const registrationTime = new Date(session.registration).getTime()
          const currentTime = new Date().getTime()
          const isNewUser = currentTime - registrationTime < 60000 // 1 minute

          // Send welcome email for new users with verified email
          if (isNewUser && session.emailVerification) {
            try {
              await sendEmail({
                to: session.email,
                templateName: 'welcome',
                templateData: {
                  name: session.name || 'there', // Fallback if name is not available
                  origin: window.location.origin,
                },
                userId: session.$id,
              })
            } catch (error) {
              console.error('Failed to send welcome email:', error)
            }
          }
        }

        // If user is authenticated, load theme from their preferences
        const prefs = await account.getPrefs()
        setDarkMode(prefs.darkMode || false)

        // Check if this is a popup callback
        if (window.opener && window.opener !== window) {
          // This is a popup callback
          const urlParams = new URLSearchParams(window.location.search)
          const success = urlParams.get('success')

          if (success === 'true') {
            // Close popup and notify parent window of success
            window.opener.postMessage(
              { type: 'oauth-success', provider: 'github' },
              '*',
            )
          } else {
            // Notify parent window of failure
            window.opener.postMessage(
              { type: 'oauth-failure', provider: 'github' },
              '*',
            )
          }
          window.close()
        }

        // Handle messages from popup window
        const handleMessage = (event) => {
          if (event.data.type === 'oauth-success') {
            const provider = event.data.provider
            toast.success(
              `Successfully signed in with ${
                provider === 'github' ? 'GitHub' : 'Google'
              }!`,
            )
            // Refresh user data
            account.get().then(setUser)
          } else if (event.data.type === 'oauth-failure') {
            const provider = event.data.provider
            toast.error(
              `Failed to sign in with ${
                provider === 'github' ? 'GitHub' : 'Google'
              }`,
            )
          }
        }

        window.addEventListener('message', handleMessage)
        return () => window.removeEventListener('message', handleMessage)
      } catch (error) {
        console.error('Session check error:', error)
      } finally {
        setLoading(false)
      }
    }
    checkUser()
  }, [])

  useEffect(() => {
    // Check if user has uploaded resume
    const checkResume = async () => {
      try {
        if (user) {
          const userProfile = await getUserProfile(user.$id)
          setHasResume(!!userProfile?.resumeFileId)
        }
      } catch (error) {
        // If document doesn't exist, user hasn't uploaded resume
        console.error(error)
        setHasResume(false)
      }
    }
    checkResume()
  }, [hasResume, user])

  // Update localStorage and document class when theme changes
  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark')
      localStorage.setItem('theme', 'dark')
    } else {
      document.documentElement.classList.remove('dark')
      localStorage.setItem('theme', 'light')
    }

    // If user is authenticated, also update their preferences
    if (user) {
      account
        .getPrefs()
        .then((prefs) => {
          account.updatePrefs({
            ...prefs,
            darkMode,
          })
        })
        .catch((error) => {
          console.error('Failed to update theme preference:', error)
        })
    }
  }, [darkMode, user])

  // Check for Stripe success
  const urlParams = new URLSearchParams(window.location.search)
  const sessionId = urlParams.get('session_id')
  const success = urlParams.get('success')

  if (sessionId && success === 'true') {
    toast.success('Successfully upgraded to Pro! Welcome aboard 🚀')
    // Optionally refresh user data here to update plan status
  }

  useEffect(() => {
    const loadUserProfile = async () => {
      try {
        if (user) {
          const profile = await getUserProfile(user.$id)
          setUserProfile(profile)
          setHasResume(!!profile?.resumeFileId)
        }
      } catch (error) {
        console.error(error)
        setHasResume(false)
      }
    }
    loadUserProfile()
  }, [user])

  if (loading) {
    return (
      <div
        className={`min-h-screen flex items-center justify-center ${
          darkMode
            ? 'bg-secondary-900'
            : 'bg-gradient-to-b from-white to-primary-50'
        }`}
      >
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary-600 dark:border-primary-400"></div>
      </div>
    )
  }

  return (
    <HelmetProvider>
      <ThemeContext.Provider value={{ darkMode, setDarkMode }}>
        <UserContext.Provider
          value={{
            user,
            setUser,
            hasResume,
            setHasResume,
            userProfile,
            setUserProfile,
          }}
        >
          <JobProvider>
            <Router>
              <AppContent />
              <Toaster
                position="bottom-right"
                toastOptions={{
                  duration: 3000,
                  style: {
                    background: darkMode ? '#1e293b' : '#fff',
                    color: darkMode ? '#f1f5f9' : '#333',
                  },
                }}
              />
            </Router>
          </JobProvider>
        </UserContext.Provider>
      </ThemeContext.Provider>
    </HelmetProvider>
  )
}

export const UserContext = createContext()

export default App
