import { useState, useEffect } from 'react'
import {
  FiUser,
  FiMail,
  FiCalendar,
  FiBarChart2,
  FiZap,
  FiBriefcase,
  FiFile,
  FiTrash2,
  FiLoader,
} from 'react-icons/fi'
import {
  getJobs,
  getResumeData,
  getUserProfile,
  updateUserProfile,
} from '../utils/db'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import PropTypes from 'prop-types'
import { format } from 'date-fns'
import { storage } from '../utils/appwrite'
import { RESUMES_BUCKET_ID } from '../utils/appwrite'
import ResumeUpload from './ResumeUpload'
import { useContext } from 'react'
import { UserContext } from '../App'
import { AnimatePresence } from 'framer-motion'
import DeleteConfirmDialog from './DeleteConfirmDialog'
import JobActivityChart from './JobActivityChart'
import { handleCancelSubscription } from '../utils/payments'
import { databases } from '../utils/appwrite'
import { Query } from 'appwrite'

const Profile = ({ user }) => {
  const [stats, setStats] = useState({
    totalApplications: 0,
    interviewRate: 0,
    offerRate: 0,
    activeApplications: 0,
  })
  const navigate = useNavigate()
  const [jobs, setJobs] = useState([])
  const [weeklyApplications, setWeeklyApplications] = useState(0)
  const [resumeInfo, setResumeInfo] = useState(null)
  const [isLoadingResume, setIsLoadingResume] = useState(true)
  const [isDeletingResume, setIsDeletingResume] = useState(false)
  const apiUrl = import.meta.env.VITE_API_URL

  const { setHasResume } = useContext(UserContext)

  const [showDeleteResumeDialog, setShowDeleteResumeDialog] = useState(false)
  const [deleteDialogPosition, setDeleteDialogPosition] = useState({
    x: 0,
    y: 0,
  })

  const [showCancelDialog, setShowCancelDialog] = useState(false)
  const [isCancelling, setIsCancelling] = useState(false)

  const [subscriptionStatus, setSubscriptionStatus] = useState(null)
  const [planEndDate, setPlanEndDate] = useState(null)

  const fetchSubscriptionStatus = async () => {
    try {
      const plans = await databases.listDocuments('job-hunt-db', 'user-plans', [
        Query.equal('userId', user.$id),
      ])

      if (plans.total > 0) {
        const userPlan = plans.documents[0]
        setSubscriptionStatus(userPlan.status)
        setPlanEndDate(userPlan.endDate)
      }
    } catch (error) {
      console.error('Error fetching subscription status:', error)
    }
  }

  useEffect(() => {
    const loadUserStats = async () => {
      try {
        // Get all jobs for the user
        const userJobs = await getJobs(user.$id)
        setJobs(userJobs)

        // Calculate weekly applications (jobs created in the last 7 days)
        const oneWeekAgo = new Date()
        oneWeekAgo.setDate(oneWeekAgo.getDate() - 7)
        const weeklyApps = userJobs.filter(
          (job) => new Date(job.$createdAt) > oneWeekAgo,
        ).length
        setWeeklyApplications(weeklyApps)

        // Calculate stats
        const totalApplications = userJobs.length
        const interviewingJobs = userJobs.filter(
          (job) => job.status === 'interviewing',
        ).length
        const offerJobs = userJobs.filter(
          (job) => job.status === 'got-offer',
        ).length
        const activeJobs = userJobs.filter(
          (job) =>
            ![
              'declined',
              'accepted',
              'withdrawn',
              'got-offer',
              'not-started',
            ].includes(job.status),
        ).length

        // Calculate rates
        const interviewRate =
          totalApplications > 0
            ? Math.round((interviewingJobs / totalApplications) * 100)
            : 0
        const offerRate =
          totalApplications > 0
            ? Math.round((offerJobs / totalApplications) * 100)
            : 0

        setStats({
          totalApplications,
          interviewRate,
          offerRate,
          activeApplications: activeJobs,
        })
      } catch (error) {
        console.error('Error loading profile:', error)
        toast.error('Failed to load profile data')
      }
    }

    loadUserStats()
  }, [user.$id])

  useEffect(() => {
    const loadResumeInfo = async () => {
      try {
        const profile = await getUserProfile(user.$id)

        if (profile?.resumeFileId) {
          const resumeData = await getResumeData(profile.resumeFileId)
          setResumeInfo({
            fileId: profile.resumeFileId,
            fileName: resumeData.name,
            uploadDate: resumeData.$createdAt,
          })
        }
      } catch (error) {
        if (error.code === '404') {
          return
        }
      } finally {
        setIsLoadingResume(false)
      }
    }
    loadResumeInfo()
  }, [apiUrl, user.$id])

  useEffect(() => {
    fetchSubscriptionStatus()
  }, [user.$id])

  const handleDeleteResume = (e) => {
    const rect = e.currentTarget.getBoundingClientRect()
    const dialogWidth = 320
    const dialogHeight = 200
    const buffer = 16

    setDeleteDialogPosition({
      x: rect.x - dialogWidth + rect.width,
      y: rect.top - dialogHeight - buffer, // Always position above with buffer
    })
    setShowDeleteResumeDialog(true)
  }

  const handleDeleteResumeConfirm = async () => {
    if (!resumeInfo?.fileId) return

    try {
      setIsDeletingResume(true)

      // Delete the file from storage
      await storage.deleteFile(RESUMES_BUCKET_ID, resumeInfo.fileId)

      // Update the user profile in the database
      await updateUserProfile(user.$id, {
        resumeText: null,
        resumeFileId: null,
        updatedAt: new Date().toISOString(),
      })

      setResumeInfo(null)
      setHasResume(false)
      toast.success('Resume deleted successfully')
    } catch (error) {
      console.error('Failed to delete resume:', error)
      toast.error('Failed to delete resume')
    } finally {
      setIsDeletingResume(false)
      setShowDeleteResumeDialog(false)
    }
  }

  // Helper function to format the activity
  const formatActivity = (job) => {
    if (job.status !== 'not-started') {
      return {
        type: 'status-update',
        date: new Date(job.updatedAt),
        text: `Updated ${job.title} status to ${formatStatus(job.status)}`,
        job,
      }
    }
    return {
      type: 'new',
      date: new Date(job.createdAt),
      text: `Added ${job.title} at ${job.company}`,
      job,
    }
  }

  const formatStatus = (status) => {
    return status
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }

  // Create a combined and sorted list of activities
  const recentActivities = jobs
    .map((job) => formatActivity(job))
    .sort((a, b) => b.date - a.date)
    .slice(0, 5)

  const handleCancel = async () => {
    try {
      setIsCancelling(true)
      const success = await handleCancelSubscription()
      if (success) {
        toast.success('Your subscription has been cancelled')
        await fetchSubscriptionStatus() // Refresh the status
      } else {
        toast.error('Failed to cancel subscription')
      }
    } catch (error) {
      console.error('Cancel error:', error)
      toast.error('Failed to cancel subscription')
    } finally {
      setIsCancelling(false)
      setShowCancelDialog(false)
    }
  }

  const handleCancelClick = () => {
    setShowCancelDialog(true)
  }

  return (
    <div className="py-4">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 relative">
        {/* Add backdrop blur container */}
        <div className="absolute inset-0 bg-white/50 dark:bg-secondary-900/50 backdrop-blur-sm rounded-xl -z-10" />

        {/* Add decorative gradient background */}
        <div className="absolute inset-0 -z-20 overflow-hidden">
          <div className="absolute -top-1/2 -left-1/2 w-full h-full bg-gradient-to-r from-primary-500/10 to-purple-500/10 blur-3xl transform rotate-12 dark:from-primary-900/20 dark:to-purple-900/20" />
          <div className="absolute -bottom-1/2 -right-1/2 w-full h-full bg-gradient-to-l from-primary-500/10 to-purple-500/10 blur-3xl transform -rotate-12 dark:from-primary-900/20 dark:to-purple-900/20" />
        </div>

        <div className="bg-white/70 dark:bg-secondary-800/70 backdrop-blur-md rounded-2xl shadow-sm overflow-hidden border border-white/20 dark:border-secondary-700/20">
          {/* Header Section */}
          <div className="p-4 sm:p-6 border-b border-secondary-200/50 dark:border-secondary-700/50">
            <div className="flex flex-col sm:flex-row sm:items-center gap-4">
              <div className="w-14 h-14 sm:w-16 sm:h-16 bg-primary-100/80 dark:bg-primary-900/30 rounded-full flex items-center justify-center flex-shrink-0 backdrop-blur-sm">
                <FiUser className="w-7 h-7 sm:w-8 sm:h-8 text-primary-600 dark:text-primary-400" />
              </div>
              <div>
                <h1 className="text-xl sm:text-2xl font-bold text-secondary-900 dark:text-secondary-100">
                  {user.name || 'User Profile'}
                </h1>
                <p className="text-secondary-600 dark:text-secondary-400 flex items-center gap-2 text-sm sm:text-base">
                  <FiMail className="w-4 h-4" />
                  {user.email}
                </p>
              </div>
            </div>
          </div>

          {/* Stats Grid - Update the container styles */}
          <div className="p-4 sm:p-6 bg-white/40 dark:bg-secondary-800/40 backdrop-blur-md">
            <div className="grid grid-cols-2 gap-3 sm:gap-4">
              <StatCard
                icon={FiBarChart2}
                label="Total Applications"
                value={stats.totalApplications}
                primary
              />
              <StatCard
                icon={FiBarChart2}
                label="Active Applications"
                value={stats.activeApplications}
                primary
              />
              <StatCard
                icon={FiBarChart2}
                label="Interview Rate"
                value={`${stats.interviewRate}%`}
              />
              <StatCard
                icon={FiBarChart2}
                label="Offer Rate"
                value={`${stats.offerRate}%`}
              />
            </div>
          </div>

          {/* Job Activity Chart - Add glass effect */}
          <div className="p-4 sm:p-6 border-t border-secondary-200/50 dark:border-secondary-700/50 bg-white/40 dark:bg-secondary-800/40 backdrop-blur-md">
            <JobActivityChart jobs={jobs} />
          </div>

          {/* Job Search Goals - Update container styles */}
          <div className="p-4 sm:p-6 border-t border-secondary-200/50 dark:border-secondary-700/50 bg-white/40 dark:bg-secondary-800/40 backdrop-blur-md">
            <h2 className="text-lg sm:text-xl font-semibold text-secondary-900 dark:text-secondary-100 mb-4">
              Job Search Goals
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div
                className="relative rounded-xl p-3 sm:p-4 overflow-hidden group
                bg-secondary-50 dark:bg-secondary-800/30 backdrop-blur-md
                border border-secondary-200/50 dark:border-secondary-700/20"
              >
                {/* Beautiful gradient background for dark mode */}
                <div className="absolute inset-0 dark:bg-gradient-to-br dark:from-secondary-800/50 dark:via-secondary-800/30 dark:to-secondary-800/50" />

                {/* Hover effect */}
                <div className="absolute inset-0 bg-gradient-to-br from-primary-500/5 via-purple-500/5 to-primary-500/5 opacity-0 group-hover:opacity-100 transition-all duration-300" />

                {/* Glow effect */}
                <div className="absolute inset-0 dark:bg-primary-500/5 dark:group-hover:bg-primary-500/10 transition-colors duration-300" />

                <div className="relative">
                  <h3 className="font-medium text-secondary-500 dark:text-secondary-400 mb-1.5 text-sm sm:text-base">
                    Weekly Application Target
                  </h3>
                  <div className="flex items-end gap-2 mb-2">
                    <span className="text-xl sm:text-2xl font-bold text-primary-600 dark:text-primary-400">
                      {weeklyApplications}
                    </span>
                    <span className="text-xs sm:text-sm text-secondary-500 dark:text-secondary-400">
                      / 10 applications
                    </span>
                  </div>
                  <div className="w-full h-2 bg-secondary-100 dark:bg-secondary-700 rounded-full">
                    <div
                      className="h-full bg-primary-500 dark:bg-primary-400 rounded-full transition-all duration-500"
                      style={{
                        width: `${Math.min(
                          (weeklyApplications / 10) * 100,
                          100,
                        )}%`,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                className="relative rounded-xl p-3 sm:p-4 overflow-hidden group
                bg-secondary-50 dark:bg-secondary-800/30 backdrop-blur-md
                border border-secondary-200/50 dark:border-secondary-700/20"
              >
                {/* Same gradient and effects as above */}
                <div className="absolute inset-0 dark:bg-gradient-to-br dark:from-secondary-800/50 dark:via-secondary-800/30 dark:to-secondary-800/50" />
                <div className="absolute inset-0 bg-gradient-to-br from-primary-500/5 via-purple-500/5 to-primary-500/5 opacity-0 group-hover:opacity-100 transition-all duration-300" />
                <div className="absolute inset-0 dark:bg-primary-500/5 dark:group-hover:bg-primary-500/10 transition-colors duration-300" />

                <div className="relative">
                  <h3 className="font-medium text-secondary-600 dark:text-secondary-300 mb-1.5 text-sm sm:text-base">
                    Interview Success Rate
                  </h3>
                  <div className="flex items-end gap-2 mb-2">
                    <span className="text-xl sm:text-2xl font-bold text-primary-600 dark:text-primary-400">
                      {stats.interviewRate}%
                    </span>
                  </div>
                  <div className="w-full h-2 bg-secondary-100 dark:bg-secondary-700 rounded-full">
                    <div
                      className="h-full bg-primary-500 dark:bg-primary-400 rounded-full transition-all duration-500"
                      style={{
                        width: `${Math.min(stats.interviewRate, 100)}%`,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Recent Activity - Update container styles */}
          <div className="p-4 sm:p-6 border-t border-secondary-200/50 dark:border-secondary-700/50 bg-white/40 dark:bg-secondary-800/40 backdrop-blur-md">
            <h2 className="text-lg sm:text-xl font-semibold text-secondary-900 dark:text-secondary-100 mb-4">
              Recent Activity
            </h2>
            <div className="space-y-3 sm:space-y-4">
              {recentActivities.map((activity, index) => (
                <div
                  key={`${activity.job.$id}-${activity.type}-${index}`}
                  className="flex items-start gap-3 bg-white/60 dark:bg-secondary-800/60 backdrop-blur-md p-3 rounded-xl border border-white/20 dark:border-secondary-700/20"
                >
                  <div className="w-7 h-7 sm:w-8 sm:h-8 rounded-full bg-primary-100 dark:bg-primary-900/50 flex items-center justify-center flex-shrink-0">
                    <FiBriefcase className="w-3.5 h-3.5 sm:w-4 sm:h-4 text-primary-600 dark:text-primary-400" />
                  </div>
                  <div>
                    <p className="text-sm sm:text-base text-secondary-600 dark:text-secondary-300">
                      {activity.text}
                    </p>
                    <p className="text-xs sm:text-sm text-secondary-500 dark:text-secondary-400">
                      {format(activity.date, 'MMM d, yyyy')}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Resume Section - Update container styles */}
          <div className="p-4 sm:p-6 border-t border-secondary-200/50 dark:border-secondary-700/50 bg-white/40 dark:bg-secondary-800/40 backdrop-blur-md">
            <h2 className="text-lg sm:text-xl font-semibold text-secondary-600 dark:text-secondary-300 mb-4">
              Resume
            </h2>
            <div className="space-y-3">
              {isLoadingResume ? (
                <div className="animate-pulse h-16 bg-secondary-100 dark:bg-secondary-700 rounded-lg" />
              ) : resumeInfo ? (
                <div
                  className="relative rounded-xl p-4 overflow-hidden group
                  bg-secondary-50 dark:bg-secondary-800/30 backdrop-blur-md
                  border border-secondary-200/50 dark:border-secondary-700/20"
                >
                  {/* Gradient effects */}
                  <div className="absolute inset-0 dark:bg-gradient-to-br dark:from-secondary-800/50 dark:via-secondary-800/30 dark:to-secondary-800/50" />
                  <div className="absolute inset-0 bg-gradient-to-br from-primary-500/5 via-purple-500/5 to-primary-500/5 opacity-0 group-hover:opacity-100 transition-all duration-300" />
                  <div className="absolute inset-0 dark:bg-primary-500/5 dark:group-hover:bg-primary-500/10 transition-colors duration-300" />

                  <div className="relative flex items-start justify-between">
                    <div className="flex items-center gap-3">
                      <div className="w-10 h-10 rounded-lg bg-primary-100 dark:bg-primary-900/50 flex items-center justify-center">
                        <FiFile className="w-5 h-5 text-primary-600 dark:text-primary-400" />
                      </div>
                      <div>
                        <p className="font-medium text-secondary-900 dark:text-secondary-100">
                          {resumeInfo.fileName}
                        </p>
                        <p className="text-sm text-secondary-500 dark:text-secondary-400">
                          Uploaded{' '}
                          {format(
                            new Date(resumeInfo.uploadDate),
                            'MMM d, yyyy',
                          )}
                        </p>
                      </div>
                    </div>
                    <button
                      onClick={handleDeleteResume}
                      className="p-2 text-secondary-500 hover:text-red-500 dark:text-secondary-400 dark:hover:text-red-400 transition-colors"
                      title="Delete resume"
                    >
                      {isDeletingResume ? (
                        <FiLoader className="w-5 h-5 animate-spin" />
                      ) : (
                        <FiTrash2 className="w-5 h-5" />
                      )}
                    </button>
                  </div>
                </div>
              ) : (
                <div
                  className="relative rounded-xl p-4 overflow-hidden group
                  bg-secondary-50 dark:bg-secondary-800/30 backdrop-blur-md
                  border border-secondary-200/50 dark:border-secondary-700/20"
                >
                  {/* Same gradient effects */}
                  <div className="absolute inset-0 dark:bg-gradient-to-br dark:from-secondary-800/50 dark:via-secondary-800/30 dark:to-secondary-800/50" />
                  <div className="absolute inset-0 bg-gradient-to-br from-primary-500/5 via-purple-500/5 to-primary-500/5 opacity-0 group-hover:opacity-100 transition-all duration-300" />
                  <div className="absolute inset-0 dark:bg-primary-500/5 dark:group-hover:bg-primary-500/10 transition-colors duration-300" />

                  <div className="relative text-center">
                    <p className="text-secondary-600 dark:text-secondary-400 mb-3">
                      No resume uploaded yet
                    </p>
                    <ResumeUpload
                      user={user}
                      onUploadComplete={(info) => setResumeInfo(info)}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Account Details - Update container styles */}
          <div className="p-4 sm:p-6 border-t border-secondary-200/50 dark:border-secondary-700/50 bg-white/40 dark:bg-secondary-800/40 backdrop-blur-md">
            <h2 className="text-lg sm:text-xl font-semibold text-secondary-600 dark:text-secondary-300 mb-4">
              Account Details
            </h2>

            {/* Subscription Status Card */}
            <div
              className="relative rounded-xl p-4 mb-4 overflow-hidden group
              bg-secondary-50 dark:bg-secondary-800/30 backdrop-blur-md
              border border-secondary-200/50 dark:border-secondary-700/20"
            >
              {/* Gradient effects */}
              <div className="absolute inset-0 dark:bg-gradient-to-br dark:from-secondary-800/50 dark:via-secondary-800/30 dark:to-secondary-800/50" />
              <div className="absolute inset-0 bg-gradient-to-br from-primary-500/5 via-purple-500/5 to-primary-500/5 opacity-0 group-hover:opacity-100 transition-all duration-300" />
              <div className="absolute inset-0 dark:bg-primary-500/5 dark:group-hover:bg-primary-500/10 transition-colors duration-300" />

              <div className="relative">
                <div className="flex items-center justify-between mb-3">
                  <div className="flex items-center gap-2">
                    <FiZap className="w-5 h-5 text-primary-600 dark:text-primary-400" />
                    <span className="font-medium text-secondary-900 dark:text-secondary-100">
                      {user.plan === 'pro' ? 'Pro Plan' : 'Free Plan'}
                    </span>
                  </div>
                  {user.plan === 'pro' ? (
                    subscriptionStatus === 'cancelled' ? (
                      <span className="px-3 py-1 bg-orange-100 dark:bg-orange-900/30 text-orange-700 dark:text-orange-400 text-sm rounded-full">
                        Cancels Soon
                      </span>
                    ) : (
                      <span className="px-3 py-1 bg-green-100 dark:bg-green-900/30 text-green-700 dark:text-green-400 text-sm rounded-full">
                        Active
                      </span>
                    )
                  ) : null}
                </div>

                {user.plan === 'pro' ? (
                  <>
                    {subscriptionStatus === 'cancelled' ? (
                      <p className="text-secondary-600 dark:text-secondary-400 text-sm mb-3">
                        Your Pro access continues until{' '}
                        {format(new Date(planEndDate), 'MMMM d, yyyy')}. After
                        that, you&apos;ll be moved to the Free plan.
                      </p>
                    ) : (
                      <p className="text-secondary-700 dark:text-secondary-300 text-sm mb-3">
                        Enjoy unlimited job applications, AI features, and
                        premium support.
                      </p>
                    )}
                    {!subscriptionStatus || subscriptionStatus === 'active' ? (
                      <button
                        onClick={handleCancelClick}
                        className="text-sm text-secondary-600 dark:text-secondary-400 hover:text-red-600 dark:hover:text-red-400 transition-colors disabled:opacity-50"
                        disabled={isCancelling}
                      >
                        {isCancelling ? (
                          <span className="flex items-center gap-2">
                            <FiLoader className="w-3 h-3 animate-spin" />
                            Cancelling...
                          </span>
                        ) : (
                          'Cancel Subscription'
                        )}
                      </button>
                    ) : null}
                  </>
                ) : (
                  <>
                    <p className="text-secondary-600 dark:text-secondary-400 text-sm mb-3">
                      Upgrade to Pro for unlimited job applications and AI
                      features.
                    </p>
                    <button
                      onClick={() => navigate('/upgrade')}
                      className="inline-flex items-center px-4 py-2 rounded-lg bg-primary-600 hover:bg-primary-700 text-white text-sm font-medium transition-colors"
                    >
                      Upgrade to Pro
                    </button>
                  </>
                )}
              </div>
            </div>

            {/* Account Info */}
            <div className="space-y-3">
              <div className="flex items-center gap-2 text-sm text-secondary-600 dark:text-secondary-400">
                <FiMail className="w-4 h-4" />
                <span>{user.email}</span>
              </div>
              <div className="flex items-center gap-2 text-sm text-secondary-600 dark:text-secondary-400">
                <FiCalendar className="w-4 h-4" />
                <span>
                  Member since{' '}
                  {format(new Date(user.$createdAt), 'MMMM d, yyyy')}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AnimatePresence key="delete-resume-dialog">
        <DeleteConfirmDialog
          isOpen={showDeleteResumeDialog}
          onClose={() => setShowDeleteResumeDialog(false)}
          onConfirm={handleDeleteResumeConfirm}
          title="Delete Resume"
          message="Are you sure you want to delete your resume? This will affect your ability to generate cover letters."
          position={deleteDialogPosition}
        />
      </AnimatePresence>
      <AnimatePresence>
        {showCancelDialog && (
          <DeleteConfirmDialog
            isOpen={showCancelDialog}
            onClose={() => setShowCancelDialog(false)}
            onConfirm={handleCancel}
            title="Cancel Pro Plan"
            message="Are you sure you want to cancel your Pro plan? You'll lose access to Pro features at the end of your current billing period."
            confirmText="Yes, Cancel Plan"
            confirmStyle="danger"
            centered={true}
            isLoading={isCancelling}
          />
        )}
      </AnimatePresence>
    </div>
  )
}

Profile.propTypes = {
  user: PropTypes.shape({
    $id: PropTypes.string.isRequired,
    $createdAt: PropTypes.string.isRequired,
    name: PropTypes.string,
    email: PropTypes.string.isRequired,
    plan: PropTypes.string.isRequired,
  }).isRequired,
}

const StatCard = ({ icon: Icon, label, value, primary }) => (
  <div
    className={`relative rounded-xl ${primary ? 'p-3 sm:p-5' : 'p-3 sm:p-4'}
    hover:shadow-lg transition-all duration-300 overflow-hidden group
    bg-secondary-50 dark:bg-secondary-800/30 backdrop-blur-md
    border border-secondary-200/50 dark:border-secondary-700/20`}
  >
    {/* Beautiful gradient background for dark mode */}
    <div className="absolute inset-0 dark:bg-gradient-to-br dark:from-secondary-800/50 dark:via-secondary-800/30 dark:to-secondary-800/50" />

    {/* Subtle animated gradient overlay on hover */}
    <div className="absolute inset-0 bg-gradient-to-br from-primary-500/5 via-purple-500/5 to-primary-500/5 opacity-0 group-hover:opacity-100 transition-all duration-300" />

    {/* Add a subtle glow effect in dark mode */}
    <div className="absolute inset-0 dark:bg-primary-500/5 dark:group-hover:bg-primary-500/10 transition-colors duration-300" />

    <div className="relative">
      <div className="flex items-center gap-1.5 sm:gap-2 mb-1 sm:mb-2">
        {/* Icon container with glowing background in dark mode */}
        <div className="rounded-full dark:bg-primary-900/30 dark:group-hover:bg-primary-900/50 transition-colors duration-300 p-1">
          <Icon
            className={`${
              primary ? 'w-5 h-5 sm:w-6 sm:h-6' : 'w-4 h-4 sm:w-5 sm:h-5'
            } text-primary-600 dark:text-primary-400`}
          />
        </div>
        <span
          className={`${
            primary ? 'text-sm sm:text-base' : 'text-xs sm:text-sm'
          } text-secondary-600 dark:text-secondary-400`}
        >
          {label}
        </span>
      </div>
      <div
        className={`${
          primary ? 'text-2xl sm:text-3xl' : 'text-xl sm:text-2xl'
        } font-bold text-secondary-600 dark:text-secondary-100`}
      >
        {value}
      </div>

      {/* Progress bar for percentage stats */}
      {typeof value === 'string' && value.includes('%') && (
        <div className="w-full h-1.5 bg-secondary-200 dark:bg-secondary-700/50 rounded-full mt-1.5 sm:mt-2 overflow-hidden">
          <div
            className="h-full bg-primary-500 dark:bg-primary-400 rounded-full transition-all duration-500 relative"
            style={{ width: value }}
          >
            {/* Add subtle shine effect to progress bar */}
            <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/10 to-transparent animate-shine" />
          </div>
        </div>
      )}
    </div>
  </div>
)

StatCard.propTypes = {
  icon: PropTypes.elementType.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  primary: PropTypes.bool,
}

StatCard.defaultProps = {
  primary: false,
}

export default Profile
