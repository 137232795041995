import PropTypes from 'prop-types'

const NextJobLogo = ({ className = 'w-8 h-8' }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <defs>
      <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
        <stop offset="0%" stopColor="#4F46E5" />
        <stop offset="100%" stopColor="#7C3AED" />
      </linearGradient>
    </defs>
    <rect x="2" y="6" width="28" height="24" rx="2" fill="url(#gradient)" />
    <path
      d="M11 6V4C11 2.89543 11.8954 2 13 2H19C20.1046 2 21 2.89543 21 4V6"
      stroke="currentColor"
      strokeWidth="2"
      className="text-secondary-600 dark:text-white"
    />
    <path
      d="M13 15L18 20L13 25"
      stroke="white"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

NextJobLogo.propTypes = {
  className: PropTypes.string,
}

export default NextJobLogo
