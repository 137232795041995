import { createContext, useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { formatBlogPost, sortBlogPosts } from '../utils/blog'

export const BlogContext = createContext()

export const useBlog = () => useContext(BlogContext)

export const BlogProvider = ({ children }) => {
  const [posts, setPosts] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const loadPosts = async () => {
      try {
        const postModules = import.meta.glob('/src/content/blog/*.mdx', {
          eager: true,
        })

        const postEntries = Object.entries(postModules)
          .map(([path, module]) => {
            const slug = path.split('/').pop().replace('.mdx', '')
            const post = {
              slug,
              Component: module.default,
              frontmatter: module.frontmatter,
              content: module.default?.toString() || '',
            }
            return formatBlogPost(post)
          })
          .filter((post) => post?.frontmatter) // Filter out any posts without frontmatter

        setPosts(sortBlogPosts(postEntries))
      } catch (error) {
        console.error('Error loading blog posts:', error)
      } finally {
        setLoading(false)
      }
    }

    loadPosts()
  }, [])

  return (
    <BlogContext.Provider value={{ posts, loading }}>
      {children}
    </BlogContext.Provider>
  )
}

BlogProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
